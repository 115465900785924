import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service';  
import { EncrDecrService } from '../_services/encrdecr.service';
import { AuthService } from '../_services/auth.service';

import { FileUploader } from "ng2-file-upload";

declare var $: any;
const URL = "https://stocksherpa.in/api/imageupload";
@Component({
  selector: 'app-paid-myaccount',
  templateUrl: './paid-myaccount.component.html',
  styleUrls: ['./paid-myaccount.component.css']
})
export class PaidMyaccountComponent implements OnInit {
  logindetails: any; 
  subscriptiontype: string="Monthly";
  UserId: string="";
  AccName: string="";
  AccEmail: string="";
  Accmobile_no: string="";
  Accsubscription_type: string=""; 
  options: any;
  rzp1: any; 
  AccValidity: string="";
  UPlansubscription_type: string="";
  UPlanValidity: string="";
  upcomingArr: any;
  GblImage: string="https://stocksherpa.blob.core.windows.net/userimages/img_avatar.png";
  defaultimg: boolean=true;
  linkedin: string=""; 
  
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router, 
    private EncrDecr: EncrDecrService,
    private Auth: AuthService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted);  
    this.UserId = this.logindetails.UserId;
    this.upcomingArr =[];
    const Userdetails = { 
      UserId: this.UserId 
    };
    this.dataload(Userdetails);
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar .mktmnu').removeClass("activetab");    
    $('.fhmenumar .topmnu').removeClass("activetab"); 
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.fhmenumar .myAcnt').addClass("activemenu"); 
  }

  dataload(Userdetails) {
    this.spinner.show();
    return this.http.post("accountdtls", Userdetails).toPromise().then(res => {  
      debugger    
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      this.AccName = parsedJson.data[0][0].title+"."+parsedJson.data[0][0].first_name+" "+parsedJson.data[0][0].last_name;
      this.AccEmail = parsedJson.data[0][0].email;
      this.Accmobile_no = parsedJson.data[0][0].mobile_no; 
      this.Accsubscription_type = parsedJson.data[1][0].subscription_type; 
      this.AccValidity = parsedJson.data[1][0].validity_from + " - " + parsedJson.data[1][0].validity_to;
      
      this.upcomingArr =parsedJson.data[2];
 
      if(this.upcomingArr.length !=0) {
        this.UPlansubscription_type = parsedJson.data[2][0].subscription_type; 
        this.UPlanValidity = parsedJson.data[2][0].validity_from + " - " + parsedJson.data[2][0].validity_to;
      }
      this.GblImage  = parsedJson.data[0][0].user_image;
      this.linkedin  = parsedJson.data[0][0].linkedin;           
      if (this.GblImage != '') {
        this.defaultimg = false;
        
      }
      else {
        this.GblImage = "https://stocksherpa.blob.core.windows.net/userimages/img_avatar.png";
      }
     

      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });
  }


  buynow() {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 8000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.subscriptiontype == undefined || this.subscriptiontype.trim() == '') {
      toastOptions.msg += 'Subscription Type is required' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = { 
        UserId: this.UserId, 
        subscriptiontype: this.subscriptiontype,
        Type: "Renewal"
      };

      this.spinner.show();
      return this.http.post("buysubscription", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        //this.spinner.hide();
        if (parsedJson.code==200) { 
          debugger        
          var Response_url = parsedJson.UserData[1][0].Response_url; 
          var key = parsedJson.UserData[1][0].APIKey;
          var amount = parsedJson.UserData[1][0].NetAmount;
          var order_id =  parsedJson.data.id;
          var logo = parsedJson.UserData[1][0].logo;
          var currency =  parsedJson.UserData[1][0].currency;
          var Cname = parsedJson.UserData[1][0].sitename;
          var description = parsedJson.UserData[1][0].sitedescription;
          var color = parsedJson.UserData[1][0].themecolor;
          var name =  parsedJson.UserData[0][0].first_name;
          var email =  parsedJson.UserData[0][0].email;
          var contact = parsedJson.UserData[0][0].mobile_no;         
         
          this.options = {
            "key": key, 
            "amount": amount, 
            "currency": currency,
            "name": Cname,
            "description": description,
            "image": logo,
            "order_id": order_id, 
            "callback_url": Response_url,
            "prefill": {
                "name": name,
                "email": email,
                "contact": contact
            },
            "notes": {
                "address": ""
            },
            "theme": {
                "color": color
            },
            "modal": {
              "ondismiss": function(){
                location.reload()
              }
          }
        };

          this.rzp1 = new this.Auth.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
 
                    
        } 
        else {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
          this.spinner.hide();
        }  
      
      }).catch(err => {
        debugger
        toastOptions.msg = "An error occurred while buying a subscription, Please try again later";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
      


    }

    
  }

  public uploader: FileUploader = new FileUploader({
    url: URL,
    disableMultipart : false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']


    });

  public onFileSelected(files: EventEmitter<File[]>) {
    debugger
    if ((files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg" || files[0].type == "image/PNG" || files[0].type == "image/JPEG" || files[0].type == "image/JPG") && files[0].size <= 1000000) {
      this.spinner.show();
      const formData: FormData = new FormData();
      formData.append("UserId", this.UserId);
      formData.append("file", files[0], files[0]['name']);

      return this.http.post('imageupload', formData).toPromise().then(res => {
        debugger;
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if (parsedJson.message == "Success") {
          this.GblImage = parsedJson.path;
          var toastOptions: ToastOptions = {
            title: "Alert",
            showClose: true,
            timeout: 3000,
            theme: 'material',
            onAdd: (toast: ToastData) => {
            },
            onRemove: function (toast: ToastData) {
            }
          };
          this.defaultimg = false;
          toastOptions.msg = "Image has been uploaded successfully";
          this.toastyService.success(toastOptions);
        }
        else {
          var toastOptions: ToastOptions = {
            title: "Alert",
            showClose: true,
            timeout: 3000,
            theme: 'material',
            onAdd: (toast: ToastData) => {
            },
            onRemove: function (toast: ToastData) {
            }
          };
          toastOptions.msg = "Image not uploaded!";
          this.toastyService.error(toastOptions);
        }
        this.spinner.hide();

      })
    }
    else {
      if (files[0].size >= 1000001) {
        var toastOptions: ToastOptions = {
          title: "Alert",
          showClose: true,
          timeout: 3000,
          theme: 'material',
          onAdd: (toast: ToastData) => {
          },
          onRemove: function (toast: ToastData) {
          }
        };
        toastOptions.msg = "Image size not more than 1MB";
        this.toastyService.error(toastOptions);
      }
      else {
        var toastOptions: ToastOptions = {
          title: "Alert",
          showClose: true,
          timeout: 3000,
          theme: 'material',
          onAdd: (toast: ToastData) => {
          },
          onRemove: function (toast: ToastData) {
          }
        };
        toastOptions.msg = "File type not allowed to upload";
        this.toastyService.error(toastOptions);
      }

    }
  }

  imageremove() {
    this.spinner.show();
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    const Userdetails = { 
      UserId: this.UserId 
    };

    return this.http.post('imagedelete', Userdetails).toPromise().then(res => {     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      if (parsedJson.data[0][0].Msg == "Success") {
        this.GblImage = "https://stocksherpa.blob.core.windows.net/userimages/img_avatar.png";
        this.defaultimg = true;
        toastOptions.msg = "Image has been removed successfully";
        this.toastyService.success(toastOptions);
      }
      this.spinner.hide();
   }).catch(err => {
        this.spinner.hide();
        toastOptions.msg = "An error occurred while saving the record, Please try again later";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    }


    linkeinupdate() {
      this.spinner.show();
      var toastOptions: ToastOptions = {
        title: "Alert",
        showClose: true,
        timeout: 3000,
        theme: 'material',
        onAdd: (toast: ToastData) => {
        },
        onRemove: function (toast: ToastData) {
        }
      };
      const Userdetails = { 
        UserId: this.UserId ,
        Linkedin: this.linkedin
      };
  
      return this.http.post('linkedinupdate', Userdetails).toPromise().then(res => {     
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if (parsedJson.data[0][0].Msg == "Success") {
          
          toastOptions.msg = "Linkedin has been updated successfully";
          this.toastyService.success(toastOptions);
        }
        this.spinner.hide();
     }).catch(err => {
          this.spinner.hide();
          toastOptions.msg = "An error occurred while saving the record, Please try again later";
          this.toastyService.error(toastOptions);
          this.spinner.hide(); 
          return err;
        });
      }

}
