import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-authheader',
  templateUrl: './authheader.component.html',
  styleUrls: ['./authheader.component.css']
})
export class AuthheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
 

    $(window).scroll(function() {
      var sticky = $('#header'),
        scroll = $(window).scrollTop();
       
      if (scroll >= 40) { 
        sticky.addClass('fixed'); }
      else { 
       sticky.removeClass('fixed');
    
    }
    });
    
    if($(window).width() >= 992){
    $('a[href^="#home"]').addClass('active');
    }

  $(window).scroll(function(){
    
    if($(window).width() >= 992){
    var scrollPos = $(document).scrollTop();
    if (scrollPos === 0)
    {
        $('a[href^="#home"]').addClass('active');
        return;
    }

    $('.nav-item-temp .nav-link').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href")); 
        if ((refElement.position().top-70) <= scrollPos && (refElement.position().top-70) + refElement.height() > scrollPos) {
            $('.nav-item-temp .nav-link').removeClass("active");
            currLink.addClass("active");
        } else {
            currLink.removeClass("active");
        }
    });
  }

}) 
 
    $(document).ready(function() {
      $('a[href*=\\#]').on('click', function(e){
        if(e.target.className=='nav-link nav-link-temp' || e.target.className=='nav-link  nav-link-temp active') {
            e.preventDefault();
            if($(window).width() >= 992){
              if(e.target.text != $(".nav-item-temp .active").html() ){
                $('html, body').animate({
                  scrollTop : $(this.hash).offset().top - 20
              }, 500);
              return false;
              }
              else { 
                return false;
              }            
              
            }
            else { 
              var navMain = $("#navbarSupportedContent");
              navMain.collapse('hide');
              $('html, body').animate({
                scrollTop : $(this.hash).offset().top - 70
            }, 500);
            }
           
          }
          else {
            return false;
          }
      
      });
  });

	$('.navbar-toggler').on('click', function(){
     
    var canSee = $("#navbarSupportedContent").is(":visible");
    if(canSee){
      var navMain = $("#navbarSupportedContent");
      navMain.collapse('hide');
      return false;
    }
}); 

  }

}
