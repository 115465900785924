export const environment = {
  production: true,
  apiendpoint:"https://console.stocksherpa.in/api/",
  indexName: 'stocksherpa',
  industryName:'stock_industry',
  applicationid:'QDONW7SZ8K',
  apikey :'dd540f0f11971083bebbdd31f4a23bf9' 
};


