import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import { PagerService } from '../_services/Pager/index';
import algoliasearch from 'algoliasearch/lite';
import {environment} from '../../environments/environment.prod';

const searchClient = algoliasearch(
  environment.applicationid,
  environment.apikey
);

declare var $: any;

@Component({
  selector: 'app-paid-byindustry',
  templateUrl: './paid-byindustry.component.html',
  styleUrls: ['./paid-byindustry.component.css']
})
export class PaidByindustryComponent implements OnInit {
  logindetails: any; 
  UserName: string = "";
  keyword: string="";
  data: any;  
  industry_name:string="";
  UserId: string="";
  industryname: string="";
  dtlreportdata: any;
  portfoliodata:any;
  industryDetails: any; 
  NorecordfoundTeext:string ="";
  pager: any = {}; 
  pagedItems: any[];
  TotalItems :any =[];
  pledged_stocks_color:string ="";
  CompanytNme:string="";
  StockBasedOn:string ="";
  config = {
    indexName: environment.industryName,
    searchClient
  };
  

  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private pagerService: PagerService,
    private CompanyService: CompanyService,
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
       //this.logindetails = JSON.parse(localStorage.getItem("user_details")); 
       var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
       this.logindetails = JSON.parse(decrypted); 
       this.UserName = this.logindetails.first_name;
       this.UserId = this.logindetails.UserId;
       this.keyword = 'name'; 
       this.dtlreportdata = [];
       this.industryDetails = [];
       this.portfoliodata = [];

       $('.nav-item .homemnu').removeClass("active");
       $('.nav-item .oktomnu').removeClass("active");
       $('.nav-item .stratgymnu').removeClass("active");
       $('.nav-item .commnu').removeClass("active"); 
       $('.fhmenumar .mktmnu').removeClass("activetab");
       $('.fhmenumar .topmnu').removeClass("activetab");
       $('.fhmenumar .myAcnt').removeClass("activemenu");    
       $('.fhmenumar .myfolio').removeClass("activemenu"); 
       
       $('.fhmenumar .indusmnu').addClass("activetab"); 
     }


     

  public searchParameters = {
    query: '',
    id:''
  };

  public setQuery({ query,id }: { id:string, query: string }) {
    if(query !="")
    {
      this.searchParameters.query = query;
      this.searchParameters.id = id;
      console.log(this.searchParameters.query+" "+this.searchParameters.id);
  
      this.industryname=this.searchParameters.query;
      this.industryname = this.searchParameters.id;
     
    }
  
  }

   public onChange(eve) {
    console.log(eve);
    this.industryname="";
    $('#divbdrid').removeClass('divbdr');
    // $(".datahide").hide();    
   }

   public onInputChange(eve){
    console.log(eve);
    this.industryname="";
   }

   
   
     go() {
       var toastOptions: ToastOptions = {
         title: "Alert",
         showClose: true,
         timeout: 5000,
         theme: 'material',
         onAdd: (toast: ToastData) => {
         },
         onRemove: function (toast: ToastData) {
         }
       };
       toastOptions.msg = "";
       if (this.industryname == undefined || this.industryname.trim() == '') {
         toastOptions.msg += 'Industry Name is required' + '</br>';
       } 
   
       if (toastOptions.msg != "") {
         this.toastyService.error(toastOptions);
       }
       else {
         const company = {
           industryname: this.industryname,
           UserId: this.UserId
         };
         this.spinner.show();
         return this.http.post("getindustrydetails", company).toPromise().then(res => {
           
           const parseJson = JSON.stringify(res);
           const parsedJson = JSON.parse(parseJson); 
           this.industryDetails = parsedJson.data;
           if(this.industryDetails.length ==0)
           {
             this.NorecordfoundTeext ="No records found";
           }
           this.industryDetails.forEach(element => {
             var SplitText = element.okto_score_aianalysis.split("_");
             if(SplitText.length == 2)
             {
               element.okto_score_aianalysis = SplitText[0].toString();
               element.okto_score_aianalysis_belowtxt = SplitText[1].toString();
             }
           });
           this.spinner.hide();
           $('.moonshotid').removeClass('basedonactive');
           $('.excellentid').removeClass('basedonactive');
           $('.othersid').removeClass('basedonactive');
         }).catch(err => {
           this.spinner.hide();
           return err;
         });
       }
   
     }
   
     viewreport(Id){ 
       var toastOptions: ToastOptions = {
         title: "Alert",
         showClose: true,
         timeout: 5000,
         theme: 'material',
         onAdd: (toast: ToastData) => {
         },
         onRemove: function (toast: ToastData) {
         }
       };
       toastOptions.msg = "";
       const company = {
         companyid: Id,
         UserId: this.UserId
       }; 
       this.spinner.show();
       return this.http.post("paidcompanydetailedreport", company).toPromise().then(res => { 
         const parseJson = JSON.stringify(res);
         const parsedJson = JSON.parse(parseJson);
         this.dtlreportdata = parsedJson.data[0];
         if((parseFloat(this.dtlreportdata.pledged_stocks))>0)
         {
           this.pledged_stocks_color ="red";
         }
         else{
           this.pledged_stocks_color ="green";
         }
         $('#myModaldash7').modal("show");
         this.spinner.hide();
       }).catch(err => {
         toastOptions.msg = "An error occurred while retrieving the record.";
         this.toastyService.error(toastOptions);
         this.spinner.hide(); 
         return err;
       });
     }

     getindustrydetails(industryname)
     {
      this.industryDetails =[];
      this.pager = {};
      const industry = {
        industryname:industryname,
        industrytype:''
      }
      this.industryname = industryname;
      this.spinner.show();
      return this.http.post("getindustrydetails", industry).toPromise().then(res => {
       
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        
        this.industryDetails = parsedJson.data;

        this.industryDetails.forEach(element => {
          if(element.octo_color!="green-moon")
        {
          element.octo_color ="white-moon";
        }
        });
        
        this.TotalItems = this.industryDetails;
        if(this.TotalItems.length ==0)
        {
          this.NorecordfoundTeext ="No records found";
        }
        this.setPage(1);
        
        this.dtlreportdata =[];
        $('html, body').animate({
          scrollTop: $("#datas").offset().top -100
        });
        
           $('.moonshotid').removeClass('basedonactive');
           $('.excellentid').removeClass('basedonactive');
           $('.othersid').removeClass('basedonactive');
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });


     }
     setPage(page: number) {
      if (page < 1 || page > this.pager.totalPages) {
          return;
      }
  
      // get pager object from service
      this.pager = this.pagerService.getPager(this.industryDetails.length, page);
  
      // get current page of items
      this.pagedItems = this.industryDetails.slice(this.pager.startIndex, this.pager.endIndex + 1);
      
  }

  FliterEvent(basedon){
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.industryname == undefined || this.industryname.trim() == '') {
      toastOptions.msg += 'Industry Name is required' + '</br>';
    } 

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      
      
   
    this.industryDetails =[];
    this.pager = {};
    const industry = {
      industryname:this.industryname,
      industrytype:basedon
    }
    
    this.spinner.show();
    return this.http.post("getindustrydetails", industry).toPromise().then(res => {
     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.industryDetails = parsedJson.data;

      this.industryDetails.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.industryDetails;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
      this.setPage(1);
      
      this.dtlreportdata =[];
      $('html, body').animate({
        scrollTop: $("#datas").offset().top-100
      });

      if(basedon =="MOONSHOT")
      {
        $('.moonshotid').addClass('basedonactive');
        $('.excellentid').removeClass('basedonactive');
        $('.othersid').removeClass('basedonactive');
        
        this.StockBasedOn =basedon;
      }
      else if(basedon =="EXCELLENT")
      {
        $('.moonshotid').removeClass('basedonactive');
        $('.excellentid').addClass('basedonactive');
        $('.othersid').removeClass('basedonactive');
        this.StockBasedOn =basedon;
      }
      else if(basedon =="OTHERS")
      {
        $('.moonshotid').removeClass('basedonactive');
        $('.excellentid').removeClass('basedonactive');
        $('.othersid').addClass('basedonactive');
        this.StockBasedOn ="OTHERS";
      }
     
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });
  }

  }

  getindustrydetails1()
  {
    
    this.industryDetails =[];
    this.pager = {};
    const industry = {
      industryname:this.industryname,
      industrytype:''
    }
    
    this.spinner.show();
    return this.http.post("getindustrydetails", industry).toPromise().then(res => {
     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.industryDetails = parsedJson.data;

      this.industryDetails.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.industryDetails;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
      this.setPage(1);
      
      this.dtlreportdata =[];
      
      $('html, body').animate({
        scrollTop: $("#datas").offset().top-100
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });

  }
   
     addportfolio(stockId)
     {
       var toastOptions: ToastOptions = {
         title: "Alert",
         showClose: true,
         timeout: 5000,
         theme: 'material',
         onAdd: (toast: ToastData) => {
         },
         onRemove: function (toast: ToastData) {
         }
       };
       toastOptions.msg = "";
       const stocks = {
         stockheaderid: stockId,
         userid: this.UserId
       }; 
       this.spinner.show();
       return this.http.post("paidaddportfolio", stocks).toPromise().then(res => { 
         const parseJson = JSON.stringify(res);
         const parsedJson = JSON.parse(parseJson);
         
         this.portfoliodata = parsedJson.data[0];
          if(this.portfoliodata.code == 200)
          {
           toastOptions.msg = "Added Successfully.";
           this.toastyService.success(toastOptions);
          }
          else
          {
           toastOptions.msg = this.portfoliodata.Msg;;
           this.toastyService.error(toastOptions);
          }
         this.spinner.hide();
       }).catch(err => {
         toastOptions.msg = "An error occurred while retrieving the record.";
         this.toastyService.error(toastOptions);
         this.spinner.hide(); 
         return err;
       });
     }

     
  expand(id){    
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }

   
    
   
  }


