import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../../_services/authorization.service'; 
declare var $:any;

@Component({
  selector: 'app-nd-lp',
  templateUrl: './nd-lp.component.html',
  styleUrls: ['./nd-lp.component.css']
})
export class NdLpComponent implements OnInit {
  firstname: string ="";
  lastname: string =""; 
  mobile: string =""; 
  email: string =""; 
  title: string ="";
  chkrobot: boolean = false;  

  constructor(private router: Router,private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) {
      window.location.href = 'https://stocksherpa.in'; return; 
    }

  ngOnInit(): void {
    window.location.href = 'https://stocksherpa.in';
    return;
    if($(window).width() >= 992){
      $('#nd-home').addClass('active');

      $('#nd-abt').removeClass('active');
      $('#nd-faq').removeClass('active');
      $('#nd-cact').removeClass('active');

      }
      
    $(function() {
      $('.popup-youtube, .popup-vimeo').magnificPopup({ 
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
      });
  });


$('#blog_slider1').owlCarousel({
  loop:true,
  autoplay:true,
  margin:10,
  dots:false, 
  nav:false,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});



  }

  signup1(plantype)
{
  debugger
  localStorage.setItem("_plantype", plantype);
  this.router.navigate(['/login'])
}
Register() {    
  debugger;
  var toastOptions: ToastOptions = {
    title: "Alert",
    showClose: true,
    timeout: 8000,
    theme: 'material',
    onAdd: (toast: ToastData) => {
    },
    onRemove: function (toast: ToastData) {
    }
  };
  toastOptions.msg = "";
  if (this.title == undefined ) {
    this.title ="";
  }
  if (this.firstname == undefined || this.firstname.trim() == '') {      
    ////toastOptions.msg += 'Name is required' + '</br>';
    this.firstname ="";
  }
  if (this.lastname == undefined || this.lastname.trim() == '') {
    this.lastname ="";
  }
  if (this.email == undefined || this.email.trim() == '') {
    toastOptions.msg += 'E-mail is required' + '</br>';
  }
  else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
    toastOptions.msg += 'E-mail is invalid'+ '</br>';
  }
  if (this.mobile == undefined || this.mobile == '') {
    ////toastOptions.msg += 'Mobile No. is required' + '</br>';
    this.mobile="";
  }
  else { 
      if((/^\d{10}$/.test(this.mobile))) {
      }
      else {
        if (this.mobile.length != 10) {
          toastOptions.msg += 'Mobile No. should be ten digits' + '</br>'; 
        }
        else {
          toastOptions.msg += 'Mobile No. is invalid' + '</br>'; 
        }
      }
  }
  if (this.chkrobot == undefined || this.chkrobot ==false) {
    toastOptions.msg += 'I am not a robot is required' + '</br>';
  }

  if (toastOptions.msg != "") {
    this.toastyService.error(toastOptions);
  }
  else {
    const user = {
      title: this.title,
      first_name: this.firstname,
      last_name:  this.lastname,
      email:  this.email,
      mobile_no: this.mobile,
      password: "",
      subscriptiontype: "Free"
    };
    
   
    this.spinner.show();
    return this.http.post("registers", user).toPromise().then(res => {
      debugger
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      if(parsedJson.code==200){
       // window.location.href = parsedJson.data;
      this.title="";
      this.firstname="";
      this.lastname="";
      this.mobile="";
      this.email="";
      this.chkrobot = false;
      //toastOptions.msg = parsedJson.data[0][0].Msg;
      //this.toastyService.success(toastOptions);
      
      // setTimeout(() => {
        $('#myModaln1').trigger('click');
      // }, 3000); 
        $("#myModaln2").modal('show');

        
      }
      else if(parsedJson.code==400) {
        toastOptions.msg = parsedJson.data[0][0].Msg;
        this.toastyService.error(toastOptions);
      }
      else {
        toastOptions.msg = "An error occurred while creating user.";
        this.toastyService.error(toastOptions);
      }
      this.spinner.hide();
    }).catch(err => {
      toastOptions.msg = "An error occurred while saving the record.";
      this.toastyService.error(toastOptions);
      this.spinner.hide(); 
      return err;
    });
  
  
   }
}
DownloadOktomap()
{
  
  const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'https://sherpa.azureedge.net/pdf/oktomap_stocksherpa.pdf');
      link.setAttribute('download', `oktomap_stocksherpa.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
}
}
