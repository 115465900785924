import { Component, OnInit,NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 

declare var $: any;

@Component({
  selector: 'app-lp',
  templateUrl: './lp.component.html',
  styleUrls: ['./lp.component.css']
})
export class LpComponent implements OnInit {
  title: string ="";
  firstname: string ="";
  lastname: string =""; 
  mobile: string =""; 
  email: string =""; 
  subscriptiontype: string="Free"; 
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
         
$('#home-slider').owlCarousel({
  loop:true,
  autoplay:true, 
  dots:true,
  //navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:false,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});

$('#about-slider').owlCarousel({
  loop:true,
  autoplay:true,
  margin:10,
  dots:false,
  navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});
$('#blog_slider').owlCarousel({
  loop:true,
  autoplay:true,
  margin:10,
  dots:false,
  navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});
$('#blog_slider1').owlCarousel({
  loop:true,
  autoplay:true,
  margin:10,
  dots:false,
  navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});
  $('#faq_slider').owlCarousel({
  loop:true,
  autoplay:false,
  margin:10,
  dots:false,
  navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});

    $('#faql').owlCarousel({
      animateOut: 'slideOutDown',
      animateIn: 'slideInRight',
      loop: true,
      autoplay: false,
      responsive:{
        0:{
          items:1
        },
        600:{
          items:1
        },
        768:{
          items:2
        },
        992:{
          items:3
        }
      },
      slideBy: 1,
      nav: true,
      dots: false,
      navText: ["<i class='fa fa-caret-up' aria-hidden='true'></i>", "<i class='fa fa-caret-down' aria-hidden='true'></i>"],

    });
   






//$("#divbdrLft").css({'height':($("#divbdrRht").height()+'px')});
  }

  ngAfterContentChecked(): void { 
    $("#divbdrLft").height($("#divbdrRht").height()); 
    $(".Free-Edt").height($(".ss-paid_logo").height());
    $(".dview1").height($(".fredtcnt").height()-$(".paidedtcnt").height() + 20 -($(".ss-paid_logo").height()-$(".Free-Edt").height()));
    $(".dview3").height($(".pricedtls").height()-$(".prodemail1").height() - 25);

  }

  signup(plantype)
  {
    localStorage.setItem("_plantype", plantype);
    this.router.navigate(['/login'])
  }

  Readmore() 
  {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("Rdmore");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "read less"; 
      moreText.style.display = "inline";
    }
  }

  Register() {   
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 8000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.title == undefined || this.title.trim() == '') {
      toastOptions.msg += 'Title is required' + '</br>';
    }
    if (this.firstname == undefined || this.firstname.trim() == '') {
      toastOptions.msg += 'Name is required' + '</br>';
    }    
    if (this.mobile == undefined) {
      toastOptions.msg += 'Mobile No. is required' + '</br>';
    }
    else { 
        if((/^\d{10}$/.test(this.mobile))) {
        }
        else {
          if (this.mobile.length != 10) {
            toastOptions.msg += 'Mobile No. should be ten digits' + '</br>'; 
          }
          else {
            toastOptions.msg += 'Mobile No. is invalid' + '</br>'; 
          }
        }
    } 
    if (this.email == undefined || this.email.trim() == '') {
      toastOptions.msg += 'Email Address is required' + '</br>';
    }
    else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
      toastOptions.msg += 'Email Address is invalid'+ '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        title: this.title,
        first_name: this.firstname,
        last_name:  "",
        email:  this.email,
        mobile_no: this.mobile,
        password: "",
        subscriptiontype: this.subscriptiontype
      };
      
     
      this.spinner.show();
      return this.http.post("registers", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if(parsedJson.code==200){ 
        this.title="";
        this.firstname="";
        this.lastname="";
        this.mobile="";
        this.email=""; 
        //toastOptions.msg = parsedJson.data[0][0].Msg;
        //this.toastyService.success(toastOptions); 

        $("#myModaln2").modal('show'); 
        
        }
        else if(parsedJson.code==400) {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
        }
        else {
          toastOptions.msg = "An error occurred while creating user.";
          this.toastyService.error(toastOptions);
        }
        this.spinner.hide();
      }).catch(err => {
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    
    
     }
  }

}
