import { Component, Inject, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';

@Component({
  selector: 'app-autocomplete',
  template: `
  <div>
  <span class="clearable">
    <input
      matInput
      [matAutocomplete]="auto"
      (keyup)="handleChange($event)"
      style="width: 100%; padding: 10px;"
      (input)="onTextChange.emit({query: $event.target.value})"
      [placeholder]="placeholderval"
      [(ngModel)]="matAutoInput"
      (focus)="focusFunction()"
    />
    
  <span *ngIf="matAutoInput !=''" (click)="onClear($event)" type="button" class="close clearable__clear">&times;</span>
 
    
   </span>
    <mat-autocomplete
      #auto="matAutocomplete"
      style="margin-top: 30px; max-height: 100px"   >
      <div *ngIf="matAutoInput !=''">
      <div *ngFor="let index of state.indices || []">
        <mat-option
          *ngFor="let option of index.hits"
          [value]="option.stockname"
          (click)="onQuerySuggestionClick.emit({ id: option.id, query: option.stockname })"
        >
          {{ option.stockname }}
        </mat-option>
      </div>
      </div>
    </mat-autocomplete>
  </div>
`
})
export class AutocompleteComponent extends BaseWidget {
  state: {
    query: string;
    refine: Function;
    indices: object[];
  };
  matAutoInput: string="";
  @Output() onQuerySuggestionClick = new EventEmitter<{ id: string,query: string }>();  
  @Output() onTextChange= new EventEmitter<{ query: string }>(); 
  @Input() placeholderval:string;
  @Input() ngInput: string;

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) {
    super('AutocompleteComponent');
  }

  public handleChange($event: KeyboardEvent) {
    
    this.state.refine(($event.target as HTMLInputElement).value);
  }

  public ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
    this.matAutoInput=this.ngInput;

  }
  public onClear($event) {
    this.matAutoInput="";
    this.onTextChange.emit($event); 
    
  }
  public focusFunction() {  
    this.state.indices = [];  
  }
}