import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncrDecrService } from '../_services/encrdecr.service';
@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.css']
})
export class PaymentsuccessComponent implements OnInit {

  logindetails: any;

  constructor(private router: Router,
    private EncrDecr: EncrDecrService) { }

  ngOnInit(): void {
    //this.logindetails = JSON.parse(localStorage.getItem("user_details")); 
     
    var logindetails  = localStorage.getItem("user_details"); 
    if(logindetails != null){
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
      this.logindetails = JSON.parse(decrypted); 
    }

  
    
    setTimeout(() => {
      if(logindetails!=null) {
        if (this.logindetails.user_type == "Paid") {
          this.router.navigate(['/premium-dashboard'])
        }
        else {
          localStorage.clear(); 
          this.router.navigate(['/login'])
        }
      }
      else {
        localStorage.clear(); 
        this.router.navigate(['/login'])
      }
    
    }, 5000);

  }
}
