<div id="nd-your-faq" class="nd-faq-stt">
    <div class="container-fluid">
        <div id="nd-hme-mobile">
            <h1 class="nd-faq-head"><span>YOUR</span>FAQ's</h1>
            <p class="nd-faq-dtls">As part of a path-breaking community, you are bound to have questions about what exactly makes STOCKSherpa one of it's kind to achieve financial freedom with cutting edge Ai guide of the Indian stock market.<br>Our FAQ section keeps updated with answers to every single question you may ever have. Read on-</p>
            <div class="row">
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 offset-md-4 offset-lg-4 offset-sm-4">
                    <div class="nd-faq-qt">
                        <div id="nd_faq_slider" class="owl-carousel owl-theme">
                            <div class="item">
                                <div class="nd-faq-content-tt">
                                    <div class="nd-faq-content-st">
                                        <h3><a data-toggle="modal" data-target="#nd-myModal-1">Ai<br>Analysis</a></h3>
                                    </div>
                                    <div class="nd-faq-content-st">
                                        <h3><a href="#" data-toggle="modal" data-target="#nd-myModal12">What are 21 stock ratios?</a></h3>
                                    </div>
                                    <div class="nd-faq-content-st" style="background: none;border: none;"></div>
                                    <div class="nd-faq-content-st">
                                        <h3><a data-toggle="modal" data-target="#nd-myModal-3">Why 21 <br>stock ratios?<br></a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="nd-faq-content-tt">
                                    <div class="nd-faq-content-st">
                                        <h3><a href="#" data-toggle="modal" data-target="#nd-myModal-2">What is Moonshot stocks?<br></a></h3>
                                    </div>
                                    <div class="nd-faq-content-st">
                                        <h3><a data-toggle="modal" data-target="#nd-myModal-4">What is<br><span class="nd-hmdtlsbold">STOCK</span>Sherpa score?</a></h3>
                                    </div>
                                    <div class="nd-faq-content-st" style="background: none;border: none;"></div>
                                    <div class="nd-faq-content-st">
                                        <h3><a data-toggle="modal" data-target="#nd-myModal-5">What is<br><span class="nd-hmdtlsbold">STOCK</span>Sherpa map?</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="nd-hme-desktop">
            <div class="nd-faqimgdiv"><img src="../../../assets/images/faq-bg-dview.png"></div>
            <h1 class="nd-faq-head-dview">FAQ</h1>
            <div class="row">
                <div class="col-12 col-sm-5 col-md-5 col-lg-5 offset-lg-2 offset-md-2 offset-sm-2">
                    <p class="nd-faq-dtls" style="text-align: left;">As part of a path-breaking community, you are bound to have questions about what exactly makes STOCKSherpa one of it's kind to achieve financial freedom with cutting edge Ai guide of the Indian stock market.<br>Our FAQ section keeps updated with answers to every single question you may ever have. Read on-</p>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                    <div id="nd_faq_slider-1" class="owl-carousel owl-theme">
                        <div class="item">
                            <div class="nd-faq-content-tt">
                                <div class="nd-faq-content-st">
                                    <h3><a data-toggle="modal" data-target="#nd-myModal-1">Ai<br>Analysis</a></h3>
                                </div>
                                <div class="nd-faq-content-st">
                                    <h3><a href="#" data-toggle="modal" data-target="#nd-myModal12">What are 21 stock ratios?</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="nd-faq-content-tt">
                                <div class="nd-faq-content-st">
                                    <h3><a data-toggle="modal" data-target="#nd-myModal-3">Why 21 <br>stock ratios?<br></a></h3>
                                </div>
                                <div class="nd-faq-content-st">
                                    <h3><a href="#" data-toggle="modal" data-target="#nd-myModal-2">What is Moonshot stocks?<br></a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="nd-faq-content-tt">
                                <div class="nd-faq-content-st">
                                    <h3><a data-toggle="modal" data-target="#nd-myModal-4">What is<br><span class="nd-hmdtlsbold">STOCK</span>Sherpa score?</a></h3>
                                </div>
                                <div class="nd-faq-content-st">
                                    <h3><a data-toggle="modal" data-target="#nd-myModal-5">What is<br><span class="nd-hmdtlsbold">STOCK</span>Sherpa map?</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="nd-myModal-2" class="modal fade nd-faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <h3>What is <br>MoonShot stocks?</h3>
                <p>Moonshot stocks offer the highest return on investment and growth with the lowest risk.</p>
                <p>Moonshot stocks usually offer any investor, between 2 to 10 times return on investment over a 2 years horizon.</p>
            </div>
        </div>
    </div>
</div>
<div id="nd-myModal-3" class="modal fade nd-faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <h3>Why <br>21&nbsp;Stock&nbsp;ratios?<br></h3>
                <p>The best way to measure the financial health of stocks is by analysing financial data from P&amp;L, balance sheet and cash flow statements. Only Ai can compute such large volumes of data over a 5 years period.</p>
                <p>The Indian stock market has 4000+ shares listed. Only 2% of the stocks are fundamentally strong and worth investing.&nbsp;<span class="nd-hmdtlsbold">STOCK</span>Sherpa is the only Ai platform that uses 21 financial ratios to analyse stocks. Most stock analysts, websites or stock brokers use only 6 ratios.</p>
            </div>
        </div>
    </div>
</div>
<div id="nd-myModal12" class="modal fade faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <div class="nd-logo-section">
                    <p>21 stock ratio analysis<br></p><a><img src="../../../assets/images/011.png"></a>
                </div>
                <section class="nd-ratios">
                    <div class="nd-ratio1">
                        <h1>FUNDAMENTAL BULLET PROOF RATIOS<br></h1>
                        <ul>
                            <li>MARKET<br>CAPITALIZATION</li>
                            <li>PROFITABILITY<br>RATIOS</li>
                            <li>EPS<br></li>
                            <li>SOLVENCY<br>RATIOS<br></li>
                            <li>ROCE&nbsp;RATIO<br></li>
                            <li>SALES<br>RATIOS<br></li>
                            <li>ROE&nbsp;RATIO<br></li>
                            <li style="background: #63bbe1;">LIQUIDITY<br>RATIOS<br></li>
                        </ul>
                    </div>
                    <div class="nd-ratio1 nd-ratio2">
                        <h1>VALUATION RATIOS<br></h1>
                        <ul>
                            <li>PE<br>RATIOS</li>
                            <li>P/S<br>RATIOS</li>
                            <li>P/BV&nbsp;<br>RATIOS<br></li>
                            <li>P/FCF<br>RATIOS<br></li>
                        </ul>
                    </div>
                    <div class="nd-ratio1 nd-ratio3">
                        <h1>MOONSHOT&nbsp;RATIOS<br></h1>
                        <ul>
                            <li>INTRINSIC<br>VALUE</li>
                            <li>CROIC</li>
                            <li>PEG<br>RATIO<br></li>
                            <li>FCF/<br>SALES<br></li>
                            <li>PROMOTERS<br>HOLDING<br></li>
                            <li>PLEDGED<br>SHARES<br></li>
                            <li>PIOTROSKI<br>SCORE<br></li>
                            <li style="background: #63bbe1;">ALTMAN Z<br>SCORE<br></li>
                        </ul>
                    </div>
                    <div style="text-align: center;"><a class="nd-bth" data-dismiss="modal">Return&nbsp;to&nbsp;Home<br></a></div>
                </section>
            </div>
        </div>
    </div>
</div>
<div id="nd-myModal-1" class="modal fade nd-faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <h3>Ai Analysis<br></h3>
                <p><span class="nd-hmdtlsbold">STOCK</span>Sherpa simplifies the financial analysis with 21 stock ratios using sophisticated Ai algorithms to identify winning stocks in&nbsp;<span style="text-decoration: underline;font-weight: bold;">30 seconds</span>&nbsp;.</p>
                <p>High growth investing has not been so simple :&nbsp;<span style="text-decoration: underline;font-weight: bold;">Watch video</span>&nbsp;.</p>
            </div>
        </div>
    </div>
</div>
<div id="nd-myModal-4" class="modal fade nd-faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <h3>What is <br><span class="nd-hmdtlsbold">STOCK</span>Sherpa <br>score?<br></h3>
                <p><span class="nd-hmdtlsbold">STOCK</span>Sherpa’s score is summary of the 21 ratios used to identify great stocks to invest.</p>
                <p>They include</p>
                <ol>
                    <li>9&nbsp;Fundamental&nbsp;ratios<br></li>
                    <li>4 Valuation ratios</li>
                    <li>8 MoonShot ratios (Future)</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div id="nd-myModal-5" class="modal fade nd-faq-popup" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"><button class="btn close" type="button" data-dismiss="modal">X</button></div>
            <div class="modal-body">
                <h3>What is <br><span class="nd-hmdtlsbold">STOCK</span>Sherpa&nbsp;map?<br></h3>
                <p><span class="nd-hmdtlsbold">STOCK</span>Sherpa map is reference notes for high growth investing.</p>
                <p><strong>For&nbsp;Free&nbsp;subscribers:</strong><br></p>
                <p><span class="nd-hmdtlsbold">STOCK</span>Sherpa&nbsp;map Part 1 - Simple GUIDE to consistently beat stock market experts with Ai.</p>
                <p><strong>For Paid subscribers:</strong><br></p>
                <p><span class="nd-hmdtlsbold">STOCK</span>Sherpa map Part 2 - Strategies for high growth investing to achieve financial freedom.</p>
            </div>
        </div>
    </div>
</div>
