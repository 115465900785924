import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import { PagerService } from '../_services/Pager/index';
declare var $: any;

@Component({
  selector: 'app-paid-top100',
  templateUrl: './paid-top100.component.html',
  styleUrls: ['./paid-top100.component.css']
})
export class PaidTop100Component implements OnInit {
  toprankDetails: any; 
  NorecordfoundTeext:string ="";
  pager: any = {}; 
  pagedItems: any[];
  portfoliodata:any;
  logindetails: any; 
  UserName: string = "";
  UserId: string="";
  marketName:string ="";
  TotalItems :any =[];
  toprank:string ="";
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private pagerService: PagerService,
    private CompanyService: CompanyService,
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 
    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    this.toprankDetails =[];
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.nav-item .oktomnu').removeClass("active");
     $('.nav-item .stratgymnu').removeClass("active");
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar .mktmnu').removeClass("activetab");
    $('.fhmenumar .myAcnt').removeClass("activemenu");    
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.fhmenumar .topmnu').addClass("activetab"); 
    if($(window).width() >= 992) {
      $('.freedb').css('min-height',$(window).height()-189);
      }
  }

  gettopstocks(toprank)
  {
    if(toprank =='Top10')
    {
      this.toprank ="TOP 10";
    }
   else if(toprank =='Top25')
    {
      this.toprank ="TOP 25";
    }
    else if(toprank =='Top42')
    {
      this.toprank ="TOP 42";
    }
    else if(toprank =='Top88')
    {
      this.toprank ="TOP 88";
    }
    else if(toprank =='Top100')
    {
      this.toprank ="TOP 100";
    }
     
    this.toprankDetails =[];
    this.pager = {};
    const ranks = {
      action:toprank,
      userid:1
    }
    this.spinner.show();
    return this.http.post("gettoprankdetails", ranks).toPromise().then(res => {
     debugger;
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.toprankDetails = parsedJson.data;

      this.toprankDetails.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.toprankDetails;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
      this.setPage(1);
            
      $('html, body').animate({
        scrollTop: $("#datas").offset().top -75
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });


   }
   setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }
  
    // get pager object from service
    this.pager = this.pagerService.getPager(this.toprankDetails.length, page);
  
    // get current page of items
    this.pagedItems = this.toprankDetails.slice(this.pager.startIndex, this.pager.endIndex + 1);   
  
  
  } 
  addportfolio(stockId)
{
  var toastOptions: ToastOptions = {
    title: "Alert",
    showClose: true,
    timeout: 5000,
    theme: 'material',
    onAdd: (toast: ToastData) => {
    },
    onRemove: function (toast: ToastData) {
    }
  };
  toastOptions.msg = "";
  const stocks = {
    stockheaderid: stockId,
    userid: this.UserId
  }; 
  this.spinner.show();
  return this.http.post("paidaddportfolio", stocks).toPromise().then(res => { 
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    
    this.portfoliodata = parsedJson.data[0];
     if(this.portfoliodata.code == 200)
     {
      toastOptions.msg = "Added Successfully.";
      this.toastyService.success(toastOptions);
     }
     else
     {
      toastOptions.msg = this.portfoliodata.Msg;;
      this.toastyService.error(toastOptions);
     }
    this.spinner.hide();
  }).catch(err => {
    toastOptions.msg = "An error occurred while retrieving the record.";
    this.toastyService.error(toastOptions);
    this.spinner.hide(); 
    return err;
  });
}

  expand(id){    
    debugger;
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }
  

}

