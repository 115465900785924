<div class="container-fluid wel freedb comn">
    <div class="inshead">
        <div class="rtntxt">
            <a href="javascript:void(0,0)" [routerLink]="['/premium-dashboard']">Return to Dashboard</a>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-6  offset-md-2 offset-lg-3" style="margin-top: 15px;">
            <div style="width: 126px; height: 123px; background-color: #f9fafc; margin: 0 auto; border-radius: 50%; border: 3px solid #ebebeb;">
                <img src="{{GblImage}}" width="200" height="auto"  class="avatar1"><br />
            </div>

            <div style="text-align: center;margin-top: 5px;">
                <span class="hidden-file">
                    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
                </span>
                <div class="btn-group" (click)="fileInput.click()">
          
                    <button type="button" class="btn btn-primary">
                        Upload
                    </button>
                </div> 

                <div *ngIf="defaultimg==false"  class="btn-group"  style="padding-left: 10px;" (click)="imageremove()">
              
                 <button type="button" class="btn btn-danger">
                        Remove
                    </button>
                </div>
            </div>
          

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-6  offset-md-2 offset-lg-3" style="margin-top: 15px;">
            <div class="sanalysis" style="padding-left:10% !important">
                <p class="text-left sahead" style="margin-left: 10%;">USER DETAILS </p>
                <div class="sacontents" style="text-transform: capitalize;">
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-md-1 col-lg-1">
                        </div>
                        <div class="col-12 col-md-11 col-lg-11" style="font-size: 14px;">
                            <p><span style="width: 110px;display: inline-flex;">Name</span> <span>: </span> <span
                                    style="color: #f58220;">{{AccName}}</span> </p>
                            <p><span style="width: 110px;display: inline-flex;">Email</span><span>: </span> <span
                                    style="color: #f58220;">{{AccEmail}}</span></p>
                            <p><span style="width: 110px;display: inline-flex;">Mobile</span><span>: </span> <span
                                    style="color: #f58220;">{{Accmobile_no}}</span></p>
                            <p><span style="width: 110px;display: inline-flex;">Subscription Type</span><span>: </span>
                                <span style="color: #f58220;">{{Accsubscription_type}}</span></p>
                            <p><span style="width: 110px;display: inline-flex;">Validity</span><span>: </span> <span
                                    style="color: #f58220;">{{AccValidity}}</span></p>
                                    <p><span style="width: 110px;display: inline-flex;">Linkedin</span><span>: </span> <span style="color: #f58220;">  
                                        <input type="text" class="form-control" name="linkedin" [(ngModel)]="linkedin" style="width: 50%;display: inline-block;padding: .3rem .15rem;">
                                        &nbsp;<i (click)="linkeinupdate()" class="fa fa-check"></i></span></p>
                                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="upcomingArr.length !=0">
        <div class="col-12 col-sm-12 col-md-8 col-lg-6  offset-md-2 offset-lg-3" style="margin-top: 15px;">
            <div class="sanalysis" style="padding-left:10% !important">
                <p class="text-left sahead" style="margin-left: 10%;">UPCOMING SUBSCRIPTION</p>
                <div class="sacontents" style="text-transform: capitalize;">
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-md-1 col-lg-1">
                        </div>
                        <div class="col-12 col-md-11 col-lg-11" style="font-size: 14px;">
                            <p><span style="width: 110px;display: inline-flex;">Subscription Type</span><span>: </span>
                                <span style="color: #f58220;">{{UPlansubscription_type}}</span></p>
                            <p><span style="width: 110px;display: inline-flex;">Validity</span><span>: </span> <span
                                    style="color: #f58220;">{{UPlanValidity}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="upcomingArr.length ==0"> 
        <div class="col-12 col-sm-12 col-md-8 col-lg-6  offset-md-2 offset-lg-3" style="margin-top: 15px;">
            <div class="sanalysis" style="padding-left:10% !important">
                <p class="text-left sahead" style="margin-left: 10%;">BUY SUBSCRIPTION</p>
                <div class="sacontents">
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-md-1 col-lg-1">
                        </div>
                        <div class="col-12 col-md-11 col-lg-11" style="font-size: 14px;">
                            <div class="row" style="margin-bottom: 13px;">
                                <div class="col-lg-4 col-sm-4 col-4" style="text-align: left; padding-right: 0;">
                                    <input type="radio" id="monthlysst" name="subscriptiontype" value="Monthly"
                                        [(ngModel)]="subscriptiontype">
                                    <span for="age2" style="color: #fff;">&nbsp;Monthly
                                        <br />&nbsp;&nbsp;&nbsp;&nbsp;Rs.201/-*</span>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4" style="text-align: left;padding-right: 0;">
                                    <input type="radio" id="yearlysst" name="subscriptiontype" value="Yearly"
                                        [(ngModel)]="subscriptiontype">
                                    <span for="age3" style="color: #fff;">&nbsp;Yearly
                                        <br />&nbsp;&nbsp;&nbsp;&nbsp;Rs.501/-*</span>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4" style="text-align: left;padding: 0;">
                                    <div class="btns"><a href="javascript:void(0,0)" class="click_btn"
                                            style="width: 80px;" (click)="buynow()"><span>BUY NOW</span></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <br /><br />
</div>