import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  password: string="";
  confirmpassword: string="";
  UserRowId: string="";
  UserEmail: string="";
  PageLoadEmail: string="";
 
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    this.UserRowId = this.route.snapshot.paramMap.get('id'); 
    this.pageload()

  }

  pageload() {
    const user = { 
      rowid: this.UserRowId
  };
  this.spinner.show();
  return this.http.post("getuseremail", user).toPromise().then(res => {
    debugger
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    this.PageLoadEmail = parsedJson.data[0][0].email; 
    this.spinner.hide();
  }).catch(err => {
    this.spinner.hide();
    return err;
  });
  }

  reset(){
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.password == undefined || this.password == '') {
      toastOptions.msg += 'New Password is required' + '</br>';
    }
    else {
      if(this.password.length < 6){
        toastOptions.msg += 'Password should be grater than or equal six digits' + '</br>'; 
      }

    }
    if (this.confirmpassword == undefined || this.confirmpassword == '') {
      toastOptions.msg += 'Confirm Password is required' + '</br>';
    }else {
      if(this.password != this.confirmpassword ){
        toastOptions.msg += 'Password and Confirm Password is not match' + '</br>';
      }
    }


    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        rowid: this.UserRowId,
        password: this.password, 
      };
      this.spinner.show();
      return this.http.post("resetpassword", user).toPromise().then(res => {
       
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.spinner.hide();
        if (parsedJson.data[0][0].Code == "200") {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.success(toastOptions);
         setTimeout(() => {
          this.router.navigate(['/login'])
         }, 3000);       
        }
        else if(parsedJson.data[0][0].Code == "400") {            
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
        }
      
      }).catch(err => { 
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    }
  }

}
