<header id="header">
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary authhdr bg-light">

    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
      <div class="navsplit">
        <div id="logo">
          <a href="#"><h1 _ngcontent-cfl-c124="">
            <span>Stock</span>Sherpa</h1>
            <p>Your Ai Stock Guide</p>
          </a>  
            
        </div>
       
      <a  class="login lplogin" [routerLink]="['/login']">LOGIN</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"><i class="fa fa-navicon"></i></span>
            
          </button>
      </div>
    </div>
 
    
 
 
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto1">
        <li class="nav-item nav-item-temp">
          <a class="nav-link  nav-link-temp" data-toggle="collapse"  href="#home">HOME</a>
        </li>
       
          <li class="nav-item nav-item-temp">
            <a class="nav-link nav-link-temp" data-toggle="collapse" href="#product">PRODUCTS  </a>
          </li>
          <li class="nav-item nav-item-temp">
            <a class="nav-link nav-link-temp" data-toggle="collapse" href="#team">TEAM  </a>
          </li>
          <li class="nav-item nav-item-temp">
            <a class="nav-link nav-link-temp" data-toggle="collapse" href="#faq">FAQS </a>
          </li>
          <li class="nav-item nav-item-temp">
            <a class="nav-link nav-link-temp" data-toggle="collapse" href="#contact">CONTACT </a>
          </li>   
          <li class="nav-item">
            <a class="nav-link"    [routerLink]="['/login']" >LOGIN </a>
          </li>   
      </ul>
       
    </div>
  </nav>
</header>
 