<div>
    <div class="failurediv" style="text-align: center;">
        <img src="../../assets/images/Payment-unsuccessful.jpg" >
    </div>
    <div style="font-size: 28px; text-align: center;color:red;">Sorry !</div>
    <div style="text-align: center;color: red;">
        <p style="font-size: 24px;word-spacing: 125%;line-height: 125%;">Your payment failed.<br>Please try again.
        </p>
    </div>
</div>
 