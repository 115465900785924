import {Injectable} from '@angular/core'; 
import { Httpclient } from './authorization.service';
import { Subject } from 'rxjs' 

function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root'  
  })
  
  export class AuthService {

    get nativeWindow() : any {
        return _window();
    }

    constructor(private http: Httpclient) { }

} 