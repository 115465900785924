import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import { PagerService } from '../_services/Pager/index';
import {environment} from '../../environments/environment.prod';

declare var $: any;
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  logindetails: any; 
  UserId: string="";
  pledged_stocks_color:string ="";
  data: any;
  TotalItems :any =[];
  NorecordfoundTeext:string ="Processing...";
  pager: any = {}; 
  pagedItems: any[];
  dtlreportdata: any;
  portfoliodata :any;
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private pagerService: PagerService,
    private CompanyService: CompanyService,
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {

    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 
    this.UserId = this.logindetails.UserId;
    this.dtlreportdata = [];
    this.portfoliodata = [];
    this.TotalItems =[];
    this.pageload();
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar .mktmnu').removeClass("activetab");    
    $('.fhmenumar .topmnu').removeClass("activetab"); 
    $('.fhmenumar .myAcnt').removeClass("activemenu"); 
    
    $('.fhmenumar .myfolio').addClass("activemenu"); 
    if($(window).width() >= 992) {
      $('.freedb').css('min-height',$(window).height()-189);
      }
  }
  pageload() {
    const user = { 
      user_id: this.UserId
  };
    
    this.spinner.show();
    return this.http.post("getpremiumportfoliohistory", user).toPromise().then(res => {
     debugger;
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.portfoliodata = parsedJson.data;

      this.portfoliodata.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.portfoliodata;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
      this.setPage(1);
            
      $('html, body').animate({
        scrollTop: $("#datas").offset().top
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });


   }
   setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }
  debugger;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.portfoliodata.length, page);
  
    // get current page of items
    this.pagedItems = this.portfoliodata.slice(this.pager.startIndex, this.pager.endIndex + 1);   
  
  
  } 

  deleteportfolio(Id)
{
  var toastOptions: ToastOptions = {
    title: "Alert",
    showClose: true,
    timeout: 5000,
    theme: 'material',
    onAdd: (toast: ToastData) => {
    },
    onRemove: function (toast: ToastData) {
    }
  };
  toastOptions.msg = "";
  const company = {
    Portfolio_TableId: Id,
    UserId: this.UserId
  }; 
  this.spinner.show();
  return this.http.post("paiddeleteportfolio", company).toPromise().then(res => { 
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    this.portfoliodata = parsedJson.data[0];
    if(this.portfoliodata[0].code == 200)
    {
      debugger
      toastOptions.msg = "Deleted Successfully.";
      this.toastyService.success(toastOptions);
      this.portfoliodata = parsedJson.data[1];

      this.portfoliodata.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.portfoliodata;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
      this.setPage(1);
            
      $('html, body').animate({
        scrollTop: $("#datas").offset().top
      });
      this.spinner.hide();
     }
     else
     {
      debugger
      toastOptions.msg = "An error occurred while retrieving the record.";
      this.toastyService.error(toastOptions);
     }
    
  }).catch(err => {
    debugger
    toastOptions.msg = "An error occurred while retrieving the record.";
    this.toastyService.error(toastOptions);
    this.spinner.hide(); 
    return err;
  });
  
}



  expand(id){    
    debugger;
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }





}
