import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-nd-aboutus',
  templateUrl: './nd-aboutus.component.html',
  styleUrls: ['./nd-aboutus.component.css']
})
export class NdAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if($(window).width() >= 992){
     
      $('#nd-abt').addClass('active');
      $('#nd-okto').removeClass('active');
      $('#nd-home').removeClass('active');
      $('#nd-faq').removeClass('active');
      $('#nd-cact').removeClass('active');

      }
  }

}
