import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import algoliasearch from 'algoliasearch/lite';
import {environment} from '../../environments/environment.prod';
const searchClient = algoliasearch(
  environment.applicationid,
  environment.apikey
);

declare var $: any;

@Component({
  selector: 'app-paid-compare',
  templateUrl: './paid-compare.component.html',
  styleUrls: ['./paid-compare.component.css']
})
export class PaidCompareComponent implements OnInit {

  
  logindetails: any; 
  UserName: string = "";
  keyword: string="";
  data: any;
  CompanytNme1: string ="";
  CompanytNme2: string ="";
  CompanytNme3: string ="";
  CompanytNme4: string ="";
  CompanytNme5: string ="";

  data1: any;
  companyid1: string="";
  companyid2: string="";
  companyid3: string="";
  companyid4: string="";
  companyid5: string="";
  
  companyname1: string="";
  companyname2: string="";
  companyname3: string="";
  companyname4: string="";
  companyname5: string="";
  
  compareDetails: any;
  pledged_stocks_color:string ="";
  UserId: string="";
  dtlreportdata: any;
  DCStockDetails: any;
  
  okto_score_aianalysis_belowtxt ="";
  portfoliodata:any;
  config = {
    indexName: environment.indexName,
    searchClient
  };

  fundamental_ratios: string="-";
  valuation_ratios: string="-";
  moonshot_ratios: string="-";
  okto_score: string="-"; 
  fundamental_color: string="";
  valuation_color: string="";
  moonshot_color: string="";
  okto_color: string="";
  white_moon:string =""; 
  company_name:string="" 
  companyname: string="";
  companyiddtlreport: string=""; 
  yesterday_date:string;
  fr_aianalysis: string=""; 
  vr_aianalysis: string="";
  ms_aianalysis: string="";
  okto_score_aianalysis: string="";
  stocksherpaanalysis ="";
  industry_type ="";
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private CompanyService: CompanyService,
    
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    //this.logindetails = JSON.parse(localStorage.getItem("user_details"));
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted);  
    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    this.keyword = 'name'; 
    this.DCStockDetails = [];
    this.portfoliodata = []; 
    this.DCStockDetails = JSON.parse(localStorage.getItem("_stockname_d_c"));

    if(this.DCStockDetails == null || this.DCStockDetails === undefined || this.DCStockDetails.companyname=="") 
    {
      this.DCStockDetails =[];
    }
 
   
    if(this.DCStockDetails.length != 0) {      
      this.CompanytNme1=this.DCStockDetails.companyname;
      this.companyname1=this.DCStockDetails.companyname;
      this.companyid1 = this.DCStockDetails.companyid;
  }
    this.compareDetails=[];
    this.dtlreportdata=[];

    // this.compareDetails = [{"id": 12},{"id": 13}]
    $('.nav-item .homemnu').removeClass("active");
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar  .mktmnu').removeClass("activetab");
    $('.fhmenumar  .topmnu').removeClass("activetab");
    $('.fhmenumar .myAcnt').removeClass("activemenu");    
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.nav-item .commnu').addClass("active");
  }

  public searchParameters = {
    query: '',
    id:''
  };

  public setQuery1({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

    this.companyname1=this.searchParameters.query;
    this.companyid1 = this.searchParameters.id;
   
  }
   public onChange1(eve) { 
    this.companyid1="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   public onInputChange1(eve){
    
    this.companyid1="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }

   public setQuery2({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

    this.companyname2=this.searchParameters.query;
    this.companyid2 = this.searchParameters.id;
   
  }
   public onChange2(eve) { 
    this.companyid2="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   public onInputChange2(eve){ 
    this.companyid2="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }

  //  3rd Bsearchbox

  
  public setQuery3({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

    this.companyname3=this.searchParameters.query;
    this.companyid3 = this.searchParameters.id;
   
  }
   public onChange3(eve) { 
    this.companyid3="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   public onInputChange3(eve){ 
    this.companyid3="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   //  4th searchbox

  
  public setQuery4({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

    this.companyname4=this.searchParameters.query;
    this.companyid4 = this.searchParameters.id;
   
  }
   public onChange4(eve) { 
    this.companyid4="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   public onInputChange4(eve){ 
    this.companyid4="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }

     //  5th searchbox

  
  public setQuery5({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

    this.companyname5=this.searchParameters.query;
    this.companyid5 = this.searchParameters.id;
   
  }
   public onChange5(eve) { 
    this.companyid5="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }
   public onInputChange5(eve){ 
    this.companyid5="";
    $('#divbdrid1').removeClass('divbdr1');
    if($(window).width() >= 768 && $(window).width() <= 991) {
      $('.moonstar').show();             
    }  
    $(".datahide").hide();  
   }

  Compare(){
    debugger;
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if(this.companyname1 == undefined || this.companyname1.trim() == '') {
      toastOptions.msg += 'First Stock Name is required' + '</br>';
    }
    else if (this.companyid1 == undefined || this.companyid1.trim() == '') {
      toastOptions.msg += 'Select First Stock Name from list' + '</br>';
    } 
     
   

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {

      if(this.DCStockDetails.length !=0) {
        localStorage.removeItem("_stockname_d_c");
      }   

      const company = {
        stockids: this.companyid1+','+this.companyid2+','+this.companyid3+','+this.companyid4+','+this.companyid5, 
      };
      this.spinner.show();
      return this.http.post("getstockcomparedetails", company).toPromise().then(res => {
       debugger;
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.compareDetails = parsedJson.data;

        this.compareDetails.forEach(element => {
          if(element.octo_color!="green-moon")
        {
          element.octo_color ="white-moon";
        }
        });
        $('#divbdrid1').addClass('divbdr1');   
        if($(window).width() >= 768 && $(window).width() <= 991) {
          $('.moonstar').hide();             
        }       
        $(".datahide").show();        
        this.dtlreportdata =[];
        $('html, body').animate({
          scrollTop: $("#scrollMeDown").offset().top
        });
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });

    }
  }

  viewreport(id){ 
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    const company = {
      companyid: id,
      UserId: this.UserId
    }; 
    this.spinner.show();
    return this.http.post("paidcompanydetailedreport", company).toPromise().then(res => { 
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      this.dtlreportdata = parsedJson.data[0];
      if((parseFloat(this.dtlreportdata.pledged_stocks))>0)
        {
          this.pledged_stocks_color ="red";
        }
        else{
          this.pledged_stocks_color ="green";
        }
      this.spinner.hide();
    }).catch(err => {
      toastOptions.msg = "An error occurred while retrieving the record.";
      this.toastyService.error(toastOptions);
      this.spinner.hide(); 
      return err;
    });
  }

  addportfolio(stockId)
  {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    const stocks = {
      stockheaderid: stockId,
      userid: this.UserId
    }; 
    this.spinner.show();
    return this.http.post("paidaddportfolio", stocks).toPromise().then(res => { 
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.portfoliodata = parsedJson.data[0];
       if(this.portfoliodata.code == 200)
       {
        toastOptions.msg = "Added Successfully.";
        this.toastyService.success(toastOptions);
       }
       else
       {
        toastOptions.msg = this.portfoliodata.Msg;;
        this.toastyService.error(toastOptions);
       }
      this.spinner.hide();
    }).catch(err => {
      toastOptions.msg = "An error occurred while retrieving the record.";
      this.toastyService.error(toastOptions);
      this.spinner.hide(); 
      return err;
    });
  }
  modelclose() {
    $('#myModaldash7').modal("hide");
  }


  expand(id){    
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }



}
