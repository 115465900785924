import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service';
import { AuthService } from '../_services/auth.service';

declare var $: any; 
@Component({
  selector: 'app-userpassword',
  templateUrl: './userpassword.component.html',
  styleUrls: ['./userpassword.component.css']
})
export class UserpasswordComponent implements OnInit {
  password: string="";
  confirmpassword: string="";
  UserRowId: string="";
  UserEmail: string="";
  PageLoadEmail: string="";
  options: any;
  rzp1: any;

  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private Auth: AuthService) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    this.UserRowId = this.route.snapshot.paramMap.get('id'); 
    this.pageload()

   

  }

  pageload() {
    const user = { 
      rowid: this.UserRowId
  };
  this.spinner.show();
  return this.http.post("getuseremail", user).toPromise().then(res => {
    debugger
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    this.PageLoadEmail = parsedJson.data[0][0].email; 
    this.spinner.hide();
  }).catch(err => {
    this.spinner.hide();
    return err;
  });
  }

  generate() {
     
    // var form = $('<form action="' + Rurl + '" method="post">' +
    // '<script ' +
    // 'src="'+ url +'"' +
    // ' data-key="'+ key +'"' +
    // ' data-amount="'+ amount +'"' +
    // ' data-currency="INR"'+ 
    // ' data-order_id=""' + 
    // ' data-buttontext="Pay with Razorpay"'+
    // ' data-name="stocksherpa"'+
    // ' data-description="STOCKSherpa is an Ai guide to help you find Moonshot stocks"'+
    // ' data-image="'+ logo +'"' + 
    // ' data-prefill.name="'+ name +'"' + 
    // ' data-prefill.email="'+ email +'"' + 
    // ' data-theme.color="#528FF0"'+

    // '></script>'+
    // '<input type="hidden" custom="Hidden Element" name="hidden" />'+
    // '</form>');
    // $('body').append(form);
    // form.submit();

    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.password == undefined || this.password == '') {
      toastOptions.msg += 'Password is required' + '</br>';
    }
    else {
      if(this.password.length < 6){
        toastOptions.msg += 'Password should be grater than or equal six digits' + '</br>'; 
      }

    }
    if (this.confirmpassword == undefined || this.confirmpassword == '') {
      toastOptions.msg += 'Confirm Password is required' + '</br>';
    }else {
      if(this.password != this.confirmpassword ){
        toastOptions.msg += 'Password and Confirm Password is not match' + '</br>';
      }
    }


    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        rowid: this.UserRowId,
        password: this.password, 
      };
      this.spinner.show();
      return this.http.post("confirmationusers", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        //this.spinner.hide();
        if (parsedJson.code==200) { 
          debugger        
          var Response_url = parsedJson.UserData[1][0].Response_url; 
          var key = parsedJson.UserData[1][0].APIKey;
          var amount = parsedJson.UserData[1][0].NetAmount;
          var order_id =  parsedJson.data.id;
          var logo = parsedJson.UserData[1][0].logo;
          var currency =  parsedJson.UserData[1][0].currency;
          var Cname = parsedJson.UserData[1][0].sitename;
          var description = parsedJson.UserData[1][0].sitedescription;
          var color = parsedJson.UserData[1][0].themecolor;
          var name =  parsedJson.UserData[0][0].first_name;
          var email =  parsedJson.UserData[0][0].email;
          var contact = parsedJson.UserData[0][0].mobile_no;         
         
          this.options = {
            "key": key, 
            "amount": amount, 
            "currency": currency,
            "name": Cname,
            "description": description,
            "image": logo,
            "order_id": order_id, 
            "callback_url": Response_url,
            "prefill": {
                "name": name,
                "email": email,
                "contact": contact
            },
            "notes": {
                "address": ""
            },
            "theme": {
                "color": color
            },
            "modal": {
              "ondismiss": function(){
                location.reload();
              }
          }
        };

          this.rzp1 = new this.Auth.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
 
                    
        }
        else if(parsedJson.code==201) {
          this.UserEmail = parsedJson.data[0][0].email;
          $("#myModaldash5").modal('show');
          this.spinner.hide();

        }
        else if(parsedJson.code==404) {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
          this.spinner.hide();
        }
        else if(parsedJson.code==400) {           
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);  
          this.spinner.hide();
        } 
      
      }).catch(err => {
        debugger
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    }


  }

}
