<div class="container-fluid wel freedb comn">
    <div  class="inshead">
        <div class="rtntxt">
            <a  href="javascript:void(0,0)" [routerLink]="['/premium-dashboard']">Return to Dashboard</a>       
        </div>
        <div class="moonshotdiv">
            <p class="moonshottxt"> 
               <span>&nbsp;&nbsp; MULTI-BAGGER STOCKS - By Industry &nbsp;&nbsp;</span> 
            </p>
            <!-- <p class="acctypetxt">
                <span>FOR PAID USERS ONLY</span>
            </p> -->
        </div>


        
    </div>
    <div class="row">
        <div class="col-9 col-sm-9 col-md-6 col-lg-4 offset-md-3  offset-lg-4" style="padding-top: 15px;">
            <div class="row">
                <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                    <!-- <div class="ais-InstantSearch" style="background: #939598;">
                        <div class="searchbox">
                            <ng-autocomplete [data]="data" [searchKeyword]="keyword" (selected)='selectEvent($event)' minQueryLength="2"
                                (inputChanged)='onChangeSearch($event)' (inputCleared)='onClearSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                [notFoundTemplate]="notFoundTemplate" placeholder="Enter Name of Industry here" style="border-radius:3px !important">
                            </ng-autocomplete>
                
                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>
                
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div> -->
                    <div class="ais-InstantSearch">
                        <ais-instantsearch [config]="config">
                            <div class="searchbox" >
                                <industry-autocomplete (onQuerySuggestionClick)="setQuery($event)" style="height: 30px;"
                                    (onTextChange)="onChange($event)" placeholderval="Enter Name of industry here"
                                    (onClear)="onInputChange($event)" [(ngInput)]="industryname"></industry-autocomplete>
                            </div>
                        </ais-instantsearch>
                    </div>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 justify-content-center"
                style="padding: 0;z-index: 1029;" (click)="getindustrydetails1()" >
                <div class="btns"><a  href="javascript:void(0,0)" data-toggle="modal" data-target="#myModaldash5" class="click_btn" style="height:42px;"><span  style="font-weight: bold;font-size: 17px;">GO!</span></a></div>
            </div>
            </div>
        </div>
        <div class="col-2">
        </div>
    </div>
 
    <div class="loopdiv" style="margin-top:15px;">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="paidver">1</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"> Quick Search - <span  class="whtcolour"> Exponential growth industries &nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                <div class="row mrb15 industryfreebox">
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Platforms')">
                            <div class="schprefix pversion">
                                <span>P</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Platforms</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Electric Vehicles')">
                            <div class="schprefix pversion">
                                <span>EV</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Electric
                                    Vehicles</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('IT Engineering')">
                            <div class="schprefix pversion">
                                <span>IT</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >IT
                                    Engineering</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Exponential Tech')">
                            <div class="schprefix pversion">
                                <span>ET</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Exponential Tech
                                    (IOT,Crypto,3D printing)</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Defence stocks')">
                            <div class="schprefix pversion">
                                <span>DS</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Defence stocks</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Logistics')">
                            <div class="schprefix pversion">
                                <span>L</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Logistics</span>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div class="loopdiv">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="paidver">2</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"> Quick Search - <span  class="whtcolour"> High Growth Industries &nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                <div class="row mrb15 industryfreebox">
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Bulk Drug')">
                            <div class="schprefix pversion">
                                <span>BD</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Bulk Drugs</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Chemicals')">
                            <div class="schprefix pversion">
                                <span>C</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Chemicals</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('FMCG')">
                            <div class="schprefix pversion">
                                <span>F</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >FMCG</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Health Care')">
                            <div class="schprefix pversion">
                                <span>HC</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Health Care</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Pharmaceuticals')">
                            <div class="schprefix pversion">
                                <span>PH</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Pharmaceuticals</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Software')">
                            <div class="schprefix pversion">
                                <span>SW</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Software</span>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>



       
    </div>

    <div class="loopdiv">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="paidver">3</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"> Quick Search - <span  class="whtcolour"> High Capex Industries &nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                <div class="row mrb15 industryfreebox">
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Automobile')">
                            <div class="schprefix pversion">
                                <span>A</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Automobile</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Auto Ancillaries')">
                            <div class="schprefix pversion">
                                <span>AA</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Auto
                                    Ancillaries</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Cement')">
                            <div class="schprefix pversion">
                                <span>CE</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Cement</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Construction')">
                            <div class="schprefix pversion">
                                <span>CO</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Construction</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Diversified')">
                            <div class="schprefix pversion">
                                <span>D</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Diversified</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Electric')">
                            <div class="schprefix pversion">
                                <span>EL</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Electric</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Entertainment')">
                            <div class="schprefix pversion">
                                <span>EN</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                   >Entertainment</span>
                            </div>
        
                        </div>
                    </div>
        
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Engineering')">
                            <div class="schprefix pversion">
                                <span>E</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Engineering</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Fertilizers')">
                            <div class="schprefix pversion">
                                <span>FE</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Fertilizers</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Food Processing')">
                            <div class="schprefix pversion">
                                <span>FP</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Food
                                    Processing</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Metals')">
                            <div class="schprefix pversion">
                                <span>M</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;">Metals</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Miscellaneous')">
                            <div class="schprefix pversion">
                                <span>MI</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Miscellaneous</span>
                            </div>
        
                        </div>
                    </div>
        
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Paints')">
                            <div class="schprefix pversion">
                                <span>P</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Paints</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Pesticides')">
                            <div class="schprefix pversion">
                                <span>PE</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Pesticides</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Petro Chemicals')">
                            <div class="schprefix pversion">
                                <span>PC</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Petro
                                    Chemicals</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Plastic')">
                            <div class="schprefix pversion">
                                <span>PL</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Plastic</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Power')">
                            <div class="schprefix pversion">
                                <span>PO</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Power</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Sugar')">
                            <div class="schprefix pversion">
                                <span>SU</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Sugar</span>
                            </div>
        
                        </div>
                    </div>
        
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Steel')">
                            <div class="schprefix pversion">
                                <span>S</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Steel</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Textile')">
                            <div class="schprefix pversion">
                                <span>T</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Textile</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreebox">
                        <div class="searchrespdiv" (click)="getindustrydetails('Telecommunication')">
                            <div class="schprefix pversion">
                                <span>TC</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                    >Telecommunication</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Tyres')">
                            <div class="schprefix pversion">
                                <span>TY</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Tyres</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Trading')">
                            <div class="schprefix pversion">
                                <span>TR</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Trading</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Others')">
                            <div class="schprefix pversion">
                                <span>O</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Others</span>
                            </div>
        
                        </div>
                    </div>
        
        
        
        
                </div>
            </div>
        </div>

    </div>

    <div class="loopdiv">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="paidver">4</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"> Quick Search - <span  class="whtcolour"> Banks & Financial &nbsp;</span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                <div class="row mrb15" style="margin-left: 0;margin-bottom: 15px;margin-right: 0;">
        
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Banks')">
                            <div class="schprefix pversion">
                                <span>B</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Banks</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv" (click)="getindustrydetails('Finance')">
                            <div class="schprefix pversion">
                                <span>FI</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;" >Finance</span>
                            </div>
        
                        </div>
                    </div>
                    <div class="col-4 rmpadd industryfreeboxextra">
                        <div class="searchrespdiv"  (click)="getindustrydetails('Construction')">
                            <div class="schprefix pversion">
                                <span>C</span>
                            </div>
                            <div class="schprefixval industryelipse" style="vertical-align: top;">
                                <span style="padding-left: 2px;"
                                   >Construction</span>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
       
    </div>
    <div ></div>
    <div id="datas">
    <div  *ngIf="TotalItems.length !=0" >
    <div class="datahide" >
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3" style="padding: 5px;">
                <div class="gviewtxt">
                    <p style="text-transform: uppercase;font-size:12px;">GLANCE VIEW OF - {{industryname}} - {{StockBasedOn}}</p>
                </div>
            </div>
        </div>
    
        
    
        <div class="compareres">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3 compadd">
                    <div class="row">
                        <div class="col-3">
                            <div class="cmpsthead">
                                <p>STOCK NAME</p>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="cmpsrdetail">
                                <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 2px;">
                                        <div class="cmpsrdetailnumblk">
                                            <p class="cmpsrdetailnum">9</p>
                                        </div>
                                        <div class="cmpsrdetailtxtblk">
                                            <p class="cmpsrdetailtxt">Fundamental<br />Ratios </p>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-2">
                            <div class="cmpsrdetail">
                                <div class="cmpsrdetailblk" style="text-align: center;padding-left: 5px;">
                                        <div class="cmpsrdetailnumblk">
                                            <p class="cmpsrdetailnum">4</p>
                                        </div>
                                        <div class="cmpsrdetailtxtblk">
                                            <p class="cmpsrdetailtxt">Valuation<br />Ratios </p>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-2">
                            <div class="cmpsrdetail">
                                <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 5px;">
                                        <div class="cmpsrdetailnumblk">
                                            <p class="cmpsrdetailnum">8</p>
                                        </div>
                                        <div class="cmpsrdetailtxtblk">
                                            <p class="cmpsrdetailtxt">Opportunity<br />Ratios </p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="cmpoktohead">
                                <p>STOCKSherpa <br>Score</p>
                            </div>
                        </div>
                        <div class="col-1">
                            
                        </div>
                    </div>
                </div>
            </div> 
    
            <div class="forloop" *ngFor="let stock of pagedItems;">
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3 compadd">
                        <div class="row">
                            <div class="col-3">
                                <div class="cmpstname">
                                    <p class="stockelipse">{{stock.company_name}}</p>
                                </div>
        
                            </div>
                            <div class="col-2">
                                <div class="cmpstval">
                                    <p>{{stock.fundamental_ratios}}</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="cmpstval">
                                    <p>{{stock.valuation_ratios}}</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="cmpstval">
                                    <p>{{stock.moonshot_ratios}}</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="cmpstoktoval">
                                    <p>{{stock.okto_score}}</p>
                                </div>
                            </div>
                            <div class="col-1">
                                <div class="cmpstexpand">
                                    <i class="fa fa-plus" id="dtlrepexpand{{stock.id}}" (click)="expand(stock.id)"></i>
                                    <br />
                                    <i class="fa fa-minus" style="display: none;" (click)="collapse(stock.id)" id="dtlrepcollapse{{stock.id}}"></i>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> 
                <div id="dtlreport{{stock.id}}" style="display: none;margin-top:15px;">
    
                 
    
                    <div>
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                        <div class="srhead">
                                            <div class="srheadblk">
                                                <p>21 STOCK RATIOS </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-3"  style="padding-right:0;">
                                        <div class="srhead">
                                            <div class="srheadblk">
                                                <p>Ai SCORE </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="srhead">
                                            <div class="srheadblk">
                                                <p>Ai ANALYSIS </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                    
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="row">
                                    <div class="col-3" style="padding-right: 0;">
                                        <div class="srdetail">
                                            <div class="srdetailblk">
                                                    <div class="srdetailnumblk">
                                                        <p class="srdetailnum">9</p>
                                                    </div>
                                                    <div class="srdetailtxtblk">
                                                        <p class="srdetailtxt">Fundamental<br />Ratios </p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" style="padding-right: 0;">
                                        <div class="aidetail">
                                            <div class="aidetailblk" [ngClass]="[stock.fr_color]">
                                                <p > {{stock.fundamental_ratios}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" style="padding-left: 0;">
                                        <div class="aadetail">
                                            <div class="aadetailblk">
                                                <p>{{stock.fr_aianalysis}} SCORE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                        <div class="srdetail">
                                            <div class="srdetailblk">
                                                    <div class="srdetailnumblk">
                                                        <p class="srdetailnum">4</p>
                                                    </div>
                                                    <div class="srdetailtxtblk">
                                                        <p class="srdetailtxt">Valuation<br />Ratios </p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" style="padding-right: 0;">
                                        <div class="aidetail">
                                            <div class="aidetailblk" [ngClass]="[stock.vr_color]">
                                                <p > {{stock.valuation_ratios}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" style="padding-left: 0;">
                                        <div class="aadetail">
                                            <div class="aadetailblk">
                                                <p>{{stock.vr_aianalysis}} PRICE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                        <div class="srdetail">
                                            <div class="srdetailblk">
                                                    <div class="srdetailnumblk">
                                                        <p class="srdetailnum">8</p>
                                                    </div>
                                                    <div class="srdetailtxtblk">
                                                        <p class="srdetailtxt">Opportunity<br />Ratios </p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" style="padding-right:0;">
                                        <div class="aidetail" >
                                            <div class="aidetailblk"  [ngClass]="[stock.ms_color]">
                                                <p> {{stock.moonshot_ratios}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" style="padding-left: 0;">
                                        <div class="aadetail">
                                            <div class="aadetailblk">
                                                <p>{{stock.ms_aianalysis}} SCORE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                        <div class="oktodetail">
                                            <div class="oktodetailblk">
                                                    <p>STOCKSherpa <br> Score</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" style="padding-right: 0;">
                                        <div class="oktoaidetail">
                                            <div class="oktoaidetailblk" [ngClass]="[stock.octo_color]">
                                                <p> {{stock.okto_score}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6" style="padding-left: 0;">
                                        <div class="oktoaadetail">
                                            <div class="oktoaadetailblk">
                                                <p >{{stock.okto_score_aianalysis}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="row mrb15">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                            <div class="sanalysis">
                                <p class="text-left sahead">STOCKSHERPA ANALYSIS</p>
                                <div class="sacontent">             
                                    <p [innerHtml] ="stock.ss_analysis_text"></p>
                                </div>
                               </div>
                        </div>
                    </div>  
                    <div class="row justify-content-center portfolioalign">
                        <div class="col-8 portfoliotxt" (click)="addportfolio(stock.id)">
                            <a  style="font-size: 12px;"> <span>Add to Portfolio</span>&nbsp;<i
                                    class="fa fa-plus" style="color: #f58220;"></i></a>
                        </div>
                    </div> 
                    <div class="row mrb15">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                    <div class="row">
       
                        <div class="col-12" id="divbdrid">
                            <div class="datahide">
                                <div class="dtlreport">
                                    <div class="row dareport"  #scrollMe1>
                                        <div class="col-12">
                                            <p class="headstyle">
                                                <span>Detailed Analysis Report - <span>({{stock.yesterday_date}})</span></span>
                                            </p>
                                        </div>
                    
                                    </div>
                    
                                    <div class="row dtlstockdtls">
                                        <div class="col-12 col-sm-12 col-md-10 col-lg-8 offset-md-1  offset-lg-2">
                                            <div class="row">
                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-right: 0;">
                                                    <p>Stock Name</p>
                                                    <p>Industry</p>
                    
                                                </div>
                                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 bdrl" style="padding-left: 3px;">
                                                    <p>{{stock.company_name}}</p>
                                                    <p>{{stock.industry}}</p>
                    
                                                </div>
                                            </div>
                    
                                        </div>
                                    </div>
                
                                    <div id="aianalysis1"  style="padding: 0 15px;">
                                       
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="aihead">
                                                                <p>AI ANALYSIS</p>
                                                            </div>
                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-9">
                                                            <p class="headstyle">Fundamental Ratios -&nbsp;<span>
                                                                    {{stock.fr_aianalysis}} SCORE</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>EPS</p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.eps}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>ROCE<br /><span>3 years</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.roce}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>ROE<br /><span>3 years</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.roe}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PROFIT GR<br /><span>3 years</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.profit_gr}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>OPM</p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.opm}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>SALES<br /><span>GR</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.sales_growth_3years}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>DEBT TO<br /><span>EQUITY</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.dept_to_equity}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>CURRENT<br /><span>RATIO</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.current_ratio}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-9">
                                                            <p class="headstyle">Valuation Ratios -&nbsp;<span>
                                                                    {{stock.vr_aianalysis}} PRICE</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PRICE<br /><span>TO EARNING</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.price_to_earning}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PRICE<br /><span>TO SALES</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.price_to_sales}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PRICE TO<br /><span>BK VALUE</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.price_to_bk_value}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PRICE TO<br /><span>FCF</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.price_to_fcf}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                    
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-9">
                                                            <p class="headstyle"> Opportunity Ratios -&nbsp;<span>
                                                                    {{stock.ms_aianalysis}} SCORE</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>INTRINSIC<br /><span>VALUE</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.intrinsic_value}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PEG</p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.peg}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>FCF TO<br /><span>SALES</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.fcf_to_sales}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>CROIC</p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.croic}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mrb15">
                                                <div class="col-12">
                                                    <div class="row rmpadding">
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PIOTROSKI<br /><span>SCORE</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.piotroski_score}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>ALTMAN Z<br /><span>SCORE</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.altman_z_score}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>FOUNDER<br /><span>STAKE</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>{{stock.founder_stake}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="aiblock">
                                                                <div class="aitxt">
                                                                    <p>PLEDGED<br /><span>STOCKS</span></p>
                                                                </div>
                                                                <div class="aivalue">
                                                                    <p>
                                                                        {{stock.pledged_stocks}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                            <div class="row mrb15 justify-content-center rmpadding">
                                                <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                                    <div class="oktoblock">
                                                        <div class="oktotxt">
                                                            <p>STOCKSherpa <br><span>Score</span></p>
                                                        </div>
                                                        <div class="oktovalue">
                                                            <p>{{stock.okto_score}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                    </div>
                    
                    
                    
                    
                                   
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                        
                </div> 
                
            </div>
          
        </div>
    </div>
</div>
</div>
    

        <!-- pager -->
        <div style="width: 95%;margin: 0 auto; text-align: center;">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                <li [ngClass]="{disabled:pager.currentPage === 1}">
                    <a (click)="setPage(1)">First</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === 1}">
                    <a (click)="setPage(pager.currentPage - 1)">Previous</a>
                </li>
                <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                    <a (click)="setPage(page)">{{page}}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a (click)="setPage(pager.currentPage + 1)">Next</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a (click)="setPage(pager.totalPages)">Last</a>
                </li>
            </ul> 
           
        </div>

        <div *ngIf="TotalItems.length ==0">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3" style="padding: 5px;">
                    <div class="gviewtxt" *ngIf="NorecordfoundTeext!=''">
                        <p style="text-transform: uppercase;font-size:12px;">GLANCE VIEW OF Industry Name - {{industryname}} - {{StockBasedOn}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                    <p style="color: #FFFFFF;"> {{NorecordfoundTeext}}</p>
                <br/>
                
            </div>
            </div>

           
            
        </div>
        <div class="row" *ngIf ="industryname!=''">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3 basedoninactive">
            <a  class ="moonshotid"  (click)="FliterEvent('MOONSHOT')">MULTI-BAGGER</a>  &nbsp;&nbsp;&nbsp;    
            <a  class ="excellentid"  (click)="FliterEvent('EXCELLENT')">EXCELLENT</a>    &nbsp;&nbsp;&nbsp;
            <a   class="othersid" (click)="FliterEvent('OTHERS')">OTHERS</a>       &nbsp;&nbsp;&nbsp;
            </div>
        </div>
        
        <br/>
        <br/>

        <div class="moonstar">
            <img src="../../assets/images/moon-stars.png" alt="" class="stars2">
        </div>
</div>


