import { Component, OnInit } from '@angular/core'; 
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from 'src/app/_services/authorization.service';

declare var $: any;
@Component({
  selector: 'app-nd-contactus',
  templateUrl: './nd-contactus.component.html',
  styleUrls: ['./nd-contactus.component.css']
})
export class NdContactusComponent implements OnInit {

  name: string="";
  email: string="";
  subject: string="";
  message: string="";
  d_name: string="";
  d_email: string="";
  d_subject: string="";
  d_message: string="";

  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router) 
    { 
      this.toastyConfig.theme = 'material';
    }

  ngOnInit(): void {

    if($(window).width() >= 992){

      $('#nd-cact').addClass('active');
      $('#nd-okto').removeClass('active');
      $('#nd-home').removeClass('active');
      $('#nd-abt').removeClass('active');
      $('#nd-faq').removeClass('active');
     

      }

  }

  sendemail(source){
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 8000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";   
    if(source=="mobile") {
      if (this.name == undefined || this.name.trim() == '') {
        toastOptions.msg += 'Name is required' + '</br>';
      }
      if (this.email == undefined || this.email.trim() == '') {
        toastOptions.msg += 'Email is required' + '</br>';
      } 
      else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
        toastOptions.msg += 'Email is invalid'+ '</br>';
      }
      if (this.subject == undefined || this.subject.trim() == '') {
        toastOptions.msg += 'Subject is required' + '</br>';
      }
      if (this.message == undefined || this.message.trim() == '') {
        toastOptions.msg += 'Message is required' + '</br>';
      }
    }
    else {
       if (this.d_name == undefined || this.d_name.trim() == '') {
        toastOptions.msg += 'Name is required' + '</br>';
      }
      if (this.d_email == undefined || this.d_email.trim() == '') {
        toastOptions.msg += 'Email is required' + '</br>';
      } 
      else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.d_email))) {
        toastOptions.msg += 'Email is invalid'+ '</br>';
      }
      if (this.d_subject == undefined || this.d_subject.trim() == '') {
        toastOptions.msg += 'Subject is required' + '</br>';
      }
      if (this.d_message == undefined || this.d_message.trim() == '') {
        toastOptions.msg += 'Message is required' + '</br>';
      }
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
       var name ="";
       var email ="";
       var subject ="";
       var message ="";
      if(source=="mobile") {
        name = this.name;
        email = this.email;
        subject = this.subject;
        message = this.message;
      }
      else {
        name = this.d_name;
        email = this.d_email;
        subject = this.d_subject;
        message = this.d_message;
      }
      const feedbackdtls = {
        name: name,
        email:  email,
        subject: subject,
        message: message
      };     
      this.spinner.show();
      return this.http.post("contactus", feedbackdtls).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if(parsedJson.code==200) { 
          if(source=="mobile") {
            this.name="";
            this.email="";
            this.subject="";
            this.message="";
          }
          else {
            this.d_name="";
            this.d_email="";
            this.d_subject="";
            this.d_message="";
          }
          toastOptions.msg = "Email sent successfully"
          this.toastyService.success(toastOptions);
        }
        else {
          toastOptions.msg = "An error occurred while sending an email.";
          this.toastyService.error(toastOptions);
        }
        this.spinner.hide();
      }).catch(err => {
        toastOptions.msg = "An error occurred while sending an email.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    
    
     }


  }

}
