import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from "../../app-routing.module";

@Component({
  selector: 'app-authlayout',
  templateUrl: './authlayout.component.html',
  styleUrls: ['./authlayout.component.css']
})
export class AuthlayoutComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private route: ActivatedRoute
    , private elementRef: ElementRef) { 
  }
  showHeader: Boolean = true;
  showFooter: Boolean = true;
  isRoute: Boolean;
  ngOnInit() {
  // this.router.events.subscribe((res: any) => {
  //  debugger
  //   for (let route of AppRoutes) {

  //     var snapRoute = "/" + route.path;
  //     if (snapRoute.match(res.url)) {
  //       this.isRoute = true;
  //     }

  //     if (this.isRoute == true && res.url != "/login" && res.url != "/404") {
  //       this.showHeader = false;
  //       this.showFooter = false;
  //       this.isRoute = false;
  //       break;
  //     }
  //     else {
  //       this.showHeader = true;
  //       this.showFooter = true;
  //       this.isRoute = false;

  //     }


  //   }
  //  });


    }
}
