import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { environment } from 'src/environments/environment.prod';
import { Httpclient } from '../_services/authorization.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastData, ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { EncrDecrService } from '../_services/encrdecr.service';
const searchClient = algoliasearch(
  environment.applicationid,
  environment.apikey
);
declare var $: any;
@Component({
  selector: 'app-free-top100',
  templateUrl: './free-top100.component.html',
  styleUrls: ['./free-top100.component.css']
})
export class FreeTop100Component implements OnInit {
  
  toprankDetails: any; 
  NorecordfoundTeext:string ="";
  CompanytNme:string="";
  industrydetails:any =[];
  TotalItems :any =[];
  logindetails: any; 
  portfoliodata:any;
  UserId: string="";
  UserName: string = "";
  config = {
    indexName: environment.indexName,
    searchClient
  };
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private EncrDecr: EncrDecrService,
    private spinner: SpinnerVisibilityService) {
      this.toastyConfig.theme = 'material';
     }
  ngOnInit(): void {
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 

    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    this.portfoliodata =[];
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar .mktmnu').removeClass("activetab");
    $('.fhmenumar .myAcnt').removeClass("activemenu");    
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.fhmenumar .topmnu').addClass("activetab"); 

    if($(window).width() >= 992) {
      $('.freedb').css('min-height',$(window).height()-189);
      }
   
  }

  public searchParameters = {
    query: '',
    id:''
  };

  public setQuery({ query,id }: { id:string, query: string }) {
    this.searchParameters.query = query;
    this.searchParameters.id = id; 

   
  }
   public onChange(eve) { 

   }
   public onInputChange(eve){
    console.log(eve);

   }
   Upgrade()
   {
     $('#myModaldash5').trigger('click');
     window.location.replace("/#/free-myaccount");
   }
   gettopstocks(toprank)
   {
    this.toprankDetails =[];
    const ranks = {
      action:toprank,
      userid:1
    }
    this.spinner.show();
    return this.http.post("getfreetoprankdetails", ranks).toPromise().then(res => {
     debugger;
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.toprankDetails = parsedJson.data;

      this.toprankDetails.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.toprankDetails;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
     
            
      $('html, body').animate({
        scrollTop: $("#datas").offset().top -75
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });

   }

   addportfolio(stockId)
   {
     var toastOptions: ToastOptions = {
       title: "Alert",
       showClose: true,
       timeout: 5000,
       theme: 'material',
       onAdd: (toast: ToastData) => {
       },
       onRemove: function (toast: ToastData) {
       }
     };
     toastOptions.msg = "";
     const stocks = {
       stockheaderid: stockId,
       userid: this.UserId
     }; 
     this.spinner.show();
     return this.http.post("freeaddportfolio", stocks).toPromise().then(res => { 
       const parseJson = JSON.stringify(res);
       const parsedJson = JSON.parse(parseJson);
       this.portfoliodata = parsedJson.data[0];
        if(this.portfoliodata.code == 200)
        {
         toastOptions.msg = "Added Successfully.";
         this.toastyService.success(toastOptions);
        }
        else
        {
         toastOptions.msg = this.portfoliodata.Msg;;
         this.toastyService.error(toastOptions);
        }
       this.spinner.hide();
     }).catch(err => {
       toastOptions.msg = "An error occurred while retrieving the record.";
       this.toastyService.error(toastOptions);
       this.spinner.hide(); 
       return err;
     });
   }

   expand(id){    
    
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }

}
