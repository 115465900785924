<div class="nd-content">
    <div id="nd-hme-mobile" style="margin-top: 78px;"><a href="#"><img src="../../../assets/images/logo.png"></a></div>
    <div id="nd-hme-desktop"><a href="#"><img src="../../../assets/images/01-full.png"></a></div>
    <div class="nd-login-home"><button class="btn navbar-toggler" type="button" style="padding: .25rem 0.70rem;"><span style="padding: 0 20px 0 0;cursor: pointer;font-size: 1rem;">HOME</span><span class="navbar-toggler-icon" style="width: auto;height: auto;"><i class="fa fa-home" style="font-size: 36px!important;color: #fff;"></i></span></button></div>
</div>
<div class="container-fluid">
    <div class="nd-static-content">
        <h1><strong>Cancellation Policy</strong><br></h1>
    </div>
    <div style="padding: 0 5%;">
        <p>Last updated Auguest 31, 2021<br></p>
        <p>All STOCKsherpa subscription channel plans include a&nbsp;<strong>3-day Cancellation policy.</strong><br></p>
        <p><strong>After 3 days, no refunds will be issued.</strong>&nbsp;Free trials does not include any premium contents during or after the initial plan creation.<br></p>
        <p>Furthermore, STOCKsherpa does not offer prorated refunds for cancelled subscriptions. All subscriptions are recurring and will automatically renew after the end of each paid subscription period. This includes both month-to-month and annual plans.<br></p>
        <p>Similarly, it is subscriber’s responsibility to evaluate STOCKsherpa, including its features, limitations, and system requirements before selecting any Paid Plan.&nbsp;<strong>STOCKsherpa will not issue refunds&nbsp;</strong>to customers on the basis of customers not understanding the system requirements, or the presence of compatibility issues, including inadequate internet speed or consistency, or incompatible devices, operating systems, or browser software versions.&nbsp;<strong>System incompatibility based on system requirements should be tested Free plans and cannot be done in paid plans.</strong><br></p>
        <h2><br>Cancellation Policy<br></h2>
        <p>Customer acknowledges that all subscription fees are charged automatically on a recurring basis&nbsp;<strong>until the Client cancels their channel subscription</strong>&nbsp;(both month-to-month and annual plans). The client may cancel their subscription by logging into their STOCKsherpa Account and going to the applicable channel or billing page, clicking settings, and choosing the “Change” or "Cancel" options under subscription. A confirmation email receipt is sent to the Client with the expiration date of the subscription. The client can learn how to cancel, delete, or update their account here. Please keep in mind that you are solely responsible for properly cancelling your account. You must email support before or on the payment due date. However, you can also contact support if you are having difficulty or need help.<br></p>
        <h2><br>STOCKsherpa’s Refund Policy for Annual Plans<br></h2>
        <p>STOCKsherpa is committed to the success of our customers, but through experience, we have found that users have varying requirements, capabilities, and limitations with regard to the types of devices and software they can use, the way their local network is configured, the speed of their internet connection, etc. These issues will certainly impact a customer’s ability to use STOCKsherpa as it is intended.<br></p>
        <p>Accordingly, STOCKsherpa offers a 3-day Freecancellation and is happy to extend the trial period for users who need more time to evaluate our software and its compatibility with their specific environment.<br></p>
        <p>STOCKsherpa also offers a Month-to-Month Subscription Plan, which allows customers to upgrade, downgrade, and cancel at any time without penalty. We recommend this option for most customers, especially those that do not have full certainty or control over their technology environment.<br></p>
        <p>Finally, STOCKsherpa offers an Annual Subscription Plan at a significant discount. We recommend this plan for customers who are certain that STOCKsherpa is compatible with their environment. It is your responsibility to evaluate STOCKsherpa, including its features, limitations, and system requirements before selecting the Annual Plan.<br></p>
        <p>If you are not certain that STOCKsherpa is a fit for your use case and environment, then you should not choose the Annual Plan.<br></p>
        <p><strong>STOCKsherpa will not issue refunds to Annual Plan customers</strong>&nbsp;on the basis of customers not understanding the system requirements, or the presence of compatibility issues, including inadequate internet speed or consistency, or incompatible devices, operating systems, or browser software versions.<br></p>
        <p>If you choose the Annual Plan, you are entering into a one-year subscription contract that expires 12 months after you sign up. You are responsible for paying for the entire subscription. If you decide to cancel before the term of your subscription is up, then you will still be billed for the remaining months on your subscription, and will still have access to its benefits until it expires.<br></p>
        <h2><br>Contact Us<br></h2>
        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:<br></p>
        <p>OCTOBAN TECHNOLOGY PRIVATE LIMITED<br>No 402, VDB CARDINAL, 6TH MAIN,<br>INDIRANAGAR, BENGALURU, KARNATAKA,<br>560038<br>info@stocksherpa.in<br></p>
    </div>
</div>