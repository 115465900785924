<ng2-toasty [position]="'bottom-right'"></ng2-toasty>
<div id="nd-contct-us" class="nd-contct-us-height">
    <div class="container-fluid">
        <div id="nd-hme-mobile">
            <h1><span>OKTOMAP</span> Download</h1>
            <div>
                <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
                    <!-- <p>Sign me up to be expertly guided on the<br> slippery slopes of the stock market by<br> STOCKSherpa! Your privacy is our priority</p> -->
                    <p>In order to be able to download the OKTOMAP, you need to register with your email address. Additionally we will provide you an account to use your learning.</p>
                    <p>Your privacy is our priority. The data is encrypted and never shared</p>
                    
                    <div class="row">
                       
                       <div class="col-lg-12 col-sm-12 col-12">
         
                         <input type="text" class="form-control" name="firstname" placeholder="Name"  [(ngModel)]="firstname">
         
                       </div>
                     </div>
                     <br>
                   <!-- <input type="text" class="form-control" name="lastname" placeholder="Last Name"  [(ngModel)]="lastname"> -->
                   <input type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="E-mail">
                   <br>
                   <input type="number" class="form-control" name="mobile" placeholder="Mobile No."  [(ngModel)]="mobile" onKeyPress="if(this.value.length==10) return false;">
                   <br>
                  
                          
                   <label class="text-left cnt" style="color: #fff;"><input type="checkbox" name="chkrobot" class="checkbox" [(ngModel)]="chkrobot"> I am not a robot! <span class="checkmark" style="border: 1px solid #f58220;" ></span></label>
                   <br>
                   <div class="sbtn"><input type="submit" value="Download" (click)="Register()" class="click_btn confirm add" style="background: #f58220;cursor: pointer;">			 </div>
                   <br>
                  
                   
               </div>
        </div>
        

        <div id="nd-hme-desktop" class="nd-pad-div nd-add">
            <h1><span>OKTOMAP</span> Download</h1>
           
            <div class="row">
              <div class="col-3">
              </div>

                <div class="col-6">
                    <div>
                        <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
                            <!-- <p>Sign me up to be expertly guided on the<br> slippery slopes of the stock market by<br> STOCKSherpa! Your privacy is our priority</p> -->
                            
                            <p>In order to be able to download the OKTOMAP, you need to register with your email address. Additionally we will provide you an account to use your learning.</p>
                            <p>Your privacy is our priority. The data is encrypted and never shared</p>
                            
                            <div class="row">
                               
                               <div class="col-lg-12 col-sm-12 col-12">
                 
                                 <input type="text" class="form-control" name="firstname" placeholder="Name"  [(ngModel)]="firstname">
                 
                               </div>
                             </div>
                             <br>
                           <!-- <input type="text" class="form-control" name="lastname" placeholder="Last Name"  [(ngModel)]="lastname"> -->
                           <input type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="E-mail">
                           <br>
                           <input type="number" class="form-control" name="mobile" placeholder="Mobile No."  [(ngModel)]="mobile" onKeyPress="if(this.value.length==10) return false;">
                           <br>
                          
                                  
                           <label class="text-left cnt" style="color: #fff;"><input type="checkbox" name="chkrobot" class="checkbox" [(ngModel)]="chkrobot"> I am not a robot! <span class="checkmark" style="border: 1px solid #f58220;" ></span></label>
                           <br>
                           <div class="sbtn"><input type="submit" value="Download" (click)="Register()" class="click_btn confirm add" style="background: #f58220;cursor: pointer;">			 </div>
                           <br>
                            <!-- <p>StockSherpa looks forward to guiding<br> you towards moonshot stocks using its<br> proprietary sophisticated Ai that expertly<br> navigates the treacherous slopes of the<br> stock market with a 21 ratio algorithm!</p> -->
                            <!-- <p>Enjoy the hike! <a [routerLink]="['/']" data-dismiss="modal">Continue to site</a></p> -->
                       </div>
                </div>
            </div>
        </div>


    </div>
</div>
