import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authfooter',
  templateUrl: './authfooter.component.html',
  styleUrls: ['./authfooter.component.css']
})
export class AuthfooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNewTab(url) {
    window.open(url, "_blank");
  }

}
