import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import algoliasearch from 'algoliasearch/lite';
import {environment} from '../../environments/environment.prod';

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts'; 
  

const searchClient = algoliasearch(
  environment.applicationid,
  environment.apikey
);


declare var $: any;
@Component({
  selector: 'app-free-dashboard',
  templateUrl: './free-dashboard.component.html',
  styleUrls: ['./free-dashboard.component.css']
})
export class FreeDashboardComponent implements OnInit { 
  logindetails: any; 
  _stockname_d_c:any;
  UserName: string = "";
  keyword: string="";
  data: any;
  portfoliodata:any;
  companyid: string="";
  industry_name:string ="";
  fundamental_ratios: string="-";
  valuation_ratios: string="-";
  moonshot_ratios: string="-";
  okto_score: string="-";
  CompanytNme: string="";
  fundamental_color: string="";
  valuation_color: string="";
  moonshot_color: string="";
  okto_color: string="";
  white_moon:string ="";
  pledged_stocks_color ="";
  company_name:string="";
  UserId: string="";
  companyname: string="";
  companyiddtlreport: string="";
  dtlreportdata: any;
  cashflowdata:any;
  balancesheetdata:any;
  pandldata:any;
  yesterday_date:string;
  fr_aianalysis: string=""; 
  vr_aianalysis: string="";
  ms_aianalysis: string="";
  okto_score_aianalysis: string="";
  stocksherpaanalysis ="";
  industry_type ="";
  @ViewChild('template') myTemplate: ElementRef;
  @ViewChild('scrollMe1') private myScrollCartContainer: ElementRef;
  config = {
    indexName: environment.indexName,
    searchClient
  };
  lineChartData: ChartDataSets[] = [
    { data: []},
  ];  
  lineChartData1: ChartDataSets[] = [
    { data: []},
  ];
  lineChartLabels: Label[] = [];
  lineChartLabels1: Label[] = [];
  lineChartOptions = {    
    responsive: true,
    title: {
      display: false,
      text: 'CHART',
      fontColor: 'white',  
    },
    scales: {
      xAxes: [{ 
        ticks: {
          fontColor: 'white',            
        } ,
        scaleLabel: {
          display: true, 
          fontColor: 'white',
          fontSize:12,        
      },
      gridLines: {
        color: 'rgba(0,0,0,0.3)',
        lineWidth: 1
      }
      }],
      yAxes: [{ 
        ticks: {
          fontColor: 'white',
        } ,
        scaleLabel: {
          display: true, 
          fontColor: 'white',
          fontSize:12
      },
      gridLines: {
        color: 'rgba(0,0,0,0.3)',
        lineWidth: 1
      }
      }] 
    },
    legend: {
      display: false,
      labels: {
        fontColor: 'white', 
      },
    } 
  }  
  lineChartColors: Color[] = [
    {
      borderColor: '#f58220', 
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';  
  BSEChartData: any;
  NSEChartData: any;

  ChartRecCnt: number;
  ChartPgeCnt: number;
  ChartStartIndex: number;
  ChartEndIndex: number;
  ChartCurrentIndex: number;
  ChartHead: string;


  displayedColumns: any;
  BalancesheetdisplayedColumns:any;
  dataSource: any;
  balancesheetdataSource: any;
  cashflowdataHead: any;
  balancesheetdataHead: any;
  pandldataHead: any;
  PNLdataSource: any;
  pandldisplayedColumns:any;
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private CompanyService: CompanyService,
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material'; 

     }

  ngOnInit(): void {  
    //this.logindetails = JSON.parse(localStorage.getItem("user_details")); 
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 

    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    this.keyword = 'name'; 
    this.dtlreportdata = [];
    this.portfoliodata =[];
    
    window.onhashchange = function() {
      $('.modal-backdrop').css('display','none');
     }

     $('.nav-item .commnu').removeClass("active");
     $('.fhmenumar .indusmnu').removeClass("activetab");
     $('.nav-item .mktmnu').removeClass("activetab");
     $('.nav-item .topmnu').removeClass("activetab");
     $('.nav-item .homemnu').addClass("active");
     $('.fhmenumar .myAcnt').removeClass("activemenu");    
     $('.fhmenumar .myfolio').removeClass("activemenu"); 

     if($(window).width() >= 992) {
      $('.freedb').css('min-height',$(window).height()-189);
      }

     
  
  }

  // // selectEvent(item) {
  // //   this.companyname=item.name;
  // //   this.companyid = item.value;
  // // }

  // // onChangeSearch(val: string) {
  // //   this.companyid="";
  // //   this.companyname=val;
  // //   var item = {
  // //     companyname: val,
  // //   } 
  // //   this.CompanyService.getCompanydetails(item).then(data => { 
  // //     this.data=data.data;  
  // //   }).catch(err => {  
  // //     return err;
  // //   });     
  // // }
  
  // // onFocused(e){
  // //   // do something when input is focused
  // // }
  // // onClearSearch(item){
  // //   
  // //   this.companyname="";
  // //   this.companyid="";
  // // }

 

  go() {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.companyname == undefined || this.companyname.trim() == '') {
      toastOptions.msg += 'Stock Name is required' + '</br>';
    }
    else if(this.companyid == undefined || this.companyid.trim() == '') {
      toastOptions.msg += 'Select Stock Name from list' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const company = {
        companyid: this.companyid,
        UserId: this.UserId
      };
      this.spinner.show();
      return this.http.post("getcompanydetails", company).toPromise().then(res => {
        debugger;
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.fundamental_ratios = parsedJson.data[0][0].fundamental_ratios;
        this.valuation_ratios = parsedJson.data[0][0].valuation_ratios;
        this.moonshot_ratios = parsedJson.data[0][0].moonshot_ratios;
        this.okto_score = parsedJson.data[0][0].okto_score;
        this.company_name = parsedJson.data[0][0].company_name;
        this.companyiddtlreport= parsedJson.data[0][0].id;
        this.fr_aianalysis = parsedJson.data[0][0].fr_aianalysis;
        this.vr_aianalysis = parsedJson.data[0][0].vr_aianalysis;
        this.ms_aianalysis = parsedJson.data[0][0].ms_aianalysis;
        this.okto_score_aianalysis = parsedJson.data[0][0].okto_score_aianalysis;
             
        this.fundamental_color = parsedJson.data[0][0].fr_color;
        this.valuation_color = parsedJson.data[0][0].vr_color;
        this.moonshot_color = parsedJson.data[0][0].ms_color;
        this.okto_color = parsedJson.data[0][0].octo_color;
        this.dtlreportdata = parsedJson.data[1][0];
        this.yesterday_date = this.dtlreportdata.yesterday_date;
        this.industry_type = this.dtlreportdata.market;
        if((parseFloat(this.dtlreportdata.pledged_stocks))>0)
        {
          this.pledged_stocks_color ="red";
        }
        else{
          this.pledged_stocks_color ="green";
        }
        if(this.okto_color!="green-moon")
        {
          this.okto_color ="white-moon";
        }
      this.stocksherpaanalysis =parsedJson.data[0][0].ss_analysis_text;  
     // this.myTemplate.nativeElement.innerHTML =  this.stocksherpaanalysis; 
      $('#divbdrid').addClass('divbdr');

// //       if(parsedJson.data[2].length > 0) {
// //         this.BSEChartData = parsedJson.data[2];
// //       }
// //       else {
// //         this.BSEChartData = parsedJson.data[3];
// //       }   

// //       let BSEmonth = [];
// //       let BSEmonthdata = []; 

// //       //this.ChartStartIndex = 
// //       if(this.BSEChartData.length > 6){
// //         this.ChartEndIndex =  this.BSEChartData.length;
// //         this.ChartCurrentIndex =  this.ChartEndIndex-6;
// //         this.ChartStartIndex = this.ChartEndIndex % 6;
// //       }
// //       else {
// //         this.ChartEndIndex =  this.BSEChartData.length;
// //         this.ChartCurrentIndex =  0;
// //         this.ChartStartIndex=0; 
       
// //       } 
      
// //       if( this.BSEChartData.length >= 12 ){
// //         $('#ChartIconNext').css("cursor",'not-allowed');
// //         $('#ChartIconPrev').css("cursor",'pointer');
// //       }
// //       else {
// //         $('#ChartIconPrev').css("cursor",'not-allowed');
// //         $('#ChartIconNext').css("cursor",'not-allowed');
// //       }

      
// //       for(let i= this.ChartCurrentIndex; i < this.ChartEndIndex; i++ ) {
// //         BSEmonth.push(this.BSEChartData[i].MonthValue);
// //         BSEmonthdata.push(this.BSEChartData[i].AvgClosePrice);
// //       } 
// //       if(this.BSEChartData > 0)
// //       {
// //         this.ChartHead = this.BSEChartData[this.ChartCurrentIndex].MonthValue + " to " + this.BSEChartData[this.ChartEndIndex-1].MonthValue;
// //         this.lineChartLabels = BSEmonth;
// //         this.lineChartData = [{
// //           data: BSEmonthdata,
// //           label: 'Stock prices'
// //         }];
// //       }
     

// //       // let NSEmonth = [];
// //       // let NSEmonthdata = []; 
// //       // for(let i=0;i < this.NSEChartData.length; i++ ) {
// //       //   NSEmonth.push(this.NSEChartData[i].MonthValue);
// //       //   NSEmonthdata.push(this.NSEChartData[i].AvgClosePrice);
// //       // } 
// //       // this.lineChartLabels1 = NSEmonth;
// //       // this.lineChartData1 = [{
// //       //   data: NSEmonthdata,
// //       //   label: 'NSE Stock prices'
// //       // }];

// //       //cashflow table
// //       this.cashflowdata = parsedJson.data[5];

// //       this.cashflowdataHead = parsedJson.data[4];

      

// //       let displayedColumns = [];
      
// //       displayedColumns.push("cashflowdetails");
// //       for(var k=0;k<this.cashflowdataHead.length;k++)
// //       { 
// //          displayedColumns.push(this.cashflowdataHead[k].YRC);
// //       } 

// //       this.displayedColumns = displayedColumns;


      
// //       for(var i=0;i<this.cashflowdata.length;i++)
// //       {
// //         if(this.cashflowdata[i].cashflowdetails=="CashfromOperatingActivityProfit")
// //         {
// //           this.cashflowdata[i].headings="Cash from Operating ActivityProfit";
// //           this.cashflowdata[i].class="A";
// //           this.cashflowdata[i].treeview="Y"; 
// //           this.cashflowdata[i].orderno = 1; 
          
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="fromoperations")
// //         {
// //           this.cashflowdata[i].headings="Profit from operations";
// //           this.cashflowdata[i].class="A1";
// //           this.cashflowdata[i].treeview="Y";
// //           this.cashflowdata[i].orderno = 2;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Receivables")
// //         {
// //           this.cashflowdata[i].headings="Receivables";
// //           this.cashflowdata[i].class="A1A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 3;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Payables")
// //         {
// //           this.cashflowdata[i].headings="Payables";
// //           this.cashflowdata[i].class="A1A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 4;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="LoansAdvances")
// //         {
// //           this.cashflowdata[i].headings="Loans Advances";
// //           this.cashflowdata[i].class="A1A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 5;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="OtherWCitems")
// //         {
// //           this.cashflowdata[i].headings="Other WC items";
// //           this.cashflowdata[i].class="A1A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 6;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Workingcapitalchanges")
// //         {
// //           this.cashflowdata[i].headings="Working capital changes";
// //           this.cashflowdata[i].class="A2";
// //           this.cashflowdata[i].treeview="Y";
// //           this.cashflowdata[i].orderno = 7;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Directtaxes")
// //         {
// //           this.cashflowdata[i].headings="Direct taxes";
// //           this.cashflowdata[i].class="A2A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 8;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="ExceptionalCFitems")
// //         {
// //           this.cashflowdata[i].headings="Exceptional Cash Flow items";
// //           this.cashflowdata[i].class="A2A";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 9;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="CashfromInvestingActivityFixed")
// //         {
// //           this.cashflowdata[i].headings="Cash from Investing Activity";
// //           this.cashflowdata[i].class="B";
// //           this.cashflowdata[i].treeview="Y";
// //           this.cashflowdata[i].orderno = 10;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="CashfromInvestingActivityFixed")
// //         {
// //           this.cashflowdata[i].headings="Cash from Investing Activity";
// //           this.cashflowdata[i].class="B";
// //           this.cashflowdata[i].treeview="Y";
// //           this.cashflowdata[i].orderno = 11;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="assetspurchased")
// //         {
// //           this.cashflowdata[i].headings="Fixed assets purchased";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N"; 
// //           this.cashflowdata[i].orderno = 12; 
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Fixedassetssold")
// //         {
// //           this.cashflowdata[i].headings="Fixed assets sold";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 13;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Investmentspurchased")
// //         {
// //           this.cashflowdata[i].headings="Investments purchased";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 14;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Investmentssold")
// //         {
// //           this.cashflowdata[i].headings="Investments sold";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 15;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Investmentincome")
// //         {
// //           this.cashflowdata[i].headings="Investment income";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 16;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Interestreceived")
// //         {
// //           this.cashflowdata[i].headings="Interest received";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 17;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Dividendsreceived")
// //         {
// //           this.cashflowdata[i].headings="Dividends received";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 18;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Investinsubsidiaries")
// //         {
// //           this.cashflowdata[i].headings="Investin subsidiaries";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 19;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Loanstosubsidiaries")
// //         {
// //           this.cashflowdata[i].headings="Loanstosubsidiaries";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 20;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Sharesacqcancelled")
// //         {
// //           this.cashflowdata[i].headings="Shares acqcancelled";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 21;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Acquisitionofcompanies")
// //         {
// //           this.cashflowdata[i].headings="Acquisition of companies";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 22;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Otherinvestingitems")
// //         {
// //           this.cashflowdata[i].headings="Other investing items";
// //           this.cashflowdata[i].class="B1";
// //           this.cashflowdata[i].treeview="N"; 
// //           this.cashflowdata[i].orderno = 23; 
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="CashfromFinancingActivityProceeds")
// //         {
// //           this.cashflowdata[i].headings="Cash from Financing Activity";
// //           this.cashflowdata[i].class="C";
// //           this.cashflowdata[i].treeview="Y";
// //           this.cashflowdata[i].orderno = 24;  
// //         }
        
// //         else if(this.cashflowdata[i].cashflowdetails=="fromshares")
// //         {
// //           this.cashflowdata[i].headings="Proceeds from shares";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 26;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Proceedsfromborrowings")
// //         {
// //           this.cashflowdata[i].headings="Proceeds from borrowings";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 27;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Dividendspaid")
// //         {
// //           this.cashflowdata[i].headings="Dividends paid";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 28;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Financialliabilities")
// //         {
// //           this.cashflowdata[i].headings="Repayment of borrowings";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 29;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="Otherfinancingitems")
// //         {
// //           this.cashflowdata[i].headings="Other financing items";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 30;  
// //         }
// //         else if(this.cashflowdata[i].cashflowdetails=="NetCashFlow")
// //         {
// //           this.cashflowdata[i].headings="Net CashFlow";
// //           this.cashflowdata[i].class="C1";
// //           this.cashflowdata[i].treeview="N";
// //           this.cashflowdata[i].orderno = 31;  
// //         }
        

// //       }

      

// //       const sorter = (a, b) => {
// //         return a.orderno - b.orderno;
// //      };
// //      const sortByOrder = arr => {
// //         arr.sort(sorter);
// //      };
// //      sortByOrder(this.cashflowdata);

// //       this.dataSource=this.cashflowdata;
      

// //       //Balance Sheet table
// //       this.balancesheetdata = parsedJson.data[7];

// //       this.balancesheetdataHead = parsedJson.data[6];

      

// //       let BalanceSheetdisplayedColumns = [];
      
// //       BalanceSheetdisplayedColumns.push("balancesheetdetails");
// //       for(var k=0;k<this.balancesheetdataHead.length;k++)
// //       { 
// //         BalanceSheetdisplayedColumns.push(this.balancesheetdataHead[k].YRC);
// //       } 

// //       this.BalancesheetdisplayedColumns = BalanceSheetdisplayedColumns;


// //       for (var i=0;i<this.balancesheetdata.length;i++)
// //       {
       
// //         if(this.balancesheetdata[i].balancesheetdetails=="sharecapital")
// //         {
// //           this.balancesheetdata[i].headings="Share Captial";
// //           this.balancesheetdata[i].class="a";
// //           this.balancesheetdata[i].treeview="Y"; 
// //           this.balancesheetdata[i].orderno = 1; 
          
// //         }
        
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="equitycapital")
// //         {
// //           this.balancesheetdata[i].headings="Equity Capital";
// //           this.balancesheetdata[i].class="a1";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno = 2; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="reserves")
// //         {
// //           this.balancesheetdata[i].headings="Reserves";
// //           this.balancesheetdata[i].class="b";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =3;
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="borrowings")
// //         {
// //           this.balancesheetdata[i].headings="Borrowings";
// //           this.balancesheetdata[i].class="c";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =4; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="otherliabilities")
// //         {
// //           this.balancesheetdata[i].headings="Other Liabilities";
// //           this.balancesheetdata[i].class="d";
// //           this.balancesheetdata[i].treeview="Y"; 
// //           this.balancesheetdata[i].orderno =5; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="noncontrollingint")
// //         {
// //           this.balancesheetdata[i].headings="Non Controlling int";
// //           this.balancesheetdata[i].class="d1";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =6; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="tradepayables")
// //         {
// //           this.balancesheetdata[i].headings="Trade Payables";
// //           this.balancesheetdata[i].class="d2";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =7; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="advancefromcustomers")
// //         {
// //           this.balancesheetdata[i].headings="Advance from Customers";
// //           this.balancesheetdata[i].class="d3";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =8; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="otherliabilityitems")
// //         {
// //           this.balancesheetdata[i].headings="Other liability items";
// //           this.balancesheetdata[i].class="d4";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =9; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="totalliabilities")
// //         {
// //           this.balancesheetdata[i].headings="Total Liabilities";
// //           this.balancesheetdata[i].class="e";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =10; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="fixedassets")
// //         {
// //           this.balancesheetdata[i].headings="Fixed Assets";
// //           this.balancesheetdata[i].class="f";
// //           this.balancesheetdata[i].treeview="Y"; 
// //           this.balancesheetdata[i].orderno =11; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="accumulateddepreciation")
// //         {
// //           this.balancesheetdata[i].headings="Accumulated Depreciation";
// //           this.balancesheetdata[i].class="f1";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =12; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="CWIP")
// //         {
// //           this.balancesheetdata[i].headings="CWIP";
// //           this.balancesheetdata[i].class="g";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =13; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="investments")
// //         {
// //           this.balancesheetdata[i].headings="Investments";
// //           this.balancesheetdata[i].class="h";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =14; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="otherassets")
// //         {
// //           this.balancesheetdata[i].headings="Other Assets";
// //           this.balancesheetdata[i].class="i";
// //           this.balancesheetdata[i].treeview="Y"; 
// //           this.balancesheetdata[i].orderno =15; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="tradereceivables")
// //         {
// //           this.balancesheetdata[i].headings="Trade Receivables";
// //           this.balancesheetdata[i].class="i1";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =16; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="cashequivalents")
// //         {
// //           this.balancesheetdata[i].headings="Cash Equivalents";
// //           this.balancesheetdata[i].class="i2";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =17; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="loansnadvances")
// //         {
// //           this.balancesheetdata[i].headings="Loans n Advances";
// //           this.balancesheetdata[i].class="i3";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =18; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="Otherassetitems")
// //         {
// //           this.balancesheetdata[i].headings="Other Asset Items";
// //           this.balancesheetdata[i].class="i4";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =19; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="grossblock")
// //         {
// //           this.balancesheetdata[i].headings="Gross block";
// //           this.balancesheetdata[i].class="j";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =20; 
          
// //         } 
// //         else  if(this.balancesheetdata[i].balancesheetdetails=="totalassets")
// //         {
// //           this.balancesheetdata[i].headings="Total Assets";
// //           this.balancesheetdata[i].class="k";
// //           this.balancesheetdata[i].treeview="N"; 
// //           this.balancesheetdata[i].orderno =21; 
          
// //         } 
        
        


// //       }
     
// //       const balancesheetsorter = (a, b) => {
// //         return a.orderno - b.orderno;
// //      };
// //      const balancesheetsortByOrder = arr => {
// //         arr.sort(balancesheetsorter);
// //      };
// //      balancesheetsortByOrder(this.balancesheetdata);

// //       this.balancesheetdataSource=this.balancesheetdata;


// // //

// // //profit and loss table
// // this.pandldata = parsedJson.data[9];

// // this.pandldataHead = parsedJson.data[8];



// // let pandldisplayedColumns = [];

// // pandldisplayedColumns.push("pnldetails");
// // for(var k=0;k<this.pandldataHead.length;k++)
// // { 
// //   pandldisplayedColumns.push(this.pandldataHead[k].YRC);
// // } 

// // this.pandldisplayedColumns = pandldisplayedColumns;



// // for(var i=0;i<this.pandldata.length;i++)
// // {
// //   if(this.pandldata[i].pnldetails=="sales")
// //   {
// //     this.pandldata[i].headings="Sales";
// //     this.pandldata[i].class="Z";
// //     this.pandldata[i].treeview="Y"; 
// //     this.pandldata[i].orderno = 1; 
    
// //   }
// //   else if(this.pandldata[i].pnldetails=="salesgrowth_per")
// //   {
// //     this.pandldata[i].headings="Sales Growth %";
// //     this.pandldata[i].class="Z1";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 2;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="cost_per")
// //   {
// //     this.pandldata[i].headings="Cost %";
// //     this.pandldata[i].class="Y";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 3;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="expensesmanufacturing")
// //   {
// //     this.pandldata[i].headings="Manufacturing Cost %";
// //     this.pandldata[i].class="X";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 4;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="employeecost_per")
// //   {
// //     this.pandldata[i].headings="Employee Cost %";
// //     this.pandldata[i].class="W";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 5;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="othercost_per")
// //   {
// //     this.pandldata[i].headings="Other Cost %";
// //     this.pandldata[i].class="V";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 6;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="operatingprofit")
// //   {
// //     this.pandldata[i].headings="Operating Profit";
// //     this.pandldata[i].class="U";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 7;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="OPM_per")
// //   {
// //     this.pandldata[i].headings="OPM %";
// //     this.pandldata[i].class="T";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 8;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="otherincome")
// //   {
// //     this.pandldata[i].headings="Other Income";
// //     this.pandldata[i].class="S";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 9;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="interest")
// //   {
// //     this.pandldata[i].headings="Interest";
// //     this.pandldata[i].class="R";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 10;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="depreciation")
// //   {
// //     this.pandldata[i].headings="Depreciation";
// //     this.pandldata[i].class="Q";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 11;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="profitbeforetax")
// //   {
// //     this.pandldata[i].headings="Profit before tax";
// //     this.pandldata[i].class="P";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 12;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="tax_per")
// //   {
// //     this.pandldata[i].headings="Tax %";
// //     this.pandldata[i].class="O";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 13;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="netprofit")
// //   {
// //     this.pandldata[i].headings="Net Profit";
// //     this.pandldata[i].class="N";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 14;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="EPSinRs")
// //   {
// //     this.pandldata[i].headings="EPS in Rs";
// //     this.pandldata[i].class="M";
// //     this.pandldata[i].treeview="N";
// //     this.pandldata[i].orderno = 15;  
// //   }
// //   else if(this.pandldata[i].pnldetails=="dividendpayout_per")
// //   {
// //     this.pandldata[i].headings="Dividend payout %";
// //     this.pandldata[i].class="L";
// //     this.pandldata[i].treeview="N"; 
// //     this.pandldata[i].orderno = 16; 
// //   }
  

// // }


// // const pnlsorter = (a, b) => {
// //   return a.orderno - b.orderno;
// // };
// // const pnlsortByOrder = arr => {
// //   arr.sort(pnlsorter);
// // };
// // pnlsortByOrder(this.pandldata);

// // this.PNLdataSource=this.pandldata;

// // //

  
// //       setTimeout(function(){
// //         $(".A1").hide();
// //         $(".A2").hide();
// //         $(".A1A").hide();
// //         $(".A2A").hide();
// //         $(".B1").hide();
// //         $(".C1").hide();
        
// //         $(".a1").hide();
// //         $(".d1").hide();
// //         $(".d2").hide();
// //         $(".d3").hide();
// //         $(".d4").hide();
// //         $(".f1").hide();
// //         $(".i1").hide();
// //         $(".i2").hide();
// //         $(".i3").hide();
// //         $(".i4").hide();
// //         $(".SpanMinusa").hide();
// //         $(".SpanMinusd").hide();
// //         $(".SpanMinusf").hide();
// //         $(".SpanMinusi").hide();
// //         $(".SpanMinusA").hide();
// //         $(".SpanMinusB").hide();
// //         $(".SpanMinusC").hide();  

// //         $(".SpanMinusA1").hide();
// //         $(".SpanMinusA2").hide();

// //         $(".Z1").hide();
// //         $(".SpanMinusZ").hide();
        
        
// //      }, 500);



      $(".datahide").show();    
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });
    }

  }
  compare()
    {
      
      if(this.companyname !="" && this.companyid!="")
      {
        this._stockname_d_c ={
          companyname:this.companyname,
          companyid:this.companyid
        }
      }
     
      else
      {
        this._stockname_d_c ={
          companyname:"",
          companyid:""
        }
      }

      localStorage.setItem("_stockname_d_c", JSON.stringify(this._stockname_d_c)); 
      this.router.navigate(['/free-compare'])
    }
  

  addportfolio()
  {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    const stocks = {
      stockheaderid: this.companyid,
      userid: this.UserId
    }; 
    this.spinner.show();
    return this.http.post("freeaddportfolio", stocks).toPromise().then(res => { 
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      this.portfoliodata = parsedJson.data[0];
       if(this.portfoliodata.code == 200)
       {
        toastOptions.msg = "Added Successfully.";
        this.toastyService.success(toastOptions);
       }
       else
       {
        toastOptions.msg = this.portfoliodata.Msg;;
        this.toastyService.error(toastOptions);
       }
      this.spinner.hide();
    }).catch(err => {
      toastOptions.msg = "An error occurred while retrieving the record.";
      this.toastyService.error(toastOptions);
      this.spinner.hide(); 
      return err;
    });
  }

  modelclose() {
    $('#myModaldash7').modal("hide");
  }

 

  

  public chartOptions: any = {
    responsive: true
  };

  public searchParameters = {
    query: '',
    id:''
  };

  public setQuery({ query,id }: { id:string, query: string }) {
    if(query !="")
    {
      this.searchParameters.query = query;
      this.searchParameters.id = id;
      console.log(this.searchParameters.query+" "+this.searchParameters.id);
  
      this.companyname=this.searchParameters.query;
      this.companyid = this.searchParameters.id;
     
    }
  
  }

   public onChange(eve) {
    console.log(eve);
    this.companyid="";
    $('#divbdrid').removeClass('divbdr');
    $(".datahide").hide();    
   }

   public onInputChange(eve){
    console.log(eve);
    this.companyid="";
   }

   scroll(){
    if($(window).width() <= 767) {
    var ele = this.myScrollCartContainer.nativeElement; 
    ele.scrollIntoView(); 
    }
   }

   chartPrev() {
    if( this.BSEChartData.length >= 12 ){
      $('#ChartIconNext').css("cursor",'pointer');
    }
    else {
      $('#ChartIconPrev').css("cursor",'not-allowed');
      $('#ChartIconNext').css("cursor",'not-allowed');
    }

    if((this.ChartCurrentIndex-6) >= this.ChartStartIndex){ 
      let BSEmonth = [];
      let BSEmonthdata = []; 
      this.ChartEndIndex =  this.ChartCurrentIndex;
      this.ChartCurrentIndex =  this.ChartEndIndex-6; 
  
      
      for(let i= this.ChartCurrentIndex; i < this.ChartEndIndex; i++ ) {
        BSEmonth.push(this.BSEChartData[i].MonthValue);
        BSEmonthdata.push(this.BSEChartData[i].AvgClosePrice);
      } 
      this.ChartHead = this.BSEChartData[this.ChartCurrentIndex].MonthValue + " to " + this.BSEChartData[this.ChartEndIndex-1].MonthValue;
      this.lineChartLabels = BSEmonth;
      this.lineChartData = [{
        data: BSEmonthdata,
        label: 'Stock prices'
      }];

      if((this.ChartCurrentIndex-6) >= this.ChartStartIndex){ 
      $('#ChartIconPrev').css("cursor",'pointer');
      }
      else {
        $('#ChartIconPrev').css("cursor",'not-allowed');   
      }

    }
    else { 
      return false;
    }
    
   }

  chartNext() {

    if( this.BSEChartData.length >= 12 ){
      $('#ChartIconPrev').css("cursor",'pointer');
    }
    else {
      $('#ChartIconPrev').css("cursor",'not-allowed');
      $('#ChartIconNext').css("cursor",'not-allowed');
    }

    if (this.ChartEndIndex < this.BSEChartData.length) { 
      let BSEmonth = [];
      let BSEmonthdata = [];
      this.ChartCurrentIndex = this.ChartEndIndex;
      this.ChartEndIndex = this.ChartCurrentIndex + 6;
      for (let i = this.ChartCurrentIndex; i < this.ChartEndIndex; i++) {
        BSEmonth.push(this.BSEChartData[i].MonthValue);
        BSEmonthdata.push(this.BSEChartData[i].AvgClosePrice);
      }
      this.ChartHead = this.BSEChartData[this.ChartCurrentIndex].MonthValue + " to " + this.BSEChartData[this.ChartEndIndex-1].MonthValue;
      this.lineChartLabels = BSEmonth;
      this.lineChartData = [{
        data: BSEmonthdata,
        label: 'Stock prices'
      }];

      if (this.ChartEndIndex < this.BSEChartData.length) { 
        $('#ChartIconNext').css("cursor",'pointer');
      }
      else {
        $('#ChartIconNext').css("cursor",'not-allowed');
      }
    }
    else { 
      return false;
    }
  }

  Upgrade()
  {
    $('#myModaldash5').trigger('click');
    window.location.replace("/#/free-myaccount");
  }

  hidetr(dte) {
    
    if(dte=="A") {
      $(".A1").hide();
      $(".A2").hide(); 
      $(".A1A").hide(); 
      $(".A2A").hide(); 
      $(".SpanPlusA").show();
      $(".SpanMinusA").hide();

      $(".SpanPlusA1").show();
      $(".SpanMinusA1").hide();

      $(".SpanPlusA2").show();
      $(".SpanMinusA2").hide();
    }
    else if(dte=="B") {
      $(".B1").hide();
      $(".SpanPlusB").show();
      $(".SpanMinusB").hide();
    }
    else if(dte=="C") {
      $(".C1").hide();
      $(".SpanPlusC").show();
      $(".SpanMinusC").hide();
    }
    else if(dte=="A1") { 
      $(".A1A").hide(); 
      $(".SpanPlusA1").show();
      $(".SpanMinusA1").hide();
    }
    else if(dte=="A2") { 
      $(".A2A").hide(); 
      $(".SpanPlusA2").show();
      $(".SpanMinusA2").hide();
    }
   
  }

  showtr(dte) {
    
    if(dte=="A") {
      $(".A1").show();
      $(".A2").show(); 
      $(".SpanPlusA").hide();
      $(".SpanMinusA").show();
    }
    else if(dte=="B") {
      $(".B1").show();
      $(".SpanPlusB").hide();
      $(".SpanMinusB").show();
    }
    else if(dte=="C") {
      $(".C1").show();
      $(".SpanPlusC").hide();
      $(".SpanMinusC").show();
    }
    else if(dte=="A1") { 
      $(".A1A").show(); 
      $(".SpanPlusA1").hide();
      $(".SpanMinusA1").show();
    }
    else if(dte=="A2") { 
      $(".A2A").show(); 
      $(".SpanPlusA2").hide();
      $(".SpanMinusA2").show();
    }

  }

  balancesheethidetr(dte) {
  
    if(dte=="a") {
      $(".a1").hide();
      $(".SpanPlusa").show();
      $(".SpanMinusa").hide();

    }
    else if(dte=="d") {
      $(".d1").hide();
      $(".d2").hide();
      $(".d3").hide();
      $(".d4").hide();
      $(".SpanPlusd").show();
      $(".SpanMinusd").hide();
    }
    else if(dte=="f") {
      $(".f1").hide();
      $(".SpanPlusf").show();
      $(".SpanMinusf").hide();
    }
    else if(dte=="i") { 
      $(".i1").hide();
      $(".i2").hide();
      $(".i3").hide();
      $(".i4").hide(); 
      $(".SpanPlusi").show();
      $(".SpanMinusi").hide();
    }
    
   
  }

  balancesheetshowtr(dte) {
    
    if(dte=="a") {
      $(".a1").show();
      $(".SpanPlusa").hide();
      $(".SpanMinusa").show();
    }
    else if(dte=="d") {
      $(".d1").show();
      $(".d2").show();
      $(".d3").show();
      $(".d4").show();
      $(".SpanPlusd").hide();
      $(".SpanMinusd").show();
    }
    else if(dte=="f") {
      $(".f1").show();
      $(".SpanPlusf").hide();
      $(".SpanMinusf").show();
    }
    else if(dte=="i") { 
      $(".i1").show(); 
      $(".i2").show(); 
      $(".i3").show(); 
      $(".i4").show(); 
      $(".SpanPlusi").hide();
      $(".SpanMinusi").show();
    }
  }

  pnlhidetr(dte) {
  
    if(dte=="Z") {
      $(".Z1").hide();
      $(".SpanPlusZ").show();
      $(".SpanMinusZ").hide();

    }
    
  }

  pnlshowtr(dte) {
    
    if(dte=="Z") {
      $(".Z1").show();
      $(".SpanPlusZ").hide();
      $(".SpanMinusZ").show();
    }
  }
 
}  