<ng2-toasty [position]="'bottom-right'"></ng2-toasty>

<header id="header" class="freehdr">
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary authhdr bg-light">

    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
      <div class="navsplit">
        <div id="logo">
          <a href="#"><h1 _ngcontent-cfl-c124="">
            <span>Stock</span>Sherpa</h1>
            <p>Your Ai Stock Guide</p>
          </a>  
            
        </div>
       
      <a  class="lplogin"  (click)="logoff()" >LOGOUT</a>
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><i class="fa fa-navicon"></i></span>
            
          </button>
      </div>
    </div>
      
   
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto1">
        <li class="nav-item" >
          <a class="nav-link homemnu" [routerLink]="['/free-dashboard']">HOME</a>
        </li> 
        <li class="nav-item">
          <a class="nav-link commnu" [routerLink]="['/free-compare']">COMPARE</a>
        </li> 
        <!-- <li class="nav-item" style="cursor: pointer;">
          <a class="nav-link oktomnu" (click)="downloadoktomap()"><i class="fa fa-download" aria-hidden="true"></i> OKTOMAP </a>
        </li>  -->
        <li class="nav-item">
          <a class="nav-link" (click)="logoff()" style="padding: 0; cursor: pointer;"> LOGOUT 
          </a>
        </li>   
      </ul>
       
    </div>
  </nav>
  </header>



  <div class="container-fluid fhmenumar" >
    <div class="row" >
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 stockhead sheadbdr indusmnu" (click)="menuclk('byindustry')">
        <a href="javascript:void(0,0)" [routerLink]="['/free-byindustry']" >Multi-bagger Stocks<br>(By Industry)</a>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 stockhead sheadbdr mktmnu" (click)="menuclk('bymarket')">
        <a href="javascript:void(0,0)" [routerLink]="['/free-bymarket']">Multi-bagger Stocks <br>(By Market)</a>
      </div>
      <!-- <div class="col-4 col-sm-4 col-md-4 col-lg-4 stockhead topmnu" (click)="menuclk('top100')">
        <a href="javascript:void(0,0)" [routerLink]="['/free-toprank']">TOP 100 <br> Multi-bagger Stocks </a>
      </div> -->
    </div>
      <div class="row"  style="border-top: 1px solid #fff;">
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 userdtls myfolio">
          <a href="javascript:void(0,0)" class="tdec" [routerLink]="['/myportfolio']">My Portfolio</a>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 userdtls htdec">
          <a href="javascript:void(0,0)">Account type: <span style="text-transform: uppercase; color: #fff;">{{AccType}}</span></a>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-4 userdtls htdec myAcnt">
          <a href="javascript:void(0,0)" class="tdec" [routerLink]="['/free-myaccount']">My Account</a>
        </div>
      </div> 
  </div>
 
  
 
 