import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { EncrDecrService } from '../../_services/encrdecr.service';
import { Httpclient } from '../../_services/authorization.service'; 
declare var $: any;
@Component({
  selector: 'app-paidfooter',
  templateUrl: './paidfooter.component.html',
  styleUrls: ['./paidfooter.component.css']
})
export class PaidfooterComponent implements OnInit {
  logindetails: any;
  UserFullName: string="";
  Mobile: string="";
  Email: string="";
  subscription: string="";
  validity_from: string="";
  validity_to: string="";
  subscriptiontype: string="";
  UserId: string="";
  subscriptionid: string="";
  zoho_customerid: string="";
 
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private EncrDecr: EncrDecrService
     ) { 
    this.toastyConfig.theme = 'material';
  }
  ngOnInit(): void {
    //this.logindetails = JSON.parse(localStorage.getItem("user_details")); 
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 
    this.UserFullName = this.logindetails.first_name+" "+ this.logindetails.last_name;
    this.Mobile = this.logindetails.mobile_no;
    this.Email = this.logindetails.Email;
    this.subscription = this.logindetails.subscription_type;
    this.validity_from = this.logindetails.validity_from;
    this.validity_to = this.logindetails.validity_to;

    this.UserId = this.logindetails.UserId;
    this.subscriptionid = this.logindetails.zoho_subscriptionid;
    this.zoho_customerid = this.logindetails.zoho_customerid;

    $("#foo-bt-stock").click(function(){
      $("#ByIndustry").removeClass("tabActive");
      $("#ByMarket").removeClass("tabActive");
      $("#Top42").removeClass("tabActive");
    }); 
    $("#foo-bt-industry").click(function(){
      $("#ByIndustry").addClass("tabActive");
      $("#ByMarket").removeClass("tabActive");
      $("#Top42").removeClass("tabActive");
    }); 
    $("#foo-bt-market").click(function(){
      $("#ByMarket").addClass("tabActive");
      $("#ByIndustry").removeClass("tabActive");
      $("#Top42").removeClass("tabActive");
    });

  }

  Upgrade() {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.subscriptiontype == undefined || this.subscriptiontype.trim() == '') {
      toastOptions.msg += 'Subscription Type is required' + '</br>';
    }


    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        user_tableid: this.UserId,
        subscription_id: this.subscriptionid,
        subscriptiontype: this.subscriptiontype,
        customer_id: this.zoho_customerid,
      };
      debugger
      this.spinner.show();
      return this.http.post("userupgrade", user).toPromise().then(res => {
        debugger
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);

        if (parsedJson.code == "200") {
          window.location.href = parsedJson.data;
        }
        else if (parsedJson.Code == "400") {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
        }
        else {
          toastOptions.msg = "An error occurred while saving the record.";
          this.toastyService.error(toastOptions);
        }
        this.spinner.hide();
      }).catch(err => {
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        this.spinner.hide();
        return err;
      });
    }



  }
}
