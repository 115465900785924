import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-nd-terms-and-conditions',
  templateUrl: './nd-terms-and-conditions.component.html',
  styleUrls: ['./nd-terms-and-conditions.component.css']
})
export class NdTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    if($(window).width() >= 992){
      $('#nd-home').removeClass('active');
      $('#nd-abt').removeClass('active');
      $('#nd-faq').removeClass('active');
      $('#nd-cact').removeClass('active');

      }

  }

}
