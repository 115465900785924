<header id="nd-header">
    <nav class="navbar navbar-light navbar-expand-md navigation-clean fixed-top">
        <div class="container-fluid">
            <div id="nd-nav-split" style="padding: .5rem 1rem;"><a class="navbar-brand" href="#">
                    <h1 id="nd-nav-head">STOCK<span style="font-weight: 500;">Sherpa</span></h1>
                    <p id="nd-nav-p">Your&nbsp;Ai&nbsp;Stock&nbsp;Guide<br></p>
                </a><a id="nd-login-link" [routerLink]="['/login']" style="color: #2B2A29;text-decoration: none;">LOGIN</a><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1" style="border-style: none;margin-top: -7px;"><span class="navbar-toggler-icon"><i class="fa fa-navicon"></i></span></button></div>
            <div class="collapse navbar-collapse" id="navcol-1">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item nav-item-temp"><a id="nd-home" class="nav-link nav-link-temp" [routerLink]="['/']">HOME<br></a></li>
                    <li class="nav-item nav-item-temp"><a id="nd-abt" class="nav-link nav-link-temp" [routerLink]="['/about-us']">ABOUT US</a></li>
                    <li class="nav-item nav-item-temp"><a id="nd-faq" class="nav-link nav-link-temp" [routerLink]="['/faq']">FAQ<br></a></li>
                    <li class="nav-item nav-item-temp"><a id="nd-cact" class="nav-link nav-link-temp" [routerLink]="['/contact-us']">CONTACT<br></a></li>
                    <li class="nav-item nav-item-temp"><a id="nd-okto" class="nav-link nav-link-temp" [routerLink]="['/oktomap-download']">OKTOMAP<br></a></li>
                    <li class="nav-item nav-item-temp"><a class="nav-link" [routerLink]="['/login']">LOGIN<br></a></li>
                </ul>
            </div>
        </div>
    </nav>
</header>