import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastData, ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { environment } from 'src/environments/environment.prod';
import { Httpclient } from '../_services/authorization.service';
import { EncrDecrService } from '../_services/encrdecr.service';
const searchClient = algoliasearch(
  environment.applicationid,
  environment.apikey
);
declare var $: any;
@Component({
  selector: 'app-free-byindustry',
  templateUrl: './free-byindustry.component.html',
  styleUrls: ['./free-byindustry.component.css']
})
export class FreeByindustryComponent implements OnInit {
  logindetails: any; 
  UserId: string="";
  UserName: string = "";
  CompanytNme:string="";
  industryname: string="";
  dtlreportdata: any;
  industrydetails:any =[];
  NorecordfoundTeext:string ="";
  industryDetails: any; 
  TotalItems :any =[];
  
  portfoliodata:any;
  config = {
    indexName: environment.industryName,
    searchClient
  };
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private EncrDecr: EncrDecrService,
    private spinner: SpinnerVisibilityService) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    let company ={}
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 

    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    this.http.post("getfreeindustrydetails", company).toPromise().then(res => {
      
       const parseJson = JSON.stringify(res);
       const parsedJson = JSON.parse(parseJson);
       this.industrydetails = parsedJson.data;
    });
    this.portfoliodata =[];
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.fhmenumar .mktmnu').removeClass("activetab");
    $('.fhmenumar .topmnu').removeClass("activetab");
    $('.fhmenumar .myAcnt').removeClass("activemenu");    
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.fhmenumar .indusmnu').addClass("activetab"); 

    if($(window).width() >= 992) {
      $('.freedb').css('min-height',$(window).height()-189);
      }

  }

  public searchParameters = {
    query: '',
    id:''
  };

  public setQuery({ query,id }: { id:string, query: string }) {
    if(query !="")
    {
      this.searchParameters.query = query;
      this.searchParameters.id = id;
      console.log(this.searchParameters.query+" "+this.searchParameters.id);
  
      this.industryname=this.searchParameters.query;
      this.industryname = this.searchParameters.id;
     
    }
  
  }

   public onChange(eve) {
    console.log(eve);
    this.industryname="";
    $('#divbdrid').removeClass('divbdr');
    // $(".datahide").hide();    
   }

   public onInputChange(eve){
    console.log(eve);
    this.industryname="";
   }

   modelopen() {
     
     
    $("#myModaldash5").modal('show');
    $('.modal-backdrop').css('display','none');
  }

  Upgrade()
  {
    $('#myModaldash5').trigger('click');
    window.location.replace("/#/free-myaccount");
  }

  getindustrydetails(industryname)
  {
   this.industryDetails =[];
   
   const industry = {
     industryname:industryname,
     industrytype:''
   }
   this.industryname = industryname;
   this.spinner.show();
   return this.http.post("getfreeindustrydetails", industry).toPromise().then(res => {
    
     const parseJson = JSON.stringify(res);
     const parsedJson = JSON.parse(parseJson);
     
     this.industryDetails = parsedJson.data;

     this.industryDetails.forEach(element => {
       if(element.octo_color!="green-moon")
     {
       element.octo_color ="white-moon";
     }
     });
    
     this.TotalItems = this.industryDetails;
     if(this.TotalItems.length ==0)
     {
       this.NorecordfoundTeext ="No records found";
     }
     
     
     this.dtlreportdata =[];
     $('html, body').animate({
       scrollTop: $("#datas").offset().top -100
     });
     
        $('.moonshotid').removeClass('basedonactive');
        $('.excellentid').removeClass('basedonactive');
        $('.othersid').removeClass('basedonactive');
     this.spinner.hide();
   }).catch(err => {
     this.spinner.hide();
     return err;
   });


  }

  getindustrydetails1()
  {
    
    this.industryDetails =[];
    
    const industry = {
      industryname:this.industryname,
      industrytype:''
    }
    
    this.spinner.show();
    return this.http.post("getfreeindustrydetails", industry).toPromise().then(res => {
     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      
      this.industryDetails = parsedJson.data;

      this.industryDetails.forEach(element => {
        if(element.octo_color!="green-moon")
      {
        element.octo_color ="white-moon";
      }
      });
      
      this.TotalItems = this.industryDetails;
      if(this.TotalItems.length ==0)
      {
        this.NorecordfoundTeext ="No records found";
      }
     
      this.dtlreportdata =[];
      
      $('html, body').animate({
        scrollTop: $("#datas").offset().top-100
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });

  }
  addportfolio(stockId)
  {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    const stocks = {
      stockheaderid: stockId,
      userid: this.UserId
    }; 
    this.spinner.show();
    return this.http.post("freeaddportfolio", stocks).toPromise().then(res => { 
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      this.portfoliodata = parsedJson.data[0];
       if(this.portfoliodata.code == 200)
       {
        toastOptions.msg = "Added Successfully.";
        this.toastyService.success(toastOptions);
       }
       else
       {
        toastOptions.msg = this.portfoliodata.Msg;;
        this.toastyService.error(toastOptions);
       }
      this.spinner.hide();
    }).catch(err => {
      toastOptions.msg = "An error occurred while retrieving the record.";
      this.toastyService.error(toastOptions);
      this.spinner.hide(); 
      return err;
    });
  }
  expand(id){    
    
    $('#dtlreport'+id).show();
    $('#dtlrepcollapse'+id).show();
    $('#dtlrepexpand'+id).hide();
  }
  collapse(id){
    $('#dtlreport'+id).hide();
    $('#dtlrepexpand'+id).show();
    $('#dtlrepcollapse'+id).hide();
  }


}
