import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-nd-header',
  templateUrl: './nd-header.component.html',
  styleUrls: ['./nd-header.component.css']
})
export class NdHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $(window).scroll(function() {
      var sticky = $('#nd-header'),
        scroll = $(window).scrollTop();
       
      if (scroll >= 40) { 
        sticky.addClass('fixed'); }
      else { 
       sticky.removeClass('fixed');
    
    }
    });

 

    $(document).ready(function() {
      $('.nav-item-temp .nav-link').on('click', function(e){ 

        if(e.target.className=='nav-link nav-link-temp' || e.target.className=='nav-link nav-link-temp active') {
           
            e.preventDefault();
            if($(window).width() >= 992){
             
            }
            else { 
              var navMain = $("#navcol-1");
              navMain.collapse('hide');
              
            }
           
          }
          else {
            return false;
          }
      
      });
    });

    $('.navbar-toggler').on('click', function(){
     
      var canSee = $("#navcol-1").is(":visible");
      if(canSee){
        var navMain = $("#navcol-1");
        navMain.collapse('hide');
        return false;
      }
  }); 

  }

}
