import { Injectable } from "@angular/core";
import { Httpclient } from "./authorization.service"; 
import { Router } from "@angular/router"; 
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable()
export class CompanyService {  
  constructor(private http: Httpclient, private router: Router,private spinner: SpinnerVisibilityService) { }
  logindetails: any;
  Userdetails = {}
  UserId: string; 

  getCompanydetails(data) {    
    this.spinner.hide();
    return this.http.post('getcompanyname', data).toPromise().then(resp => {
        const parseJson =JSON.stringify(resp);
        const parsedJson = JSON.parse(parseJson);
      return parsedJson;

    });

  } 

  getPremiumCompanydetails(data) {    
    this.spinner.hide();
    return this.http.post('getpremiumcompanyname', data).toPromise().then(resp => {
        const parseJson =JSON.stringify(resp);
        const parsedJson = JSON.parse(parseJson);
      return parsedJson;

    });

  }
  
  getindustryname(data) {    
    this.spinner.hide();
    return this.http.post('getindustryname', data).toPromise().then(resp => {
        const parseJson =JSON.stringify(resp);
        const parsedJson = JSON.parse(parseJson);
      return parsedJson;

    });

  }
  getmarketdtls(data) {    
    this.spinner.hide();
    return this.http.post('getmarketdetails', data).toPromise().then(resp => {
        const parseJson =JSON.stringify(resp);
        const parsedJson = JSON.parse(parseJson);
      return parsedJson;

    });

  }
  
}