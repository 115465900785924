import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-nd-faq',
  templateUrl: './nd-faq.component.html',
  styleUrls: ['./nd-faq.component.css']
})
export class NdFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    if($(window).width() >= 992) {
      $('#nd-faq').addClass('active');
      $('#nd-okto').removeClass('active');
      $('#nd-home').removeClass('active');
      $('#nd-abt').removeClass('active');
      $('#nd-cact').removeClass('active');    

      }

$('#nd_faq_slider').owlCarousel({
  loop:true,
  autoplay:false,
  margin:10,
  dots:false,
  navText : ['<i class="fa fa-caret-down" aria-hidden="true"></i>','<i class="fa fa-caret-down" aria-hidden="true"></i>'],
  nav:true,
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
});

    $('#nd_faq_slider-1').owlCarousel({
      animateOut: 'slideOutDown',
      animateIn: 'slideInRight',
      loop: true,
      autoplay: false,
      responsive:{
        0:{
          items:1
        },
        600:{
          items:1
        },
        768:{
          items:2
        },
        992:{
          items:3
        }
      },
      slideBy: 1,
      nav: true,
      dots: false,
      navText: ["<i class='fa fa-caret-up' aria-hidden='true'></i>", "<i class='fa fa-caret-down' aria-hidden='true'></i>"],

    });
   
  }

}
