import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import { CompanyService } from '../_services/company.services';
import { EncrDecrService } from '../_services/encrdecr.service';
import { PagerService } from '../_services/Pager/index';
declare var $: any;

@Component({
  selector: 'app-paid-bymarket',
  templateUrl: './paid-bymarket.component.html',
  styleUrls: ['./paid-bymarket.component.css']
})
export class PaidBymarketComponent implements OnInit {
  marketDetails: any; 
  NorecordfoundTeext:string ="";
  pager: any = {}; 
  pagedItems: any[];
  logindetails: any; 
  portfoliodata:any;
  UserName: string = "";
  UserId: string="";
  marketName:string ="";
  TotalItems :any =[];
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private pagerService: PagerService,
    private CompanyService: CompanyService,
    private EncrDecr: EncrDecrService ) {
      this.toastyConfig.theme = 'material';
     }

  ngOnInit(): void {
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 
    this.UserName = this.logindetails.first_name;
    this.UserId = this.logindetails.UserId;
    $('.nav-item .homemnu').removeClass("active");
    $('.nav-item .commnu').removeClass("active");   
    $('.fhmenumar .indusmnu').removeClass("activetab");
    $('.fhmenumar .topmnu').removeClass("activetab");
    $('.fhmenumar .myAcnt').removeClass("activemenu");    
    $('.fhmenumar .myfolio').removeClass("activemenu"); 
    
    $('.fhmenumar .mktmnu').addClass("activetab"); 
  }

  getbymarketdetails(marketnamesaction,displayname)
  {
    this.marketName =displayname;
    this.marketDetails =[];
    this.pager = {};
    const market ={
      action:marketnamesaction,
      userid:1
    }
    this.spinner.show();
      return this.http.post("getmarketdetails", market).toPromise().then(res => {
       
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        
        this.marketDetails = parsedJson.data;

        this.marketDetails.forEach(element => {
          if(element.octo_color!="green-moon")
        {
          element.octo_color ="white-moon";
        }
        });
        
        this.TotalItems = this.marketDetails;
        if(this.TotalItems.length ==0)
        {
          this.NorecordfoundTeext ="No records found";
        }
        this.setPage(1);
        
        $('html, body').animate({
          scrollTop: $("#datas").offset().top -80
        });
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }

    // get pager object from service
    this.pager = this.pagerService.getPager(this.marketDetails.length, page);

    // get current page of items
    this.pagedItems = this.marketDetails.slice(this.pager.startIndex, this.pager.endIndex + 1);
    
}

addportfolio(stockId)
{
  var toastOptions: ToastOptions = {
    title: "Alert",
    showClose: true,
    timeout: 5000,
    theme: 'material',
    onAdd: (toast: ToastData) => {
    },
    onRemove: function (toast: ToastData) {
    }
  };
  toastOptions.msg = "";
  const stocks = {
    stockheaderid: stockId,
    userid: this.UserId
  }; 
  this.spinner.show();
  return this.http.post("paidaddportfolio", stocks).toPromise().then(res => { 
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    
    this.portfoliodata = parsedJson.data[0];
     if(this.portfoliodata.code == 200)
     {
      toastOptions.msg = "Added Successfully.";
      this.toastyService.success(toastOptions);
     }
     else
     {
      toastOptions.msg = this.portfoliodata.Msg;;
      this.toastyService.error(toastOptions);
     }
    this.spinner.hide();
  }).catch(err => {
    toastOptions.msg = "An error occurred while retrieving the record.";
    this.toastyService.error(toastOptions);
    this.spinner.hide(); 
    return err;
  });
}

expand(id){    
  $('#dtlreport'+id).show();
  $('#dtlrepcollapse'+id).show();
  $('#dtlrepexpand'+id).hide();
}
collapse(id){
  $('#dtlreport'+id).hide();
  $('#dtlrepexpand'+id).show();
  $('#dtlrepcollapse'+id).hide();
}

}
