<section>
    <div>
        <div class="loader"></div>
        <div class="loadertxt">
            <div style="font-size: 28px; text-align: center;color: green;padding:20px 0;">Thank You !</div>
            <div style="text-align: center;color: green;">
                <p style="font-size: 24px;word-spacing: 125%;line-height: 125%;">Your payment is received.</p>
                <p style="font-size: 20px;word-spacing: 125%;line-height: 125%;">Your purchase will be reflected in few
                    seconds.</p>
            </div>
        </div>
    </div>
</section>