<footer>
    <div id="nd-footer-mobile">
        <div class="row">
            <div id="nd-footer-logo" class="col-4"><img src="../../../../assets/images/last_page_pic.png">
                <div id="nd-foo-logo-txt">
                    <h1>STOCK<span>Sherpa</span></h1>
                    <p>Your Ai Stock Guide<br></p>
                </div>
            </div>
            <div class="col-8 nd-disclaimer-cnt">
                <h1>Disclaimer:</h1>
                <p>STOCKSherpa is not a wealth advisory, or a financial analyst or a stock broker. This is&nbsp;STOCKSherpa’s research innovation to digitize, demonetize, dematerialize and democratize financial ratios and value investing for retail investors to seek financial freedom on Indian Stock Market. Please do your own research before you invest.</p>
                <p class="nd-rpay-links"><a [routerLink]="['/TermsandConditions']" >Terms</a>&nbsp;||&nbsp;<a [routerLink]="['/PrivacyPolicy']" >Privacy</a>&nbsp; ||&nbsp;<a [routerLink]="['/CancellationPolicy']" >Cancellation Policy</a>&nbsp;</p>
                <p style="text-align: center;">All rights reserved@STOCKSherpa 2021</p>
            </div>
        </div>
    </div>

</footer>