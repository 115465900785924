<div class="contact-footer">

    <div class="container">
        <div class="row clearfix">
            <div class="col-lg-6 col-sm-6 col-6">
                <div class="d-block d-md-none">
                    <div class="footer-image-contact" style="text-align: center;">
                        <img style="border: 1px solid #000;border-radius:27px;width:85%;"
                            src="../../../assets/images/last_page_pic.png">
                    </div>
                    <div class="stocksherpa-reserved" style="text-align: center;">
                        <h2 style="border-bottom:1px solid #000;"><b>STOCK</b>Sherpa</h2>
                        <span style="color: #fff;font-size: 20px;">Your Ai Stock Guide</span>
                    </div>
                    <div style="position: absolute;bottom:0;left:10px;">
                        <p style="font-size: 11px;">All rights reserved@stocksherpa 2021</p>
                    </div>
                </div>
                <div class="d-none d-md-block dviewfooter">

                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-6">
                            <div class="footer-image-contact" style="text-align: center;">
                                <img style="border: 1px solid #000;border-radius:27px;"
                                    src="../../../assets/images/last_page_pic.png">
                            </div>
                            <div class="stocksherpa-reserved" style="text-align: center;">
                                <h2 style="border-bottom:1px solid #000;"><b>STOCK</b>Sherpa</h2>
                                <span style="color: #fff;font-size: 20px;">Your Ai Stock Guide</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6">
                            <p style="font-size: 14px;position:absolute; bottom:0px; margin-bottom: 0;">All rights reserved@stocksherpa 2021</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-6 pl-0">
                <div class="footer-right rpaylink">
                    <p><b>Disclaimer:</b><br><b>STOCK</b>Sherpa is an Ai guide to help you find Moonshot stocks. As a
                        guide STOCKSherpa provides you
                        the compass and the guide. However the journey towards financial freedom and choices must be
                        made by you. StockSherpa is not a stockbroker or wealth advisory or a financial analyst
                        or a screener or a stock ticker.</p>

                        

                        <a (click)="openNewTab('https://stocksherpa.in/#/TermsandConditions')" href="#" >
                            Terms 
                        </a> ||
                        <a (click)="openNewTab('https://stocksherpa.in/#/PrivacyPolicy')" href="#" >
                            &nbsp; Privacy</a> ||
                            <a (click)="openNewTab('https://stocksherpa.in/#/CancellationPolicy')" href="#" >
                                &nbsp; Cancellation Policy</a> 
                            
      
                </div>
            </div>
        </div>
    </div>
</div>
