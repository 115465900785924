<div class="container-fluid wel freecompare compare comn"> 

    <div class="row"> 
        <div class="col-12 col-sm-12 col-md-6 col-lg-5 offset-lg-1">

            <div class="row">
                <div class="col-10 col-sm-10 col-md-12 col-lg-12">
                    <div class="row" style="padding-top: 2px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="ais-InstantSearch">
                                <div class="ais-InstantSearch">
                                    <ais-instantsearch [config]="config">
                                        <div class="searchbox">
                                            <app-autocomplete (onQuerySuggestionClick)="setQuery1($event)"
                                                (onTextChange)="onChange1($event)" placeholderval="Enter First Stock Name here"
                                                (onClear)="onInputChange1($event)" [(ngInput)]="CompanytNme1" style="width:89%;">
                                            </app-autocomplete>
                                        </div>
                                    </ais-instantsearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                        </div>
                    </div>
            
                    <div class="row" style="padding-top: 3px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9 complus">
                            <i class="fa fa-plus"></i>
                        </div>
                    </div>
                    <!-- Search Box -2 -->
                    <div class="row" style="padding-top: 2px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="ais-InstantSearch">
                                <div class="ais-InstantSearch">
                                    <ais-instantsearch [config]="config">
                                        <div class="searchbox">
                                            <app-autocomplete (onQuerySuggestionClick)="setQuery2($event)"
                                                (onTextChange)="onChange2($event)" placeholderval="Enter Second Stock Name here"
                                                (onClear)="onInputChange2($event)" [(ngInput)]="CompanytNme2" style="width:89%;">
                                            </app-autocomplete>
                                        </div>
                                    </ais-instantsearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                        </div>
                    </div>
                    <div class="row" style="padding-top: 3px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9 complus">
                            <i class="fa fa-plus"></i>
                        </div>
                    </div>
            
                    <!-- Search Box -3 -->
                    <div class="row" style="padding-top: 2px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="ais-InstantSearch">
                                <div class="ais-InstantSearch">
                                    <ais-instantsearch [config]="config">
                                        <div class="searchbox">
                                            <app-autocomplete (onQuerySuggestionClick)="setQuery3($event)"
                                                (onTextChange)="onChange3($event)" placeholderval="Enter Third Stock Name here"
                                                (onClear)="onInputChange3($event)" [(ngInput)]="CompanytNme3" style="width:89%;">
                                            </app-autocomplete>
                                        </div>
                                    </ais-instantsearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                        </div>
                    </div>
                    <div class="row" style="padding-top: 3px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9 complus">
                            <i class="fa fa-plus"></i>
                        </div>
                    </div>
            
            
                    <!-- Search Box -4 -->
                    <div class="row" style="padding-top: 2px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="ais-InstantSearch">
                                <div class="ais-InstantSearch">
                                    <ais-instantsearch [config]="config">
                                        <div class="searchbox">
                                            <app-autocomplete (onQuerySuggestionClick)="setQuery4($event)"
                                                (onTextChange)="onChange4($event)" placeholderval="Enter Fourth Stock Name here"
                                                (onClear)="onInputChange4($event)" [(ngInput)]="CompanytNme4" style="width:89%;">
                                            </app-autocomplete>
                                        </div>
                                    </ais-instantsearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                        </div>
                    </div>
                    <div class="row" style="padding-top: 3px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9 complus">
                            <i class="fa fa-plus"></i>
                        </div>
                    </div>
            
            
            
            
                    <!-- Search Box -5 -->
                    <div class="row mrb15" style="padding-top: 2px;">
                        <div class="col-9 col-sm-9 col-md-9 col-lg-9">
                            <div class="ais-InstantSearch">
                                <ais-instantsearch [config]="config">
                                    <div class="searchbox">
                                        <app-autocomplete (onQuerySuggestionClick)="setQuery5($event)"
                                            (onTextChange)="onChange5($event)" placeholderval="Enter Fifth Stock Name here"
                                            (onClear)="onInputChange5($event)" [(ngInput)]="CompanytNme5"></app-autocomplete>
                                    </div>
                                </ais-instantsearch>
                            </div>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 justify-content-center" style="padding: 0;z-index: 1029;"
                            (click)="Compare()">
                            <div class="btns"><a class="click_btn" style="height:36px !important;"><span
                                        style="font-weight: bold;font-size: 18px;">GO!</span></a></div>
                        </div>
                    </div>
            
            
            
            
            
                </div>
            </div>

            <div class="row mrb15">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="sanalysis">
                        <p class="text-left sahead">COMPARISON ANALYSIS</p>
                        <div class="sacontent">             
                           <p>YOUR COMPARISON OF SELECTED STOCKS IS VIEWABLE IN TWO WAYS -</p>
                           <p>THE GLANCE VIEW ALLOWS YOU AN OVERALL VIEWING OF FINAL SCORES 
                               THE DETAILED VIEW GIVES YOU A BETTER UNDERSTANDING OF THE SCORES ALONG WITH THE STOCKSHERPA ANALYSIS OF EACH STOCK!</p>
                        </div>
                       </div>
                </div>
            </div> 

            
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-5" id="divbdrid1">
     
            <div class="datahide" style="display: none;">
                <div class="row">
                    <div class="col-12" style="padding: 5px;">
                        <div class="gviewtxt">
                            <p>GLANCE VIEW OF COMPARISION</p>
                        </div>
                    </div>
                </div>
            
                
            
                <div class="compareres">
                    <div class="row">
                        <div class="col-12 compadd">
                            <div class="row">
                                <div class="col-3">
                                    <div class="cmpsthead">
                                        <p>STOCK NAME</p>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="cmpsrdetail">
                                        <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 2px;">
                                                <div class="cmpsrdetailnumblk">
                                                    <p class="cmpsrdetailnum">9</p>
                                                </div>
                                                <div class="cmpsrdetailtxtblk">
                                                    <p class="cmpsrdetailtxt">Fundamental<br />Ratios </p>
                                                </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-2">
                                    <div class="cmpsrdetail">
                                        <div class="cmpsrdetailblk" style="text-align: center;padding-left: 5px;">
                                                <div class="cmpsrdetailnumblk">
                                                    <p class="cmpsrdetailnum">4</p>
                                                </div>
                                                <div class="cmpsrdetailtxtblk">
                                                    <p class="cmpsrdetailtxt">Valuation<br />Ratios </p>
                                                </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-2">
                                    <div class="cmpsrdetail">
                                        <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 5px;">
                                                <div class="cmpsrdetailnumblk">
                                                    <p class="cmpsrdetailnum">8</p>
                                                </div>
                                                <div class="cmpsrdetailtxtblk">
                                                    <p class="cmpsrdetailtxt">Opportunity<br />Ratios </p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="cmpoktohead">
                                        <p>STOCKSherpa <br>Score</p>
                                    </div>
                                </div>
                                <div class="col-1">
                                    
                                </div>
                            </div>
                        </div>
                    </div> 
            
                    <div class="forloop" *ngFor="let stock of compareDetails;">
                        <div class="row mrb15">
                            <div class="col-12 compadd">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="cmpstname">
                                            <p class="stockelipse">{{stock.company_name}}</p>
                                        </div>
                
                                    </div>
                                    <div class="col-2">
                                        <div class="cmpstval">
                                            <p>{{stock.fundamental_ratios}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="cmpstval">
                                            <p>{{stock.valuation_ratios}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="cmpstval">
                                            <p>{{stock.moonshot_ratios}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="cmpstoktoval">
                                            <p>{{stock.okto_score}}</p>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="cmpstexpand">
                                            <i class="fa fa-plus" id="dtlrepexpand{{stock.id}}" (click)="expand(stock.id)"></i>
                                            <br />
                                            <i class="fa fa-minus" style="display: none;" (click)="collapse(stock.id)" id="dtlrepcollapse{{stock.id}}"></i>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div id="dtlreport{{stock.id}}" style="display: none;margin-top:15px;">
            
                         
            
                            <div>
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <div class="srhead">
                                                    <div class="srheadblk">
                                                        <p>21 STOCK RATIOS </p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-3"  style="padding-right:0;">
                                                <div class="srhead">
                                                    <div class="srheadblk">
                                                        <p>Ai SCORE </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="srhead">
                                                    <div class="srheadblk">
                                                        <p>Ai ANALYSIS </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                    </div>
                                </div>
                            
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3" style="padding-right: 0;">
                                                <div class="srdetail">
                                                    <div class="srdetailblk">
                                                            <div class="srdetailnumblk">
                                                                <p class="srdetailnum">9</p>
                                                            </div>
                                                            <div class="srdetailtxtblk">
                                                                <p class="srdetailtxt">Fundamental<br />Ratios </p>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3" style="padding-right: 0;">
                                                <div class="aidetail">
                                                    <div class="aidetailblk" [ngClass]="[stock.fr_color]">
                                                        <p > {{stock.fundamental_ratios}} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="padding-left: 0;">
                                                <div class="aadetail">
                                                    <div class="aadetailblk">
                                                        <p>{{stock.fr_aianalysis}} SCORE</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <div class="srdetail">
                                                    <div class="srdetailblk">
                                                            <div class="srdetailnumblk">
                                                                <p class="srdetailnum">4</p>
                                                            </div>
                                                            <div class="srdetailtxtblk">
                                                                <p class="srdetailtxt">Valuation<br />Ratios </p>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3" style="padding-right: 0;">
                                                <div class="aidetail">
                                                    <div class="aidetailblk" [ngClass]="[stock.vr_color]">
                                                        <p > {{stock.valuation_ratios}} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="padding-left: 0;">
                                                <div class="aadetail">
                                                    <div class="aadetailblk">
                                                        <p>{{stock.vr_aianalysis}} PRICE</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <div class="srdetail">
                                                    <div class="srdetailblk">
                                                            <div class="srdetailnumblk">
                                                                <p class="srdetailnum">8</p>
                                                            </div>
                                                            <div class="srdetailtxtblk">
                                                                <p class="srdetailtxt">Opportunity<br />Ratios </p>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3" style="padding-right:0;">
                                                <div class="aidetail" >
                                                    <div class="aidetailblk"  [ngClass]="[stock.ms_color]">
                                                        <p> {{stock.moonshot_ratios}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="padding-left: 0;">
                                                <div class="aadetail">
                                                    <div class="aadetailblk">
                                                        <p>{{stock.ms_aianalysis}} SCORE</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-3" style="padding-right:0;">
                                                <div class="oktodetail">
                                                    <div class="oktodetailblk">
                                                            <p>STOCKSherpa <br> Score</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3" style="padding-right: 0;">
                                                <div class="oktoaidetail">
                                                    <div class="oktoaidetailblk" [ngClass]="[stock.octo_color]">
                                                        <p> {{stock.okto_score}} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="padding-left: 0;">
                                                <div class="oktoaadetail">
                                                    <div class="oktoaadetailblk">
                                                        <p >{{stock.okto_score_aianalysis}} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="sanalysis">
                                        <p class="text-left sahead">STOCKSHERPA ANALYSIS</p>
                                        <div class="sacontent">             
                                            <p [innerHtml] ="stock.ss_analysis_text"></p>
                                        </div>
                                       </div>
                                </div>
                            </div>   
                            <div class="row justify-content-center portfolioalign">
                                <div class="col-8 portfoliotxt" (click)="addportfolio(stock.id)">
                                    <a  style="font-size: 12px;"> <span>Add to Portfolio</span>&nbsp;<i
                                            class="fa fa-plus" style="color: #f58220;"></i></a>
                                </div>
                            </div> 
                            <div class="row">
       
                                <div class="col-12" id="divbdrid">
                                    <div class="datahide">
                                        <div class="dtlreport">
                                            <div class="row dareport"  #scrollMe1>
                                                <div class="col-12">
                                                    <p class="headstyle">
                                                        <span>Detailed Analysis Report - <span>({{stock.yesterday_date}})</span></span>
                                                    </p>
                                                </div>
                            
                                            </div>
                            
                                            <div class="row dtlstockdtls">
                                                <div class="col-12 col-sm-12 col-md-10 col-lg-8 offset-md-1  offset-lg-2">
                                                    <div class="row">
                                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-right: 0;">
                                                            <p>Stock Name</p>
                                                            <p>Industry</p>
                            
                                                        </div>
                                                        <div class="col-8 col-sm-8 col-md-8 col-lg-8 bdrl" style="padding-left: 3px;">
                                                            <p>{{stock.company_name}}</p>
                                                            <p>{{stock.industry}}</p>
                            
                                                        </div>
                                                    </div>
                            
                                                </div>
                                            </div>
                        
                                            <div id="aianalysis1"  style="padding: 0 15px;">
                                               
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="aihead">
                                                                        <p>AI ANALYSIS</p>
                                                                    </div>
                                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-9">
                                                                    <p class="headstyle">Fundamental Ratios -&nbsp;<span>
                                                                            {{stock.fr_aianalysis}} SCORE</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>EPS</p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.eps}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>ROCE<br /><span>3 years</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.roce}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>ROE<br /><span>3 years</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.roe}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PROFIT GR<br /><span>3 years</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.profit_gr}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>OPM</p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.opm}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>SALES<br /><span>GR</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.sales_growth_3years}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>DEBT TO<br /><span>EQUITY</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.dept_to_equity}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>CURRENT<br /><span>RATIO</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.current_ratio}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-9">
                                                                    <p class="headstyle">Valuation Ratios -&nbsp;<span>
                                                                            {{stock.vr_aianalysis}} PRICE</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PRICE<br /><span>TO EARNING</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.price_to_earning}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PRICE<br /><span>TO SALES</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.price_to_sales}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PRICE TO<br /><span>BK VALUE</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.price_to_bk_value}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PRICE TO<br /><span>FCF</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.price_to_fcf}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                            
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-9">
                                                                    <p class="headstyle">Opportunity Ratios -&nbsp;<span>
                                                                            {{stock.ms_aianalysis}} SCORE</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>INTRINSIC<br /><span>VALUE</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.intrinsic_value}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PEG</p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.peg}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>FCF TO<br /><span>SALES</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.fcf_to_sales}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>CROIC</p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.croic}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mrb15">
                                                        <div class="col-12">
                                                            <div class="row rmpadding">
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PIOTROSKI<br /><span>SCORE</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.piotroski_score}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>ALTMAN Z<br /><span>SCORE</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.altman_z_score}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>FOUNDER<br /><span>STAKE</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>{{stock.founder_stake}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="aiblock">
                                                                        <div class="aitxt">
                                                                            <p>PLEDGED<br /><span>STOCKS</span></p>
                                                                        </div>
                                                                        <div class="aivalue">
                                                                            <p>
                                                                                {{stock.pledged_stocks}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                    <div class="row mrb15 justify-content-center rmpadding">
                                                        <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                                            <div class="oktoblock">
                                                                <div class="oktotxt">
                                                                    <p>STOCKSherpa <br> <span>Score</span></p>
                                                                </div>
                                                                <div class="oktovalue">
                                                                    <p>{{stock.okto_score}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                            </div>
                            
                            
                            
                            
                                           
                            
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </div> 
                        
                    </div>
                  
                </div>
            </div>
         
    </div>



    </div>      


 
    
   
 
 



   

 
    <br />
    
    
    
    
    <br />    
<!-- 
    <img src="../../assets/images/stars1.png" alt="" class="stars1">
    <img src="../../assets/images/moon.png" alt="" class="moon1">
    <img src="../../assets/images/stars2.png" alt="" class="stars2"> -->
    <div class="moonstar">
        <img src="../../assets/images/moon-stars.png" alt="" class="stars2">
    </div>
</div>
