<div id="nd-about-us">
    <div class="container-fluid">
        <div id="nd-hme-mobile">
            <h1 class="nd-about-head"><span>ABOUT</span>Us</h1>
            <p class="nd-about-dtls">STOCKSherpa was conceptualized by a small passionate team of entrepreneurs, who have for long been 'Sherpas' guiding their relevant industries towards excellence. <br>The team includes a Phd in artificial intelligence, an ethical hacker and a serial entrepreneur working on exponential technologies, all bonded by their non-conformist attitudes and zeal to breach barriers!</p>
            <div class="nd-vission-div">
                <h3>Vision<br></h3>
                <p>To empower a million to achieve financial freedom on the Indian stock market with sophisticated Ai stock guide, the STOCKSherpa!</p>
            </div>
        </div>
        <div id="nd-hme-desktop">
            <h1 class="nd-about-head-dview"><span>ABOUT</span>&nbsp;Us</h1>
            <div class="row">
                <div class="col-7">
                    <p class="nd-about-dtls" style="text-align: left;padding: 0 75px;">STOCKSherpa was conceptualized by a small passionate team of entrepreneurs, who have for long been 'Sherpas' guiding their relevant industries towards excellence. <br><br>The team includes a Phd in artificial intelligence, an ethical hacker and a serial entrepreneur working on exponential technologies, all bonded by their non-conformist attitudes and zeal to breach barriers!</p>
                </div>
                <div class="col-5 nd-abt-img"><img src="../../../assets/images/unnamed.png"></div>
            </div>
            <div class="nd-vission-div">
                <h3>Vision<br></h3>
                <p>To empower a million to achieve financial freedom on the Indian stock market<br>&nbsp;with sophisticated Ai stock guide, the STOCKSherpa!</p>
            </div>
        </div>
    </div>
</div>