import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { observable } from "rxjs/symbol/observable";
import { retry } from "rxjs/operators/retry";
import { EncrDecrService } from '../_services/encrdecr.service';

@Injectable()
export class AuthGuard implements CanActivate {
  logindetails: any;
  constructor(private router: Router,private EncrDecr: EncrDecrService ) {
  }
  
  isAuthenticated(): boolean {
      if (localStorage.getItem("_uid")) {
        return true;
      } else {
        return false;
      }
    }

  isPaidUser(Url) {
      var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
      this.logindetails = JSON.parse(decrypted); 
       
      if(this.logindetails.user_type=="Paid") {
      if(Url=="/premium-dashboard" || Url=="/premium-compare" || Url=="/premium-portfolio" 
      || Url=="/by-stocks" || Url=="/premiumby-industry" || Url=="/premiumby-market" || Url=="/premiumtop-rank"
      || Url=="/premium-myaccount" || Url=="/premium-myportfolio" ) {
        return true;
      }
      else { 
        return false;
      }
      }
      else {
        
        if(Url=="/free-dashboard" || Url=="/free-compare" || Url=="/portfolio" || Url =="/free-toprank"
      || Url =="/free-byindustry" || Url =="/free-bymarket" || Url =="/free-myaccount" || Url=="/myportfolio") {
          return true;
        }
        else {
          return false;
        }
      }

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      console.log(state.url);
      if (!this.isAuthenticated()) { 
        this.router.navigate(["login"]);
        return false;
      }
      else {

        if (!this.isPaidUser(state.url)) { 
          this.router.navigate(["404"]);
          return false;
        }
        else {
          return true;
        }
       
      }
    
  }

}
