<ng2-toasty [position]="'bottom-right'"></ng2-toasty>
<div class="container-fluid" id="pglogin">
  <div class="row d-none d-sm-block">
      <a><img src="../../../assets/images/01-full.png" class="img-responsive"></a>
  </div>
  <div class="row d-block d-sm-none">
      <a><img src="../../../assets/images/logo.png" class="img-responsive"></a>
  </div> 
 
  <div class="login-home">
    <button class="navbar-toggler" type="button" (click)="redirectToExternalUrl()"  style="padding: .25rem 0.70rem" >
      <span class="login" style="padding: 0 20px 0 0; cursor: pointer;font-size: 1rem;" >HOME</span> <span class="navbar-toggler-icon" style="width: auto; height: auto;"> <i style="color: #fff;font-size: 40px;" class="fa fa-home"></i></span>
      </button>
  </div>
 
  <div class="row logindtls">
    <div class="col-12 col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="login-form">
        <div class="form-section">
          <div style="text-align: center; margin-top: -25px;">
            <h1 style="display: inline-block; font-size: 48px; padding-bottom: 0; margin-bottom: 0;"><span>Stock</span>Sherpa</h1>
            </div>
            <h2 style="margin:10px 0 0 0;font-size: 28px;">Your Ai Stock Guide</h2>
            <!-- <form>
            <h3 style="margin: 4px 0 16px 0;text-align: left;">I am an existing user</h3>
              <input type="text" class="form-control" name="username" placeholder="Email Address" [(ngModel)]="username">
              <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="password"> 
              <div style="text-align: right;margin-bottom: 15px;margin-top: 11px;">
                <a style="cursor: pointer;" class="tdu btn-fpswd"  (click)="ForgotPassword()"  >Forgot Password?</a>
              </div>
              <input type="submit" name="login" style="cursor: pointer;" value="login"  (click)="Login()"  class="click_btn add">			 
            </form> 
            <a style="margin-top: 0; padding-top: 10px;" href="" class="login-btn" data-toggle="modal" data-target="#myModaln1">Sign up</a> -->
        </div>
    </div>
    </div>
  </div>  

  <br />
</div>




 <div id="myModaln1" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln1" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
    <div class="modal-dialog">
     
      <div class="modal-content">
       
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
             <!-- <p>Sign me up to be expertly guided on the<br> slippery slopes of the stock market by<br> STOCKSherpa! Your privacy is our priority</p> -->
             
             <p>Your privacy is our priority. The data is encrypted and never shared</p>
             
             <div class="row">
                <!-- <div class="col-lg-4 col-sm-4 col-4" style="padding-right: 0;">
                  <select class="form-control" name="title" [(ngModel)]="title" style="font-size: 14px; padding: .375em 14px;">
                    <option value="">Title</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                  </select>
                </div> -->
                <div class="col-lg-12 col-sm-12 col-12">
  
                  <input type="text" class="form-control" name="firstname" placeholder="Name"  [(ngModel)]="firstname">

                </div>
              </div>
            <!-- <input type="text" class="form-control" name="lastname" placeholder="Last Name"  [(ngModel)]="lastname"> -->
            <input type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="E-mail">
            <input type="number" class="form-control" name="mobile" placeholder="Mobile No."  [(ngModel)]="mobile" onKeyPress="if(this.value.length==10) return false;">
           
            <div class="row" style="margin-bottom: 13px;">
              <div class="col-lg-12 col-sm-12 col-12">
                <p style="text-align: left;margin: 0; font-size: 18px;">Subscription Type :</p>
              </div>
              <div class="col-lg-4 col-sm-4 col-4" style="text-align: left;">
            
                <input type="radio" id="freesst" name="subscriptiontype" value="Free" [(ngModel)]="subscriptiontype">
                <span for="age1">&nbsp;Free</span>
              </div>
              <div class="col-lg-4 col-sm-4 col-4" style="text-align: left; padding-right: 0;">
                <input type="radio" id="monthlysst" name="subscriptiontype" value="Monthly" [(ngModel)]="subscriptiontype">
                <span for="age2" style="color: #fff;">&nbsp;Monthly <br/>&nbsp;&nbsp;&nbsp;&nbsp;Rs.201/-*</span>
              </div>
              <div class="col-lg-4 col-sm-4 col-4" style="text-align: left;padding-right: 0;">
                <input type="radio" id="yearlysst" name="subscriptiontype" value="Yearly" [(ngModel)]="subscriptiontype">
                <span for="age3" style="color: #fff;">&nbsp;Yearly <br/>&nbsp;&nbsp;&nbsp;&nbsp;Rs.501/-*<br/> 
                  
                </span>
                 
              </div>
            </div>        
            <label class="text-left cnt"><input type="checkbox" name="chkrobot" class="checkbox" [(ngModel)]="chkrobot"> I am not a robot! <span class="checkmark"></span></label>
            <div class="sbtn"><input type="submit" name="login" (click)="Register()" class="click_btn confirm add">			 </div>
          
             <!-- <p>StockSherpa looks forward to guiding<br> you towards moonshot stocks using its<br> proprietary sophisticated Ai that expertly<br> navigates the treacherous slopes of the<br> stock market with a 21 ratio algorithm!</p> -->
             <p>Enjoy the hike! <a [routerLink]="['/']" data-dismiss="modal">Continue to site</a></p>
        </div>
      </div>
      </div>
  </div> 
<!-- 
  <div id="myModaln1" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln1" aria-hidden="true">
    <div class="modal-dialog">
      
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
         <div style="text-align: center;padding: 30px 0;">
          <span style="font-size: 16px;font-weight: bold; color: #fff;">
            Global launch scheduled for 1st September 21.<br> Registeration begins at 8 pm IST.
           </span>
         </div>
      
        </div>
      </div>
      </div>
  </div>  -->


 
  <div id="myModaln2" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln2" aria-hidden="true">
    <div class="modal-dialog">
      
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
         <div style="text-align: center;padding: 30px 0;">
          <span style="font-size: 16px;font-weight: bold; color: #fff;">
            Registration Successful. Activation link is sent to your email
           </span>
         </div>
      
        </div>
      </div>
      </div>
  </div>  