import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import { Httpclient } from './_services/authorization.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthfooterComponent } from './layout/authfooter/authfooter.component'; 
import { AuthheaderComponent } from './layout/authheader/authheader.component';
import { AuthlayoutComponent } from './layout/authlayout/authlayout.component';
import { LpComponent } from './lp/lp.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {ToastyModule} from 'ng2-toasty';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { FreelayoutComponent } from './layout/freelayout/freelayout.component';
import { FreeheaderComponent } from './layout/freeheader/freeheader.component';
import { FreefooterComponent } from './layout/freefooter/freefooter.component'; 
import { AuthGuard } from './_guards/auth.guard'; 
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CompanyService } from './_services/company.services'; 
import { PaymentsuccessComponent } from './paymentsuccess/paymentsuccess.component';
import { PagerService } from './_services/Pager/index'; 
import {EncrDecrService} from './_services/encrdecr.service';
import { Error404Component } from './error404/error404.component';
import { UserpasswordComponent } from './userpassword/userpassword.component'; 
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NgAisModule } from 'angular-instantsearch';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteComponent } from './components/autocomplete';
import { AutocompleteComponent as  industry_autocomplete } from './components/industry_autocomplete';
import { FreeDashboardComponent } from './free-dashboard/free-dashboard.component';
import { FreeCompareComponent } from './free-compare/free-compare.component';
import { FreeByindustryComponent } from './free-byindustry/free-byindustry.component';
import { FreeBymarketComponent } from './free-bymarket/free-bymarket.component';
import { FreeTop100Component } from './free-top100/free-top100.component';
import { PaidTop100Component } from './paid-top100/paid-top100.component';
import { PaidBymarketComponent } from './paid-bymarket/paid-bymarket.component';
import { PaidByindustryComponent } from './paid-byindustry/paid-byindustry.component';
import { PaidDashboardComponent } from './paid-dashboard/paid-dashboard.component';
import { PaidCompareComponent } from './paid-compare/paid-compare.component';
import { PaidlayoutComponent } from './layout/paidlayout/paidlayout.component';
import { PaidheaderComponent } from './layout/paidheader/paidheader.component';
import { PaidfooterComponent } from './layout/paidfooter/paidfooter.component';
import { ChartsModule } from 'ng2-charts';
import { FreeMyaccountComponent } from './free-myaccount/free-myaccount.component';
import { PaidMyaccountComponent } from './paid-myaccount/paid-myaccount.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsandConditionsComponent } from './termsand-conditions/termsand-conditions.component';
import { CancellationpolicyComponent } from './cancellationpolicy/cancellationpolicy.component';

import { NdLpComponent } from './nd-new-design/nd-lp/nd-lp.component';
import { NdHeaderComponent } from './nd-new-design/nd-layout/nd-header/nd-header.component';
import { NdFooterComponent } from './nd-new-design/nd-layout/nd-footer/nd-footer.component';
import { NdLayoutComponent } from './nd-new-design/nd-layout/nd-layout/nd-layout.component';
import { NdAboutusComponent } from './nd-new-design/nd-aboutus/nd-aboutus.component';
import { NdFaqComponent } from './nd-new-design/nd-faq/nd-faq.component';
import { NdContactusComponent } from './nd-new-design/nd-contactus/nd-contactus.component';
import { NdTermsAndConditionsComponent } from './nd-new-design/nd-terms-and-conditions/nd-terms-and-conditions.component';
import { NdPrivacyPolicyComponent } from './nd-new-design/nd-privacy-policy/nd-privacy-policy.component';
import { NdCancellationPolicyComponent } from './nd-new-design/nd-cancellation-policy/nd-cancellation-policy.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTableModule} from '@angular/material/table';
import { NdDownloadoktomapComponent } from './nd-new-design/nd-downloadoktomap/nd-downloadoktomap.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SignupComponent } from './signup/signup.component';


@NgModule({
  declarations: [
    AppComponent, 
    AuthheaderComponent,
    AuthfooterComponent,
    AuthlayoutComponent,
    LpComponent,
    LoginComponent,
    FreelayoutComponent,
    FreeheaderComponent,
    FreefooterComponent,    
    PaidlayoutComponent,
    PaidheaderComponent,
    PaidfooterComponent, 
    PaymentsuccessComponent, 
    Error404Component,
    UserpasswordComponent, 
    ForgotpasswordComponent,
    AutocompleteComponent,
    industry_autocomplete,
    FreeDashboardComponent,
    FreeCompareComponent,
    FreeByindustryComponent,
    FreeBymarketComponent,
    FreeTop100Component,
    PaidTop100Component,
    PaidBymarketComponent,
    PaidByindustryComponent,
    PaidDashboardComponent,
    PaidCompareComponent,
    FreeMyaccountComponent,
    PaidMyaccountComponent,
    PortfolioComponent,
    PrivacyPolicyComponent,
    TermsandConditionsComponent,
    CancellationpolicyComponent,
    NdLpComponent,
    NdHeaderComponent,
    NdFooterComponent,
    NdLayoutComponent,
    NdAboutusComponent,
    NdFaqComponent,
    NdContactusComponent,
    NdTermsAndConditionsComponent,
    NdPrivacyPolicyComponent,
    NdCancellationPolicyComponent,
    NdDownloadoktomapComponent,
    SignupComponent   
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastyModule.forRoot(),    
    NgHttpLoaderModule.forRoot(), 
    AutocompleteLibModule,
    NgAisModule,
    MatAutocompleteModule,
    ChartsModule,
    FileUploadModule,
    MatButtonToggleModule,
    MatTableModule

  ],
  providers: [AuthGuard,Httpclient,SpinnerVisibilityService,CompanyService,PagerService,EncrDecrService],
  bootstrap: [AppComponent],
  exports:[FormsModule,ToastyModule]
})
export class AppModule { }
