<ng2-toasty [position]="'center-center'"></ng2-toasty>
<div class="container-fluid" id="pglogin">
  <div class="row d-none d-sm-block">
    <a><img src="../../../assets/images/01-full.png" class="img-responsive"></a>
  </div>
  <div class="row d-block d-sm-none">
    <a><img src="../../../assets/images/logo.png" class="img-responsive"></a>
  </div>

  <div class="login-home">
    <button class="navbar-toggler" type="button" [routerLink]="['/']" style="padding: .25rem 0.70rem">
      <span class="login" style="padding: 0 20px 0 0; cursor: pointer;font-size: 1rem;">HOME</span> <span
        class="navbar-toggler-icon" style="width: auto; height: auto;"> <i style="color: #fff;font-size: 40px;"
          class="fa fa-home"></i></span>
    </button>
  </div>

  <div class="row logindtls">
    <div class="col-12 col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="login-form">
        <div class="form-section">
          <h1><span>Stock</span>Sherpa</h1>
          <h2>Your Ai Stock Guide</h2>
          <form>
            <p style="text-align: center;font-size: 21px; padding-bottom: 10px;">Reset Password</p>
            <p style="text-align: left;">Email: <b>{{PageLoadEmail}}</b> </p>
            <input type="password" class="form-control" name="password" placeholder="New Password"
              [(ngModel)]="password">
            <input type="password" class="form-control" name="confirmpassword" 
              placeholder="Confirm Password" [(ngModel)]="confirmpassword">
            <input type="submit" style="cursor: pointer;" name="submit" value="Reset Password" (click)="reset()" class="click_btn add">
          </form>

        </div>
      </div>
    </div>
  </div>
  <br />
</div>