import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paidlayout',
  templateUrl: './paidlayout.component.html',
  styleUrls: ['./paidlayout.component.css']
})
export class PaidlayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
