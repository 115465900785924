<div class="container-fluid" id="pglogin">
    <div class="row d-none d-sm-block">
        <a href="./"><img src="../../../assets/images/01-full.png" class="img-responsive"></a>
    </div>
    <div class="row d-block d-sm-none">
        <a href="./"><img src="../../../assets/images/logo.png" class="img-responsive"></a>
    </div> 
   
    <div class="login-home">
      <button class="navbar-toggler" type="button" [routerLink]="['/']" style="padding: .25rem 0.70rem" >
        <span class="login" style="padding: 0 20px 0 0; cursor: pointer;font-size: 1rem;" >HOME</span> <span class="navbar-toggler-icon" style="width: auto; height: auto;"> <i style="color: #fff;font-size: 40px;" class="fa fa-home"></i></span>
        </button>
    </div>
   
    <div class="row logindtls rpaypage">
        <div class="col-12 col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4" style="text-align: center;">
        <h2><b>Cancellation Policy</b></h2>
       </div>  
    </div>  
  
  <div class="row">   
    <div class="col-12" >
        <div style="padding: 0 5%;"> 
       <br>
            <p class="sub">Last updated Auguest 31, 2021</p>

<p>All STOCKsherpa subscription channel plans include a <b>3-day Cancellation policy.</b></p>

<p><b>After 3 days, no refunds will be issued.</b> Free trials does not include any premium contents 
    during or after the initial plan creation.</p> 

<p>Furthermore, STOCKsherpa does not offer prorated refunds for cancelled subscriptions. 
    All subscriptions are recurring and will automatically renew after the end of each paid subscription period. 
    This includes both month-to-month and annual plans. </p>


<p>Similarly, it is subscriber’s responsibility to evaluate STOCKsherpa, including its features, 
    limitations, and system requirements before selecting any Paid Plan. 
    <b>STOCKsherpa will not issue refunds </b>to customers on the basis of customers not understanding
     the system requirements, or the presence of compatibility issues, including inadequate 
     internet speed or consistency, or incompatible devices, operating systems, or browser 
     software versions. <b>System incompatibility based on system requirements should be tested 
     Free plans and cannot be done in paid plans.</b></p>
<br>
     <h2 id="terms">Cancellation Policy</h2>
 
     <p>Customer acknowledges that all subscription fees are charged automatically on a recurring basis <b>until the 
Client cancels their channel subscription</b> (both month-to-month and annual plans). The client may cancel 
their subscription by logging into their STOCKsherpa Account and going to the applicable channel or billing page, 
clicking settings, and choosing the “Change” or "Cancel" options under subscription. A confirmation email receipt 
is sent to the Client with the expiration date of the subscription. The client can learn how to cancel, delete,
 or update their account here.  Please keep in mind that you are solely responsible for properly cancelling 
 your account. You must email support before or on the payment due date. However, you can also contact 
 support if you are having difficulty or need help. </p>
 <br>
 <h2 id="terms">STOCKsherpa’s Refund Policy for Annual Plans</h2>
 
 <p>STOCKsherpa is committed to the success of our customers, but through experience, we have found that users
      have varying requirements, capabilities, and limitations with regard to the types of devices and software
       they can use, the way their local network is configured, the speed of their internet connection, etc.
These issues will certainly impact a customer’s ability to use STOCKsherpa as it is intended. </p>

<p>Accordingly, STOCKsherpa offers a 3-day Freecancellation and is happy to extend the trial period for users
    who need more time to evaluate our software and its compatibility with their specific environment.</p>


<p>STOCKsherpa also offers a Month-to-Month Subscription Plan, which allows customers to upgrade,
    downgrade, and cancel at any time without penalty. We recommend this option for most customers,
    especially those that do not have full certainty or control over their technology environment.</p>


<p>Finally, STOCKsherpa offers an Annual Subscription Plan at a significant discount. We recommend
    this plan for customers who are certain that STOCKsherpa is compatible with their environment.
    It is your responsibility to evaluate STOCKsherpa, including its features, limitations, and system
    requirements before selecting the Annual Plan.</p>

<p>If you are not certain that STOCKsherpa is a fit for your use case and environment,
    then you should not choose the Annual Plan.</p>

<p><b>STOCKsherpa will not issue refunds to Annual Plan customers</b> on the basis of customers not
    understanding the system requirements, or the presence of compatibility issues,
    including inadequate internet speed or consistency, or incompatible devices,
    operating systems, or browser software versions.</p>

<p>If you choose the Annual Plan, you are entering into a one-year subscription
    contract that expires 12 months after you sign up. You are responsible for
    paying for the entire subscription. If you decide to cancel before the term
    of your subscription is up, then you will still be billed for the remaining
    months on your subscription, and will still have access to its benefits until it expires.</p>

    <h2 id="contact-us">Contact Us</h2>
    <p>In order to resolve a complaint regarding the Site or to receive further information regarding 
 use of the Site, please contact us at:</p>

 <p>OCTOBAN TECHNOLOGY PRIVATE LIMITED<br>
No 402, VDB CARDINAL, 6TH MAIN,<br>
INDIRANAGAR, BENGALURU, KARNATAKA,<br>
560038</p>


</div>

</div>



<br />
</div>