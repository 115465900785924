<div class="container-fluid wel freedb comn">
    <div  class="inshead">
        <div class="rtntxt">
            <a  href="javascript:void(0,0)" [routerLink]="['/free-dashboard']">Return to Dashboard</a>       
        </div>
        <div class="moonshotdiv">
            <p class="moonshottxt"> 
               <span>&nbsp;&nbsp; MULTI-BAGGER STOCKS - Top Ranked &nbsp;&nbsp;</span> 
            </p>
            <!-- <p class="acctypetxt">
                <span>FOR PAID USERS ONLY TOP 100</span>
            </p> -->
        </div>


        
    </div>
    
    <div  >
 
    <!-- <div class="loopdiv" style="margin-top:15px;">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="freever">1</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="disablecolour"> Top 10&nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        
    </div> -->

    <div class="loopdiv" style="margin-top:15px;">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2 bytop">
                    <div class="searchinrdiv" (click)="gettopstocks('FreeTop')"> 
                        <div class="cntnum">
                            <span class="paidver">1</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="whtcolour"  > Top 10&nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
        
    </div>

    <div class="loopdiv" style="margin-top:15px;" data-toggle="modal" data-target="#myModaldash5">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="freever">2</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="disablecolour"> Top 25&nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div>
   
       
    </div>

    <div class="loopdiv" style="margin-top:15px;" data-toggle="modal" data-target="#myModaldash5">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="freever">3</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="disablecolour"> Top 42&nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div> 
       
    </div>

    <div class="loopdiv" style="margin-top:15px;" data-toggle="modal" data-target="#myModaldash5">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="freever">4</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="disablecolour"> Top 88&nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div> 
       
    </div>

    <div class="loopdiv" style="margin-top:15px;" data-toggle="modal" data-target="#myModaldash5">
        <div class="searchdiv mrb15" >
            <div class="row" >
                <div class="col-12 col-sm-12 col-md-10 col-lg-8  offset-md-1 offset-lg-2">
                    <div class="searchinrdiv"> 
                        <div class="cntnum">
                            <span class="freever">5</span>
                        </div>
                        <div class="qstxt">
                            <span class="ogcolour"><span  class="disablecolour"> Top 100 &nbsp; </span> <i class="fa fa-search ogcolour searchicon"></i> </span>
                    
                        </div>
                       
                       </div>
                </div>
            </div>
        </div> 
       
    </div>
    <div>
        <!-- <ul class="links">
            <li><a href="moonshot-stocks-industry-paid.html">IP</a></li>
            <li><a href="moonshot-stocks-industry-free.html">IF</a></li>
              <li><a href="moonshot-stocks-market-free.html">MF</a></li>
              <li><a href="moonshot-stocks-market-paid.html">MP</a></li>
               <li><a href="moonshot-stocks-rank-free.html">RF</a></li>
              <li><a href="moonshot-stocks-rank-paid.html">RP</a></li>
        </ul> -->
    </div>

    
    </div>
    <br/>
    <div id="datas">
        <div  *ngIf="TotalItems.length !=0" >
        <div class="datahide" >
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3" style="padding: 5px;">
                    <div class="gviewtxt">
                        <p style="text-transform: uppercase;font-size:12px;">GLANCE VIEW OF {{toprank}} MULTI-BAGGER STOCKS</p>
                    </div>
                </div>
            </div>
        
            
        
            <div class="compareres">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3 compadd">
                        <div class="row">
                            <div class="col-3">
                                <div class="cmpsthead">
                                    <p>STOCK NAME</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="cmpsrdetail">
                                    <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 2px;">
                                            <div class="cmpsrdetailnumblk">
                                                <p class="cmpsrdetailnum">9</p>
                                            </div>
                                            <div class="cmpsrdetailtxtblk">
                                                <p class="cmpsrdetailtxt">Fundamental<br />Ratios </p>
                                            </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-2">
                                <div class="cmpsrdetail">
                                    <div class="cmpsrdetailblk" style="text-align: center;padding-left: 5px;">
                                            <div class="cmpsrdetailnumblk">
                                                <p class="cmpsrdetailnum">4</p>
                                            </div>
                                            <div class="cmpsrdetailtxtblk">
                                                <p class="cmpsrdetailtxt">Valuation<br />Ratios </p>
                                            </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-2">
                                <div class="cmpsrdetail">
                                    <div class="cmpsrdetailblk"  style="text-align: center;padding-left: 5px;">
                                            <div class="cmpsrdetailnumblk">
                                                <p class="cmpsrdetailnum">8</p>
                                            </div>
                                            <div class="cmpsrdetailtxtblk">
                                                <p class="cmpsrdetailtxt">Opportunity<br />Ratios </p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="cmpoktohead">
                                    <p>STOCKSherpa <br>Score</p>
                                </div>
                            </div>
                            <div class="col-1">
                                
                            </div>
                        </div>
                    </div>
                </div> 
        
                <div class="forloop" *ngFor="let stock of TotalItems;">
                    <div class="row mrb15">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3 compadd">
                            <div class="row">
                                <div class="col-3">
                                    <div class="cmpstname">
                                        <p class="stockelipse">{{stock.company_name}}</p>
                                    </div>
            
                                </div>
                                <div class="col-2">
                                    <div class="cmpstval">
                                        <p>{{stock.fundamental_ratios}}</p>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="cmpstval">
                                        <p>{{stock.valuation_ratios}}</p>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="cmpstval">
                                        <p>{{stock.moonshot_ratios}}</p>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="cmpstoktoval">
                                        <p>{{stock.okto_score}}</p>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="cmpstexpand">
                                        <i class="fa fa-plus" id="dtlrepexpand{{stock.id}}" (click)="expand(stock.id)"></i>
                                        <br />
                                        <i class="fa fa-minus" style="display: none;" (click)="collapse(stock.id)" id="dtlrepcollapse{{stock.id}}"></i>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div id="dtlreport{{stock.id}}" style="display: none;margin-top:15px;">
        
                     
        
                        <div>
                            <div class="row mrb15">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                    <div class="row">
                                        <div class="col-3" style="padding-right:0;">
                                            <div class="srhead">
                                                <div class="srheadblk">
                                                    <p>21 STOCK RATIOS </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-3"  style="padding-right:0;">
                                            <div class="srhead">
                                                <div class="srheadblk">
                                                    <p>Ai SCORE </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="srhead">
                                                <div class="srheadblk">
                                                    <p>Ai ANALYSIS </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                </div>
                            </div>
                        
                            <div class="row mrb15">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                    <div class="row">
                                        <div class="col-3" style="padding-right: 0;">
                                            <div class="srdetail">
                                                <div class="srdetailblk">
                                                        <div class="srdetailnumblk">
                                                            <p class="srdetailnum">9</p>
                                                        </div>
                                                        <div class="srdetailtxtblk">
                                                            <p class="srdetailtxt">Fundamental<br />Ratios </p>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3" style="padding-right: 0;">
                                            <div class="aidetail">
                                                <div class="aidetailblk" [ngClass]="[stock.fr_color]">
                                                    <p > {{stock.fundamental_ratios}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" style="padding-left: 0;">
                                            <div class="aadetail">
                                                <div class="aadetailblk">
                                                    <p>{{stock.fr_aianalysis}} SCORE</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row mrb15">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                    <div class="row">
                                        <div class="col-3" style="padding-right:0;">
                                            <div class="srdetail">
                                                <div class="srdetailblk">
                                                        <div class="srdetailnumblk">
                                                            <p class="srdetailnum">4</p>
                                                        </div>
                                                        <div class="srdetailtxtblk">
                                                            <p class="srdetailtxt">Valuation<br />Ratios </p>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3" style="padding-right: 0;">
                                            <div class="aidetail">
                                                <div class="aidetailblk" [ngClass]="[stock.vr_color]">
                                                    <p > {{stock.valuation_ratios}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" style="padding-left: 0;">
                                            <div class="aadetail">
                                                <div class="aadetailblk">
                                                    <p>{{stock.vr_aianalysis}} PRICE</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row mrb15">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                    <div class="row">
                                        <div class="col-3" style="padding-right:0;">
                                            <div class="srdetail">
                                                <div class="srdetailblk">
                                                        <div class="srdetailnumblk">
                                                            <p class="srdetailnum">8</p>
                                                        </div>
                                                        <div class="srdetailtxtblk">
                                                            <p class="srdetailtxt">Opportunity<br />Ratios </p>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3" style="padding-right:0;">
                                            <div class="aidetail" >
                                                <div class="aidetailblk"  [ngClass]="[stock.ms_color]">
                                                    <p> {{stock.moonshot_ratios}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" style="padding-left: 0;">
                                            <div class="aadetail">
                                                <div class="aadetailblk">
                                                    <p>{{stock.ms_aianalysis}} SCORE</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row mrb15">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                    <div class="row">
                                        <div class="col-3" style="padding-right:0;">
                                            <div class="oktodetail">
                                                <div class="oktodetailblk">
                                                        <p>STOCKSherpa <br> Score</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3" style="padding-right: 0;">
                                            <div class="oktoaidetail">
                                                <div class="oktoaidetailblk" [ngClass]="[stock.octo_color]">
                                                    <p> {{stock.okto_score}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" style="padding-left: 0;">
                                            <div class="oktoaadetail">
                                                <div class="oktoaadetailblk">
                                                    <p >{{stock.okto_score_aianalysis}} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                                <div class="sanalysis">
                                    <p class="text-left sahead">STOCKSHERPA ANALYSIS</p>
                                    <div class="sacontent">             
                                        <p [innerHtml] ="stock.ss_analysis_text"></p>
                                    </div>
                                   </div>
                            </div>
                        </div>   
                        <div class="row justify-content-center portfolioalign">
                            <div class="col-8 portfoliotxt" (click)="addportfolio(stock.id)">
                                <a  style="font-size: 12px;"> <span>Add to Portfolio</span>&nbsp;<i
                                        class="fa fa-plus" style="color: #f58220;"></i></a>
                            </div>
                        </div> 
                        <div class="row mrb15">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3  offset-lg-3">
                        <div class="row">
       
                            <div class="col-12" id="divbdrid">
                                <div class="datahide">
                                    <div class="dtlreport">
                                        <div class="row dareport"  #scrollMe1>
                                            <div class="col-12">
                                                <p class="headstyle">
                                                    <span>Detailed Analysis Report - <span>({{stock.yesterday_date}})</span></span>
                                                </p>
                                            </div>
                        
                                        </div>
                        
                                        <div class="row dtlstockdtls">
                                            <div class="col-12 col-sm-12 col-md-10 col-lg-8 offset-md-1  offset-lg-2">
                                                <div class="row">
                                                    <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-right: 0;">
                                                        <p>Stock Name</p>
                                                        <p>Industry</p>
                        
                                                    </div>
                                                    <div class="col-8 col-sm-8 col-md-8 col-lg-8 bdrl" style="padding-left: 3px;">
                                                        <p>{{stock.company_name}}</p>
                                                        <p>{{stock.industry}}</p>
                        
                                                    </div>
                                                </div>
                        
                                            </div>
                                        </div>
                    
                                        <div id="aianalysis1"  style="padding: 0 15px;">
                                           
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="aihead">
                                                                    <p>AI ANALYSIS</p>
                                                                </div>
                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-9">
                                                                <p class="headstyle">Fundamental Ratios -&nbsp;<span>
                                                                        {{stock.fr_aianalysis}} SCORE</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>EPS</p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.eps}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>ROCE<br /><span>3 years</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.roce}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>ROE<br /><span>3 years</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.roe}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PROFIT GR<br /><span>3 years</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.profit_gr}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>OPM</p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.opm}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>SALES<br /><span>GR</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.sales_growth_3years}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>DEBT TO<br /><span>EQUITY</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.dept_to_equity}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>CURRENT<br /><span>RATIO</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.current_ratio}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-9">
                                                                <p class="headstyle">Valuation Ratios -&nbsp;<span>
                                                                        {{stock.vr_aianalysis}} PRICE</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PRICE<br /><span>TO EARNING</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.price_to_earning}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PRICE<br /><span>TO SALES</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.price_to_sales}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PRICE TO<br /><span>BK VALUE</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.price_to_bk_value}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PRICE TO<br /><span>FCF</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.price_to_fcf}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                        
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-9">
                                                                <p class="headstyle">Opportunity Ratios -&nbsp;<span>
                                                                        {{stock.ms_aianalysis}} SCORE</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>INTRINSIC<br /><span>VALUE</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.intrinsic_value}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PEG</p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.peg}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>FCF TO<br /><span>SALES</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.fcf_to_sales}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>CROIC</p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.croic}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mrb15">
                                                    <div class="col-12">
                                                        <div class="row rmpadding">
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PIOTROSKI<br /><span>SCORE</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.piotroski_score}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>ALTMAN Z<br /><span>SCORE</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.altman_z_score}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>FOUNDER<br /><span>STAKE</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>{{stock.founder_stake}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="aiblock">
                                                                    <div class="aitxt">
                                                                        <p>PLEDGED<br /><span>STOCKS</span></p>
                                                                    </div>
                                                                    <div class="aivalue">
                                                                        <p>
                                                                            {{stock.pledged_stocks}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="row mrb15 justify-content-center rmpadding">
                                                    <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                                        <div class="oktoblock">
                                                            <div class="oktotxt">
                                                                <p>STOCKSherpa <br><span>Score</span></p>
                                                            </div>
                                                            <div class="oktovalue">
                                                                <p>{{stock.okto_score}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                        </div>
                                        
                        
                        
                        
                                       
                        
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                    </div>                     
                    </div> 
                    
                </div>
                <div style="color: #fff;font-weight: 700;text-decoration: underline;text-align: center;cursor: pointer;">
                    <a  data-toggle="modal" data-target="#myModaldash5"> For More Stocks </a>       
                </div>
            </div>
        </div>
    </div>
   
    </div>

    <br/>

    <div class="moonstar">
        <img src="../../assets/images/moon-stars.png" alt="" class="stars2">
    </div>
</div>
<div id="myModaldash5" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div>
            <p style="color: #f58220;font-weight: 700;">To gain access to names and analysis <br> of all the Multi-bagger stocks (10X)</p>
            <p style="color: #fff;font-weight: 700;">Please upgrade to paid subscription.</p>
          <div class="btns"><a href="javascript:void(0,0)" class="click_btn"  (click)="Upgrade()"><span>Upgrade</span></a></div>
          </div>
          <div>
          <img src="../../../assets/images/upgrade.png" style="    border-bottom-left-radius: 57px;
border-bottom-right-radius: 57px;">
          </div>
        </div>
      </div> 
      </div>
  </div>