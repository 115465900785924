<ng2-toasty [position]="'bottom-right'"></ng2-toasty>
<div id="nd-contct-us">
    <div class="container-fluid">
        <div id="nd-hme-mobile">
            <h1><span>CONTACT</span>Us</h1>
            <p>STOCKSherpa would love to guide you towards achieving your peak potential in high growth Investing. Reach out to us to know how!</p>
            <section class="contact-clean">
                <form method="post">
                    <div class="form-group"><input class="form-control" type="text" name="name" maxlength="100" placeholder="Name *"  [(ngModel)]="name"></div>
                    <div class="form-group"><input class="form-control" type="email" name="email" maxlength="100" placeholder="Email *" [(ngModel)]="email" ></div>
                    <div class="form-group"><input class="form-control" type="text" name="subject" maxlength="200" placeholder="Subject *" [(ngModel)]="subject"></div>
                    <div class="form-group"><textarea class="form-control" name="message" placeholder="Message *" rows="10" [(ngModel)]="message"></textarea></div>
                    <div class="form-group"><button class="btn btn-primary" type="button" (click)="sendemail('mobile')">send </button></div>
                </form>
            </section>
        </div>
        <div id="nd-hme-desktop" class="nd-pad-div nd-add">
            <h1><span>CONTACT</span>&nbsp;Us</h1>
            <p>STOCKSherpa would love to guide you towards achieving your <br>peak potential in high growth Investing. Reach out to us to know how!</p>
            <div class="row">
                <div class="col-12">
                    <section class="contact-clean">
                        <form method="post">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group"><input class="form-control" type="text" maxlength="100" name="name" placeholder="Name *"  [(ngModel)]="d_name"></div>
                                    <div class="form-group"><input class="form-control" type="email" maxlength="100" name="email" placeholder="Email *"  [(ngModel)]="d_email" ></div>
                                    <div class="form-group"><input class="form-control" type="text" maxlength="200" name="subject" placeholder="Subject *"  [(ngModel)]="d_subject"></div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group"><textarea class="form-control" name="message" placeholder="Message *" rows="25" [(ngModel)]="d_message"></textarea></div>
                                </div>
                            </div>
                            <div class="form-group"><button class="btn btn-primary" type="button"  (click)="sendemail('desktop')">send </button></div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>