import { Component, OnInit,NO_ERRORS_SCHEMA } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import { Httpclient } from '../_services/authorization.service'; 
import {Subject, Observable, Subscription} from 'rxjs';  
import { EncrDecrService } from '../_services/encrdecr.service';

declare var $: any; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title: string ="";
  firstname: string ="";
  lastname: string =""; 
  mobile: string =""; 
  email: string =""; 
  chkrobot: boolean = false;  
  subscriptiontype: string="Free";
  Ip_PlanType: string = "";
  // username: string ="";
  // password: string ="";

  // username: string ="prabakaran@warblerit.com";
  // password: string ="4p5io06j";

    username: string ="";
   password: string ="";

  //username: string ="ps2@warblerit.com";
  //password: string ="Stock@2021";
 
  

  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private route: ActivatedRoute,
    private router: Router,
    private EncrDecr: EncrDecrService
     ) { 
    this.toastyConfig.theme = 'material';
  }

  ngOnInit(): void {
    $(document).ready(function() {
              
      /* Centering the modal vertically */
      function alignModal() {
          var modalDialog = $(this).find(".modal-dialog");
          modalDialog.css("margin-top", Math.max(0, 
          ($(window).height() - modalDialog.height()) / 2));
      }
      $(".modal").on("shown.bs.modal", alignModal);

      /* Resizing the modal according the screen size */
      $(window).on("resize", function() {
          $(".modal:visible").each(alignModal);
      });
      
  });
  this.Ip_PlanType = localStorage.getItem("_plantype"); 
  if(this.Ip_PlanType ==="Monthly" || this.Ip_PlanType ==="Yearly" || this.Ip_PlanType ==="Free")
  {
    debugger
    $("#myModaln1").modal('show');
    localStorage.setItem("_plantype", "");
    this.subscriptiontype = this.Ip_PlanType;
    this.Ip_PlanType ="";
  } 

  //document.getElementById("myModaln1").click();

  $('.modal-backdrop').css('display','none');
  }

 
  Register() {    
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 8000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.title == undefined ) {
      this.title ="";
    }
    if (this.firstname == undefined || this.firstname.trim() == '') {      
      ////toastOptions.msg += 'Name is required' + '</br>';
      this.firstname ="";
    }
    if (this.lastname == undefined || this.lastname.trim() == '') {
      this.lastname ="";
    }
    if (this.email == undefined || this.email.trim() == '') {
      toastOptions.msg += 'E-mail is required' + '</br>';
    }
    else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
      toastOptions.msg += 'E-mail is invalid'+ '</br>';
    }
    if (this.mobile == undefined || this.mobile == '') {
      ////toastOptions.msg += 'Mobile No. is required' + '</br>';
      this.mobile="";
    }
    else { 
        if((/^\d{10}$/.test(this.mobile))) {
        }
        else {
          if (this.mobile.length != 10) {
            toastOptions.msg += 'Mobile No. should be ten digits' + '</br>'; 
          }
          else {
            toastOptions.msg += 'Mobile No. is invalid' + '</br>'; 
          }
        }
    }
    if (this.chkrobot == undefined || this.chkrobot ==false) {
      toastOptions.msg += 'I am not a robot is required' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        title: this.title,
        first_name: this.firstname,
        last_name:  this.lastname,
        email:  this.email,
        mobile_no: this.mobile,
        password: "",
        subscriptiontype: this.subscriptiontype
      };
      
     
      this.spinner.show();
      return this.http.post("registers", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if(parsedJson.code==200){
         // window.location.href = parsedJson.data;
        this.title="";
        this.firstname="";
        this.lastname="";
        this.mobile="";
        this.email="";
        this.chkrobot = false;
        //toastOptions.msg = parsedJson.data[0][0].Msg;
        //this.toastyService.success(toastOptions);
        
        // setTimeout(() => {
          $('#myModaln1').trigger('click');
        // }, 3000); 
          $("#myModaln2").modal('show');
        }
        else if(parsedJson.code==400) {
          toastOptions.msg = parsedJson.data[0][0].Msg;
          this.toastyService.error(toastOptions);
        }
        else {
          toastOptions.msg = "An error occurred while creating user.";
          this.toastyService.error(toastOptions);
        }
        this.spinner.hide();
      }).catch(err => {
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });
    
    
     }
  }

  Login() { 
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    if (this.username == undefined || this.username.trim() == '') {
      toastOptions.msg += 'Email Address is required' + '</br>';
    }
    else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.username))) {
      toastOptions.msg += 'Email Address is invalid'+ '</br>';
    }
    if (this.password == undefined || this.password.trim() == '') {
      toastOptions.msg += 'Password is required' + '</br>';
    }


    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {
      const user = {
        email: this.username,
        password: this.password, 
      };
      this.spinner.show();
      return this.http.post("login", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if (parsedJson.user_details[0][0].Flag == "Authentication failed") {
          toastOptions.msg = "Authentication failed";
          this.toastyService.error(toastOptions);
        }
        else {
          this.username ="";
          this.password ="";
         

          localStorage.setItem("_uid", parsedJson.user_token);

            var item = {
            Email: parsedJson.user_details[0][0].Email,
            Flag:  parsedJson.user_details[0][0].Flag,
            UserId:  parsedJson.user_details[0][0].UserId,
            first_name:  parsedJson.user_details[0][0].first_name,
            last_name: parsedJson.user_details[0][0].last_name,
            mobile_no: parsedJson.user_details[0][0].mobile_no,
            title:  parsedJson.user_details[0][0].title, 
            status:  parsedJson.user_details[1][0].status,
            subscription_type: parsedJson.user_details[1][0].subscription_type,
            user_type: parsedJson.user_details[1][0].user_type,
            validity_from: parsedJson.user_details[1][0].validity_from,
            validity_to: parsedJson.user_details[1][0].validity_to 
            }

            var encrypted = this.EncrDecr.set('123456$#@$^@1ERF', JSON.stringify(item));
            // var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
           
            // console.log('Encrypted :' + encrypted);
            // console.log('Encrypted :' + decrypted);



          //localStorage.setItem("user_details", JSON.stringify(parsedJson.user_details));
          localStorage.setItem("user_details", encrypted);
          if(parsedJson.user_details[1][0].user_type=="Free") {
            this.router.navigate(['/free-dashboard'])
          }
          else {
            this.router.navigate(['/premium-dashboard'])
          }         
        } 
        this.spinner.hide();
      }).catch(err => {
        debugger 
        toastOptions.msg = "Authentication failed.";
        this.toastyService.error(toastOptions);
        this.spinner.hide(); 
        return err;
      });

     }


    }
    redirectToExternalUrl() {
        window.location.href = 'https://stocksherpa.in';
    }
    ForgotPassword(){
      var toastOptions: ToastOptions = {
        title: "Alert",
        showClose: true,
        timeout: 5000,
        theme: 'material',
        onAdd: (toast: ToastData) => {
        },
        onRemove: function (toast: ToastData) {
        }
      };
      toastOptions.msg = "";
      if (this.username == undefined || this.username.trim() == '') {
        toastOptions.msg += 'Email Address is required' + '</br>';
      }
      else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.username))) {
        toastOptions.msg += 'Email Address is invalid'+ '</br>';
      }
      if (toastOptions.msg != "") {
        this.toastyService.error(toastOptions);
      }
      else {
        const user = {
          email: this.username, 
        };
        this.spinner.show();
        return this.http.post("forgotpassword", user).toPromise().then(res => {
          debugger
          const parseJson =JSON.stringify(res);
          const parsedJson = JSON.parse(parseJson);
          if (parsedJson.data[0][0].Code == "200") {
            toastOptions.msg = parsedJson.data[0][0].Msg;
            this.toastyService.success(toastOptions);
          }
          else if(parsedJson.data[0][0].Code == "400") {            
            toastOptions.msg = parsedJson.data[0][0].Msg;
            this.toastyService.error(toastOptions);
          }
          this.spinner.hide();
        }).catch(err => {
          debugger
          toastOptions.msg = "An error occurred while retrieving the record.";
          this.toastyService.error(toastOptions);
          this.spinner.hide(); 
          return err;
        });
      }
    }
 
}
