import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncrDecrService } from '../../_services/encrdecr.service';
declare var $: any;
@Component({
  selector: 'app-paidheader',
  templateUrl: './paidheader.component.html',
  styleUrls: ['./paidheader.component.css']
})
export class PaidheaderComponent implements OnInit {
  logindetails: any;
  AccType: string="";
  subscriptionChk: string="";

  constructor(private route: ActivatedRoute,
    private router: Router,
    private EncrDecr: EncrDecrService  ) { }

  ngOnInit(): void {
    //this.logindetails = JSON.parse(localStorage.getItem("user_details")); 
    var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', localStorage.getItem("user_details"));
    this.logindetails = JSON.parse(decrypted); 

    this.AccType = this.logindetails.user_type;

    $(document).ready(function() {
              
      /* Centering the modal vertically */
      function alignModal() {
          var modalDialog = $(this).find(".modal-dialog");
          modalDialog.css("margin-top", Math.max(0, 
          ($(window).height() - modalDialog.height()) / 2));
      }
      $(".modal").on("shown.bs.modal", alignModal);

      /* Resizing the modal according the screen size */
      $(window).on("resize", function() {
          $(".modal:visible").each(alignModal);
      });
  });

  $(document).ready(function() {
    $('.navbar-nav .nav-item a').on('click', function(e) {
      var currLink = $(this);
      if($(window).width() >= 992) {
      // $('.nav-link').removeClass("active");
      // currLink.addClass("active");
      return false;
      }
      else {
        var navMain = $("#navbarSupportedContent");
        navMain.collapse('hide');
      }
    });
});

$('.navbar-toggler').on('click', function(){
     
  var canSee = $("#navbarSupportedContent").is(":visible");
  if(canSee){
    var navMain = $("#navbarSupportedContent");
    navMain.collapse('hide');
    return false;
  }
}); 

  }

  logoff() {
    localStorage.clear(); 
     this.router.navigate(['/login'])
   
  }

  menuclk(screenname)
  {
    if(screenname=='byindustry')
    {
      this.router.navigate(['/premiumby-industry'])
    }
    else if(screenname=='bymarket')
    {
      this.router.navigate(['/premiumby-market'])
    }
    else if(screenname=='top100')
    {
      this.router.navigate(['/premiumtop-rank'])
    }
  }

  downloadoktomap()
  {
    const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'https://sherpa.azureedge.net/pdf/oktomap_stocksherpa.pdf');
        link.setAttribute('download', `oktomap_stocksherpa.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
  }

  DownloadSTRATGYHACK()
  {
    const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'https://sherpa.azureedge.net/pdf/stratgy_hack_stocksherpa.pdf');
        link.setAttribute('download', `stratgy_hack_stocksherpa.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
  }


}
