<div class="container-fluid wel freedb comn"> 
    <div class="row">
        <div class="col-9 col-sm-9 col-md-6 col-lg-5 offset-lg-1">
            <div class="inrusrDtls">
                <p class="headp">Welcome {{UserName}},
                    <span style="font-family: avantgargotitcteedem;margin-top:0;"><br />Use StockSherpas AI tool to identify stocks that can create wealth.</span>
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-9 col-sm-9 col-md-6 col-lg-5 offset-lg-1" style="padding-top: 2px;">
            <div class="row">
                <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                    <div class="ais-InstantSearch">
                        <ais-instantsearch [config]="config">
                            <div class="searchbox" >
                                <app-autocomplete (onQuerySuggestionClick)="setQuery($event)" style="height: 30px;"
                                (onTextChange)="onChange($event)" placeholderval="Let AI analyse the stock in 5 seconds"
                                (onClear)="onInputChange($event)" [(ngInput)]="CompanytNme"></app-autocomplete>
                            </div>
                        </ais-instantsearch>
                    </div>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 justify-content-center"
                    style="padding: 0;z-index: 1029;"  (click)="go()" >
                    <div class="btns"><a  class="click_btn" style="height:42px;"><span  style="font-weight: 900;font-size:20px;">GO!</span></a></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 comparetxt" style="padding-right:0;padding-top: 10px;">
                    <a  (click)="compare()" style="cursor: pointer;font-size: 12px;"> <span>To compare stocks</span>&nbsp;<i class="fa fa-exchange"></i></a>
                </div>                
            </div>

        </div>
        <div class="col-2 col-sm-2 col-md-6 col-lg-6">

        </div>
    </div>
   
    
    <div class="row">
        <div class="col-12 col-sm-12  col-md-6 col-lg-5 offset-lg-1">
            <div class="datahide" style="display: none; margin-top:15px; padding: 0 15px;">
 
    
                <div class="row newheadstyle">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                           <div class="col-12" id="aianalysis1">
                                <p class="headstyle" style="border: none; padding: 10px 0;">STEP 1 -&nbsp; Find if the stock is Fundamentally strong?</p>
                            </div>
                            <div class="col-3" style="padding-right: 0;">
                            </div>
                        </div>
                        <div class="row mrb15">
                            <div class="col-4" style="padding-right: 0;">
                                <div class="srdetail">
                                    <div class="srdetailblk"> 
                                        <div class="srdetailtxtblk" style="width: 100%; vertical-align: middle;">
                                            <p class="srdetailtxt" style="text-align: center;">Fundamental Ratios</p>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
    
                            <div class="col-2" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{fundamental_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p style="font-size: 11px; color: #fff; font-weight: normal;">If score is >= 18 "Excellent; >=16 "Good" ; &lt;15 "Wait"</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mrb15">
                            <div class="col-4" style="padding-right: 0;">

                                <div class="srdetail">
                                    <div class="srdetailblk"> 
                                        <div class="srdetailtxtblk" style="width: 100%; vertical-align: middle;">
                                            <p class="srdetailtxt" style="text-align: center;">Operating Margin (OPM %)</p>
                                        </div> 
                                    </div>
                                </div>
 


                            </div>
    
                            <div class="col-2" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{dtlreportdata.opm}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p style="font-size: 11px; color: #fff; font-weight: normal;">If OPM >= 10 "Excellent" ; >= 8% "Good" ; &lt; 7 "Wait"</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
    
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <!-- <div class="col-3" style="padding-right:0;">
                                <div class="srdetail">
                                    <div class="srdetailblk">
                                        <div class="srdetailnumblk">
                                            <p class="srdetailnum">4</p>
                                        </div>
                                        <div class="srdetailtxtblk">
                                            <p class="srdetailtxt">Valuation<br />Ratios </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[valuation_color]">
                                        <p> {{valuation_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p>{{vr_aianalysis}} PRICE</p>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>
                    </div>
                </div>
    
                <div class="row newheadstyle mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                           <div class="col-12" id="aianalysis1">
                                <p class="headstyle" style="border: none; padding: 10px 0;">STEP 2 -&nbsp;Find if it is the "Right Price" to invest?</p>
                                   
                            </div>
                            <div class="col-3" style="padding-right: 0;">
                            </div>
                        </div>
                        <div class="row mrb15">
                            <div class="col-4" style="padding-right: 0;">
                                <div class="srdetail">
                                    <div class="srdetailblk">

                                        <div class="srdetailtxtblk" style="width: 100%; vertical-align: middle;">
                                            <p class="srdetailtxt" style="text-align: center;">Valuation Ratios</p>
                                        </div> 
 
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-2" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{valuation_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p style="font-size: 11px; color: #fff; font-weight: normal;"> If score >= 6 "Excellent" ; >=5 "Good" ; &lt; 4 "Wait"</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mrb15">
                            <div class="col-4" style="padding-right: 0;">

                                <div class="srdetail">
                                    <div class="srdetailblk"> 
                                        <div class="srdetailtxtblk" style="width: 100%; vertical-align: middle;">
                                            <p class="srdetailtxt" style="text-align: center;">Intrinsic Value (IV)</p>
                                        </div> 
                                    </div>
                                </div>
 


                            </div>
    
                            <div class="col-2" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{dtlreportdata.intrinsic_value}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p style="font-size: 11px; color: #fff; font-weight: normal;">If Price &lt;= IV "Excellent", price &lt;= 2 times IV "Good"; >3  times "Wait"</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="row newheadstyle mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                           <div class="col-12" id="aianalysis1">
                                <p class="headstyle" style="border: none; padding: 10px 0;">STEP 3 -&nbsp;Qualitative Checks</p>
                                   
                            </div>
                            <div class="col-3" style="padding-right: 0;">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="padding-right: 0;">                
                              <p class="stp3txt">a. Check if Pledged shares = 0       </p>
                              <p class="stp3txt">b. Debt to Equity less than 1 </p>
                            </div>
    
                         
                        </div>
 

                    </div>
                </div>
                
    
                <div class="row newheadstyle mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                           <div class="col-12" id="aianalysis1">
                                <p class="headstyle" style="border: none; padding: 10px 0;">STEP 4 -&nbsp;STOCKShepra Score</p>
                                   
                            </div>
                            <div class="col-3" style="padding-right: 0;">
                            </div>
                        </div>
                        <div class="row mrb15">
                            <div class="col-4" style="padding-right: 0;">
                                <div class="srdetail">
                                    <div class="srdetailblk"> 
                                        <div class="srdetailtxtblk" style="width: 100%; vertical-align: middle;">
                                            <p class="srdetailtxt" style="text-align: center;">STOCKSherpa Score</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-2" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{okto_score}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p style="font-size: 11px; color: #fff; font-weight: normal;">If >= 38 "Excellent" ; >= 35 "Good" ; &lt;35 "Wait"</p>
                                    </div>
                                </div>
                            </div>
                        </div>
 

                    </div>
                </div>
    
                <div class="row mrb15" style="margin-bottom: 7px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="sanalysis">
                            <p class="text-left sahead">&nbsp;STOCKSHERPA CARDINAL RULE&nbsp;</p>
    
                            <div class="sacontent">
                                <!-- <p #template></p> 
                                ONLY IF THE STOCK MEETS ALL THE CRITERIAS IN STEP1, STEP 2, STEP3 AND STEP4 YOU CAN CONSIDER INVESTMENT. ELSE WAIT <br><br> 
                                -->
                                 <p> <br>AS A SELF DISCIPLINE NEVER BUY ANY STOCK IF IT IS MORE THAN TWO TIMES INTRINSIC VALUE.</p>
                               
                            </div>
    
                        </div>
                    </div>
                </div>
    
                <div class="row justify-content-center" style="margin-bottom: 12px;">
                    <div class="col-8 portfoliotxt" (click)="addportfolio()">
                        <a  style="font-size: 12px;"> <span>Add to Portfolio</span>&nbsp;<i
                                class="fa fa-plus"></i></a>
                    </div>
                </div>
    
                <div class="row" style="margin-bottom: 12px;">
                    <div class="col-12" (click)="addportfolio()">
                        <p style="font-size: 14px; color:  #FFF;"> <span>Note:<br>  
                            <span style="color: #f58220;">></span>&nbsp;&nbsp;&nbsp;- greater than  <br> 
                            <span style="color: #f58220;">&lt;</span>&nbsp;&nbsp;&nbsp;- less than  <br> 
                            <span style="color: #f58220;">>=</span> - greater than or equal to  <br> 
                            <span style="color: #f58220;">&lt;=</span> - less than or equal to</span>
                        </p>
                    </div>
                </div>
    
    
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-5" id="divbdrid">
            <div class="datahide" style="display: none;">
                <div class="dtlreport">
                    <div class="row dareport"  #scrollMe1>
                        <div class="col-12">
                            <p class="headstyle">
                                <span>Detailed Analysis Report - <span>({{yesterday_date}})</span></span>
                            </p>
                        </div>
    
                    </div>
    
                    <div class="row dtlstockdtls">
                        <div class="col-12 col-sm-12 col-md-10 col-lg-8 offset-md-1  offset-lg-2">
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-right: 0;">
                                    <p>Stock Name</p>
                                    <p>Industry Type</p>
    
                                </div>
                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 bdrl" style="padding-left: 3px;">
                                    <p>{{company_name}}</p>
                                    <p>{{industry_type}}</p>
    
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div id="aianalysis1"  style="padding: 0 15px;">
                       
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="aihead">
                                            <p>AI ANALYSIS</p>
                                        </div>
                
                                    </div>
                                </div>
                            </div>
                        </div>
                
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-9">
                                        <p class="headstyle">Fundamental Ratios 
                                            <!-- -&nbsp;<span>{{dtlreportdata.fr_aianalysis}} SCORE</span> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>EPS</p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.eps}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>ROCE<br /><span>3 years</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.roce}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>ROE<br /><span>3 years</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.roe}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PROFIT GR<br /><span>3 years</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.profit_gr}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>OPM</p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.opm}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>SALES<br /><span>GR</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.sales_growth_3years}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>DEBT TO<br /><span>EQUITY</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.dept_to_equity}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>CURRENT<br /><span>RATIO</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.current_ratio}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-9">
                                        <p class="headstyle">Valuation Ratios 
                                            <!-- -&nbsp;<span>{{dtlreportdata.vr_aianalysis}} PRICE</span> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PRICE<br /><span>TO EARNING</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.price_to_earning}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PRICE<br /><span>TO SALES</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.price_to_sales}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PRICE TO<br /><span>BK VALUE</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.price_to_bk_value}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PRICE TO<br /><span>FCF</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.price_to_fcf}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-9">
                                        <p class="headstyle">Opportunity Ratios 
                                            <!-- -&nbsp;<span>{{dtlreportdata.ms_aianalysis}} SCORE</span> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>INTRINSIC<br /><span>VALUE</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.intrinsic_value}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PEG</p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.peg}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>FCF TO<br /><span>SALES</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.fcf_to_sales}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>CROIC</p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.croic}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mrb15">
                            <div class="col-12">
                                <div class="row rmpadding">
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PIOTROSKI<br /><span>SCORE</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.piotroski_score}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>ALTMAN Z<br /><span>SCORE</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.altman_z_score}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>FOUNDER<br /><span>STAKE</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p>{{dtlreportdata.founder_stake}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="aiblock">
                                            <div class="aitxt">
                                                <p>PLEDGED<br /><span>STOCKS</span></p>
                                            </div>
                                            <div class="aivalue">
                                                <p [ngClass]="[pledged_stocks_color]">
                                                    {{dtlreportdata.pledged_stocks}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row mrb15 justify-content-center rmpadding">
                            <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                <div class="oktoblock">
                                    <div class="oktotxt">
                                        <p>STOCKSherpa <br><span>Score</span></p>
                                    </div>
                                    <div class="oktovalue">
                                        <p>{{dtlreportdata.okto_score}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                </div>
    
    
                    <div class="rounded shadow">
                        <!-- Bordered tabs-->
                        <ul id="myTab1" role="tablist"
                            class="nav nav-tabs nav-pills with-arrow flex-column flex-sm-row text-center">
                            <li class="nav-item flex-sm-fill" (click)="scroll()">
                                <a id="overview1-tab" data-toggle="tab" href="#overview1" role="tab"
                                    aria-controls="overview1" aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border active">OVERVIEW </a>
                            </li>
                            <!-- <li class="nav-item flex-sm-fill" (click)="scroll()">
                                <a id="aianalysis1-tab" data-toggle="tab" href="#aianalysis1" role="tab"
                                    aria-controls="aianalysis1" aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border">Ai
                                    ANALYSIS </a>
                            </li> -->
                            <!-- <li class="nav-item flex-sm-fill">
                                <a id="chart1-tab" data-toggle="tab" href="#chart1" role="tab" aria-controls="chart1"
                                    aria-selected="true"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border">CHART</a>
                            </li> -->
    
                            <li class="nav-item flex-sm-fill">
                                <a id="swot1-tab" data-toggle="tab" href="#swot1" role="tab" aria-controls="swot1"
                                    aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border">SWOT</a>
                            </li>
    
                            <!-- <li class="nav-item flex-sm-fill">
                                <a id="financials1-tab" data-toggle="tab" href="#financials1" role="tab"
                                    aria-controls="financials1" aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border financialstab-width">CASH FLOW</a>
                            </li>
    
                            <li class="nav-item flex-sm-fill">
                                <a id="deals-tab" data-toggle="tab" href="#deals1" role="tab" aria-controls="deals1"
                                    aria-selected="false" class="nav-link font-weight-bold rounded-0 border dealstab-width">BALANCE SHEET</a>
                            </li>
    
                            <li class="nav-item flex-sm-fill pandltop">
                                <a id="pnl-tab" data-toggle="tab" href="#profitnloss1" role="tab" aria-controls="deals2"
                                    aria-selected="false" class="nav-link font-weight-bold rounded-0 border dealstab-width">PROFIT & LOSS</a>
                            </li> -->
    
    
                        </ul>
                        <div id="myTab1Content" class="tab-content">
     
                            <div id="overview1" role="tabpanel" aria-labelledby="aianalysis-tab"
                                class="tab-pane fade px-4 show active" style="padding-top: 15px;padding-bottom:15px;">
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>OVERVIEW</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>M.CAP</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.marketcap}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>FACE VALUE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.face_value}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>52 WEEK (H)</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.high_price}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>52 WEEK (L)</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.low_price}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>CURRENT PRICE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.currentprice}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>VOLUMES</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.volumes}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>UPPER LIMIT</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.upper_limit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>LOWER LIMIT</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.lower_limit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>SALES</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.sales}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>BOOK VALUE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.book_value}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>INDUSTRY PE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.industry_pe}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>COMPANY PE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.company_pe}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                       
                            <div id="chart1" role="tabpanel" 
                            class="tab-pane fade px-4" style="padding-top: 15px;padding-bottom:15px;" aria-labelledby="swot-tab" class="tab-pane fade px-4">
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="aihead">
                                                <p>CHART</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-1">
                            
                                </div>
                                <div class="col-10" style="text-align: center; padding: 0; margin-bottom: 10px;">
                                    <span class="ChartIcon" (click)="chartPrev()" id="ChartIconPrev">
                                        <i style="font-size: 18px;" class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                                    </span>
                                    &nbsp;
                                    <span class="ChartHed">
                                        {{ChartHead}}
                                    </span>
                                    &nbsp;
                                <span class="ChartIcon" (click)="chartNext()" id="ChartIconNext">
                                        <i style="font-size: 18px;" class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                                    </span>
                            
                                </div>
                                <div class="col-1">
                            
                                </div>
                            </div>
                                <div class="row mrb15">
                                    <div class="col-12" style="padding: 0;">
                                        <div class="chart-wrapper">
                                            <canvas baseChart 
                                                [datasets]="lineChartData" 
                                                [labels]="lineChartLabels" 
                                                [options]="lineChartOptions"
                                                [colors]="lineChartColors" 
                                                [legend]="lineChartLegend" 
                                                [chartType]="lineChartType" 
                                                [plugins]="lineChartPlugins">
                                            </canvas>
                                        </div>
                            
                                    </div>
                                </div>

                                <!-- <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="chart-wrapper">
                                            <canvas baseChart 
                                                [datasets]="lineChartData1" 
                                                [labels]="lineChartLabels1" 
                                                [options]="lineChartOptions"
                                                [colors]="lineChartColors" 
                                                [legend]="lineChartLegend" 
                                                [chartType]="lineChartType" 
                                                [plugins]="lineChartPlugins">
                                            </canvas>
                                        </div>
                            
                                    </div>
                                </div> -->
      
                            </div>
                            <div id="swot1" role="tabpanel" 
                            class="tab-pane fade px-4 show active" style="padding-top: 15px;padding-bottom:15px;" aria-labelledby="swot-tab" class="tab-pane fade px-4">
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="aihead">
                                                <p>SWOT</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead" style="margin-left: 10%;">STRENGTH</p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_strength"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">WEAKNESS</p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_weakness"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">OPPORTUNITIES </p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_opportunities"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">THREAT </p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_threat"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div id="financials1" role="tabpanel" aria-labelledby="financials-tab"
                            style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                               
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>CASH FLOW</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="dataSource" style="width: 100%;">
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="cashflowdetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td  id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="showtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="hidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of displayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: displayedColumns;"></tr>
                            
                              </table>
                            </div>
                                          
                                  
                            </div>
                            <div id="deals1" role="tabpanel" aria-labelledby="deals-tab" style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                                
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>BALANCE SHEET</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="balancesheetdataSource"  style="width: 100%;">
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="balancesheetdetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td   id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="balancesheetshowtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="balancesheethidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of BalancesheetdisplayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="BalancesheetdisplayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: BalancesheetdisplayedColumns;"></tr>
                            
                              </table>
                            </div>
                            </div>
                            <div id="profitnloss1" role="tabpanel" aria-labelledby="deals-tab" style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                                
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>PROFIT & LOSS</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="PNLdataSource" style="width: 100%;">
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="pnldetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td   id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="pnlshowtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="pnlhidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of pandldisplayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="pandldisplayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: pandldisplayedColumns;"></tr>
                            
                              </table>
                            </div>
                            </div>
    
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12 col-sm-12  col-md-6 col-lg-5 offset-lg-1">
            <div class="datahide" style="display: none; margin-top:15px;">
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3" style="padding-right:0;">
                                <div class="srhead">
                                    <div class="srheadblk">
                                        <p>21 STOCK RATIOS </p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right:0;">
                                <div class="srhead">
                                    <div class="srheadblk">
                                        <p>Ai SCORE </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="srhead">
                                    <div class="srheadblk">
                                        <p>Ai ANALYSIS </p>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3" style="padding-right: 0;">
                                <div class="srdetail">
                                    <div class="srdetailblk">
                                        <div class="srdetailnumblk">
                                            <p class="srdetailnum">9</p>
                                        </div>
                                        <div class="srdetailtxtblk">
                                            <p class="srdetailtxt">Fundamental<br />Ratios </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[fundamental_color]">
                                        <p> {{fundamental_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p>{{fr_aianalysis}} SCORE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3" style="padding-right:0;">
                                <div class="srdetail">
                                    <div class="srdetailblk">
                                        <div class="srdetailnumblk">
                                            <p class="srdetailnum">4</p>
                                        </div>
                                        <div class="srdetailtxtblk">
                                            <p class="srdetailtxt">Valuation<br />Ratios </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right: 0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[valuation_color]">
                                        <p> {{valuation_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p>{{vr_aianalysis}} PRICE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3" style="padding-right:0;">
                                <div class="srdetail">
                                    <div class="srdetailblk">
                                        <div class="srdetailnumblk">
                                            <p class="srdetailnum">8</p>
                                        </div>
                                        <div class="srdetailtxtblk">
                                            <p class="srdetailtxt">Opportunity<br />Ratios </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right:0;">
                                <div class="aidetail">
                                    <div class="aidetailblk" [ngClass]="[moonshot_color]">
                                        <p> {{moonshot_ratios}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="aadetail">
                                    <div class="aadetailblk">
                                        <p>{{ms_aianalysis}} SCORE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row mrb15">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3" style="padding-right:0;">
                                <div class="oktodetail">
                                    <div class="oktodetailblk">
                                        <p>STOCKSherpa <br/>Score</p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-3" style="padding-right: 0;">
                                <div class="oktoaidetail">
                                    <div class="oktoaidetailblk" [ngClass]="[okto_color]">
                                        <p> {{okto_score}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="padding-left: 0;">
                                <div class="oktoaadetail">
                                    <div class="oktoaadetailblk">
                                        <p>{{okto_score_aianalysis}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="row mrb15" style="margin-bottom: 7px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="sanalysis">
                            <p class="text-left sahead">&nbsp;STOCKSHERPA ANALYSIS&nbsp;</p>
    
                            <div class="sacontent">
                                <p #template></p>
                            </div>
    
                        </div>
                    </div>
                </div>
    
                <div class="row justify-content-center" style="margin-bottom: 12px;">
                    <div class="col-8 portfoliotxt" (click)="addportfolio()">
                        <a style="font-size: 12px;"> <span>Add to Portfolio</span>&nbsp;<i
                                class="fa fa-plus"></i></a>
                    </div>
                </div>
    
    
    
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-5" id="divbdrid">
            <div class="datahide" style="display: none;">
                <div class="dtlreport">
                    <div class="row dareport"  #scrollMe1>
                        <div class="col-12">
                            <p class="headstyle">
                                <span>Detailed Analysis Report - <span>({{yesterday_date}})</span></span>
                            </p>
                        </div>
    
                    </div>
    
                    <div class="row dtlstockdtls">
                        <div class="col-12 col-sm-12 col-md-10 col-lg-8 offset-md-1  offset-lg-2">
                            <div class="row">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4" style="padding-right: 0;">
                                    <p>Stock Name</p>
                                    <p>Industry</p>
    
                                </div>
                                <div class="col-8 col-sm-8 col-md-8 col-lg-8 bdrl" style="padding-left: 3px;">
                                    <p>{{company_name}}</p>
                                    <p>{{industry_type}}</p>
    
                                </div>
                            </div>
    
                        </div>
                    </div>

                    <div id="aianalysis1"  style="padding: 0 15px;">
                       
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="aihead">
                                                <p>AI ANALYSIS</p>
                                            </div>
                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-9">
                                            <p class="headstyle">Fundamental Ratios -&nbsp;<span>
                                                    {{dtlreportdata.fr_aianalysis}} SCORE</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>EPS</p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.eps}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>ROCE<br /><span>3 years</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.roce}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>ROE<br /><span>3 years</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.roe}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PROFIT GR<br /><span>3 years</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.profit_gr}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>OPM</p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.opm}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>SALES<br /><span>GR</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.sales_growth_3years}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>DEBT TO<br /><span>EQUITY</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.dept_to_equity}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>CURRENT<br /><span>RATIO</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.current_ratio}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-9">
                                            <p class="headstyle">Valuation Ratios -&nbsp;<span>
                                                    {{dtlreportdata.vr_aianalysis}} PRICE</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PRICE<br /><span>TO EARNING</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.price_to_earning}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PRICE<br /><span>TO SALES</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.price_to_sales}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PRICE TO<br /><span>BK VALUE</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.price_to_bk_value}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PRICE TO<br /><span>FCF</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.price_to_fcf}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                    
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-9">
                                            <p class="headstyle">Opportunity Ratios -&nbsp;<span>
                                                    {{dtlreportdata.ms_aianalysis}} SCORE</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>INTRINSIC<br /><span>VALUE</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.intrinsic_value}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PEG</p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.peg}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>FCF TO<br /><span>SALES</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.fcf_to_sales}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>CROIC</p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.croic}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row rmpadding">
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PIOTROSKI<br /><span>SCORE</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.piotroski_score}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>ALTMAN Z<br /><span>SCORE</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.altman_z_score}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>FOUNDER<br /><span>STAKE</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p>{{dtlreportdata.founder_stake}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="aiblock">
                                                <div class="aitxt">
                                                    <p>PLEDGED<br /><span>STOCKS</span></p>
                                                </div>
                                                <div class="aivalue">
                                                    <p [ngClass]="[pledged_stocks_color]">
                                                        {{dtlreportdata.pledged_stocks}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mrb15 justify-content-center rmpadding">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-3">
                                    <div class="oktoblock">
                                        <div class="oktotxt">
                                            <p>STOCKSherpa <br/><span>Score</span></p>
                                        </div>
                                        <div class="oktovalue">
                                            <p>{{dtlreportdata.okto_score}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       
                    </div>
    
    
    
    
                    <div class="rounded shadow">
                       
                        <ul id="myTab1" role="tablist"
                            class="nav nav-tabs nav-pills with-arrow flex-column flex-sm-row text-center">
                            <li class="nav-item flex-sm-fill" (click)="scroll()">
                                <a id="overview1-tab" data-toggle="tab" href="#overview1" role="tab"
                                    aria-controls="overview1" aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border active">OVERVIEW </a>
                            </li>
                           
    
                            <li class="nav-item flex-sm-fill">
                                <a id="swot1-tab" data-toggle="tab" href="#swot1" role="tab" aria-controls="swot1"
                                    aria-selected="false"
                                    class="nav-link font-weight-bold mr-sm-3 rounded-0 border">SWOT</a>
                            </li>
    
                            
    
    
    
    
                        </ul>
                        <div id="myTab1Content" class="tab-content">
    
                        
                            <div id="overview1" role="tabpanel" aria-labelledby="aianalysis-tab"
                                class="tab-pane fade px-4 show active" style="padding-top: 15px;padding-bottom:15px;">
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>OVERVIEW</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>M.CAP</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.marketcap}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>FACE VALUE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.face_value}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>52 WEEK (H)</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.high_price}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>52 WEEK (L)</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.low_price}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>CURRENT PRICE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.currentprice}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>VOLUMES</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.volumes}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>UPPER LIMIT</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.upper_limit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>LOWER LIMIT</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.lower_limit}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row rmpadding">
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>SALES</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.sales}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>BOOK VALUE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.book_value}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>INDUSTRY PE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.industry_pe}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="aiblock">
                                                    <div class="aitxt">
                                                        <p>COMPANY PE</p>
                                                    </div>
                                                    <div class="aivalue">
                                                        <p>{{dtlreportdata.company_pe}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                           
                            <div id="chart1" role="tabpanel" 
                            class="tab-pane fade px-4" style="padding-top: 15px;padding-bottom:15px;" aria-labelledby="swot-tab" class="tab-pane fade px-4">
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="aihead">
                                                <p>CHART</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-1">
                            
                                </div>
                                <div class="col-10" style="text-align: center; padding: 0; margin-bottom: 10px;">
                                    <span class="ChartIcon" (click)="chartPrev()" id="ChartIconPrev">
                                        <i style="font-size: 18px;" class="fa fa-chevron-circle-left" aria-hidden="true"></i>
                                    </span>
                                    &nbsp;
                                    <span class="ChartHed">
                                        {{ChartHead}}
                                    </span>
                                    &nbsp;
                                <span class="ChartIcon" (click)="chartNext()" id="ChartIconNext">
                                        <i style="font-size: 18px;" class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                                    </span>
                            
                                </div>
                                <div class="col-1">
                            
                                </div>
                            </div>
                                <div class="row mrb15">
                                    <div class="col-12" style="padding: 0;">
                                        <div class="chart-wrapper">
                                            <canvas baseChart 
                                                [datasets]="lineChartData" 
                                                [labels]="lineChartLabels" 
                                                [options]="lineChartOptions"
                                                [colors]="lineChartColors" 
                                                [legend]="lineChartLegend" 
                                                [chartType]="lineChartType" 
                                                [plugins]="lineChartPlugins">
                                            </canvas>
                                        </div>
                            
                                    </div>
                                </div>

                               
                            </div>
                            


                           
                            
                            <div id="swot1" role="tabpanel" 
                            class="tab-pane fade px-4" style="padding-top: 15px;padding-bottom:15px;" aria-labelledby="swot-tab" class="tab-pane fade px-4">
                            <div class="row mrb15">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="aihead">
                                                <p>SWOT</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead" style="margin-left: 10%;">STRENGTH</p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_strength"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">WEAKNESS</p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_weakness"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">OPPORTUNITIES </p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_opportunities"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 15px;">
                                        <div class="sanalysis" style="padding-left:10% !important">
                                            <p class="text-left sahead"  style="margin-left: 10%;">THREAT </p>
                                            <div class="sacontents">             
                                                <p [innerHtml] ="dtlreportdata.Swot_threat"></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div id="financials1" role="tabpanel" aria-labelledby="financials-tab"
                            style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                               
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>CASH FLOW</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="dataSource" style="width:100%;">
                            
                               
                                <ng-container matColumnDef="cashflowdetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td   id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="showtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="hidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of displayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: displayedColumns;"></tr>
                            
                              </table>
                            </div>
                                          
                                  
                            </div>
                            <div id="deals1" role="tabpanel" aria-labelledby="deals-tab" style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                                
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>BALANCE SHEET</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="balancesheetdataSource" style="width:100%;">
                            
                               
                                <ng-container matColumnDef="balancesheetdetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td   id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="balancesheetshowtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="balancesheethidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of BalancesheetdisplayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="BalancesheetdisplayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: BalancesheetdisplayedColumns;"></tr>
                            
                              </table>
                            </div>
                            </div>
                            <div id="profitnloss1" role="tabpanel" aria-labelledby="deals-tab" style="padding-top: 15px;padding-bottom:15px;" class="tab-pane fade">
                               
                                
                                <div class="row mrb15">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="aihead">
                                                    <p>PROFIT & LOSS</p>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                     
                                          
                                           <div class="example-container mat-elevation-z8">
                              <table mat-table [dataSource]="PNLdataSource" style="width: 100%;">
                            
                               
                                <ng-container matColumnDef="pnldetails" sticky>
                                  <th mat-header-cell *matHeaderCellDef >   </th>
                                  <td   id="{{element.class}}" mat-cell *matCellDef="let element"> {{element.headings}} 
                                    <span style="cursor: pointer;" class="SpanPlus{{element.class}}"   (click)="pnlshowtr(element.class)" *ngIf="element.treeview=='Y'"> <i class="fa fa-plus" aria-hidden="true"></i>
                                      </span> 
                                      <span style="cursor: pointer;" class="SpanMinus{{element.class}}"  (click)="pnlhidetr(element.class)"  *ngIf="element.treeview=='Y'" > <i class="fa fa-minus" aria-hidden="true"></i>
                                      </span> 
                                    
                                    </td>
                                </ng-container>
                             
                                <ng-container  [matColumnDef]="column" *ngFor="let column of pandldisplayedColumns | slice:1;">
                                  <th mat-header-cell *matHeaderCellDef> {{column}}  </th>
                                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container> 
                            
                                <tr mat-header-row *matHeaderRowDef="pandldisplayedColumns; sticky: true"></tr>
                                <tr mat-row class="{{row.class}}" *matRowDef="let row; columns: pandldisplayedColumns;"></tr>
                            
                              </table>
                            </div>
                            </div>
    
    
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div> -->





    <!-- <img src="../../assets/images/stars1.png" alt="" class="stars1">
    <img src="../../assets/images/moon.png" alt="" class="moon1">
    <img src="../../assets/images/stars2.png" alt="" class="stars2"> -->
    <div class="moonstar">
        <img src="../../assets/images/moon-stars.png" alt="" class="stars2">
    </div>
   
</div>

<div id="myModaldash5" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: none;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div>
          <p style="color: #f58220;font-weight: 700;">SORRY! BEING GUIDED DIRECTLY <br>TO DOWNLOAD STRATEGY GUIDE IS</p>
          <p style="color: #fff;font-weight: 700;">FOR PAID MEMBERS ONLY!</p>
          <div class="btns"><a href="javascript:void(0,0)" class="click_btn" (click)="Upgrade()"><span>Upgrade</span></a></div>
          </div>
          <div>
          <img src="../../../assets/images/upgrade.png" style="    border-bottom-left-radius: 57px;
border-bottom-right-radius: 57px;">
          </div>
        </div>
      </div> 
      </div>
  </div>