<ng2-toasty [position]="'bottom-right'"></ng2-toasty>
<div class="lpage" id="home">

    <div class="container-fluid" id="abt">
        <div class="row d-none d-sm-block">
            <a href="./"><img src="../../../assets/images/01-full.png" class="img-responsive"></a>
        </div>
        <div class="row d-block d-sm-none">
            <a href="./"><img src="../../../assets/images/logo.png" class="img-responsive"></a>
        </div>
        <br>
        <div class="row homedtls">
            <div class="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                <div class="home-details">
                    <h2><span class="hmdtlsbold">STOCK</span>Sherpa</h2>
                    <p class="abtstock">
                        <span class="hmdtlsbold">STOCK</span>Sherpa is your Ai hack to financial freedom in the Indian
                        stock market. It analyzes every stock using 21 stock ratios with artificial intelligence (Ai) to
                        identify Moonshot stocks (10x)
                    </p>
                    <a href="" class="click_btn" data-toggle="modal" data-target="#myModal12"><b>21 STOCK RATIO</b>
                        Advantage</a>
                </div>
            </div>
        </div>
    </div>
    <br />

    <div class="d-block d-sm-none">
        <div class="footer-bg">
            <div class="owl-carousel owl-theme" id="home-slider">
                <div class="item">
                    <div class="row clearfix">
                        <div class="col-lg-7 col-sm-7 col-7 pr-0">
                            <div>
                                <img class="who_text" src="../../../assets/images/who-1.png">
                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 col-5 align-center">
                            <div class="who_text_anchot">
                                <a style="color: #fff;" class="click_here" data-toggle="modal"
                                    data-target="#myModal">Click
                                    here</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="row clearfix">
                        <div class="col-lg-7 col-sm-7 col-7 pr-0">
                            <div>
                                <img class="who_text" src="../../../assets/images/what-1.png">
                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 col-5 align-center">
                            <div class="who_text_anchot">
                                <a style="color: #fff;" class="click_here" data-toggle="modal"
                                    data-target="#myModalwhat">Click here</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-none d-sm-block">
        <div class="about-mountain" id="about">
            <div class="row clearfix common">
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="about-popup">
                        <div class="popup-content">
                            <p><img src="../../assets/images/who_white.png"></p>
                            <p class="heit-cls"><span class="hmdtlsbold">STOCK</span>Sherpa is for you, whether you are
                                a working professional, millennial, a beginner or a
                                long-term growth investor. <br>The quantitative analysis & benchmarks offered by your Ai
                                guide
                                ensures you don’t put a foot wrong in your quest for financial freedom while hiking up
                                the
                                Indian stock market, to find Moonshot stocks!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="about-popup">
                        <div class="popup-content">
                            <p><img src="../../assets/images/what_white.png"></p>
                            <p class="heit-cls">In a world of uncertainty and rapid change, stock investing as we know
                                and the maps we have, are
                                no longer relevant. <br>With sophisticated Ai algorithms, <span
                                    class="hmdtlsbold">STOCK</span>Sherpa guides you to effortlessly
                                hack the stock market and find Moonshot stocks (10x)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix common">
                <div class="col-lg-6 col-sm-6 col-12">

                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-md-9 col-sm-10 col-10 stcoksherpa-divbdr" id="divbdrLft">
                            <div class="stcoksherpa-div">
                                <h3>The biggest challenge!</h3>
                                <p>The Indian stock market has over 4000+ stocks listed. The challenge for you as an
                                    investor,
                                    would be to analyse and pick stocks that offer the highest return on investment &
                                    growth at
                                    lowest risk.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-md-9 col-sm-10 col-10 stcoksherpa-divbdr" id="divbdrRht">
                            <div class="stcoksherpa-div">
                                <h3><span class="hmdtlsbold">STOCK</span>Sherpa’s USP!</h3>
                                <p>When you receive stock recommendations from brokers or want to evaluate your current
                                    portfolio, use <span class="hmdtlsbold">STOCK</span>Sherpa’s sophisticated Ai tool
                                    OKTO to effortlessly and quantitatively
                                    validate your decision in 30 seconds!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" id="product">
        <div class="our-products products" >
                <div class="row ">
                    <div class="col-12 col-lg-6 col-sm-6 col-md-6 ourproductsbg">
                        <h2><b>OUR</b>Products</h2>
                        <div class="row">
                            <div class="col-12 col-lg-12 col-sm-12 col-md-12">
                                <div class="row justify-content-center">
                                    <div class="col-10 col-lg-6 col-sm-6 col-md-6">
                                        <div class="fredtimg Free-Edt"> 
                                            <img src="../../assets/images/Free-Edt.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="fredtcnt">
                                    <p>FREE EDITION can be used by investors to analyse any stock in 30 seconds using
                                        the 21 stock ratios. Some unique features in the FREE EDITION are</p>
                                    <ol class="abcd">
                                        <li>Identifying Moonshot stocks using Ai.</li>
                                        <li>Leverage the power of Ai to analyse stocks with the most
                                            exhaustive list of 9 Fundamental ratios, 4 valuation ratios and
                                            8 Moonshot ratios.</li>
                                        <li class="padd30">Use “compare” to analyse any 2 stocks.</li>
                                        <li class="padd95">Details of P&L, Balance sheet and cash flow.</li>
                                        <li class="padd75">Tracking the stock price chart.</li>
                                        <li>Details of the shareholding pattern of the stock</li>
                                        <li>Receive a copy of <span class="hmdtlsbold">STOCK</span>Sherpa Ai compass the
                                            <b>OKTO</b>map to
                                            understand the 21 stock ratios and Ai hacks to outsmart
                                            market experts
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-12 col-sm-12 col-md-12" style="padding: 0;">
                                <div class="prodemail">
                                    <div class="prodemail1">

                                        <p style="text-align: center;font-size:20px;font-weight: 700;margin-bottom: 5px;">
                                            Experience for FREE the power of <br />Ai stock analysis in 30 seconds!</p>
                                        <div class="row" style="margin: 0; padding-bottom: 5px;">

                                            <div class="col-2" style="padding: 0 2px;">
                                                <select class="form-control selheight" name="title" [(ngModel)]="title" style="font-size: 14px;padding-left: 0;padding-right: 0; ">
                                                    <option value="">Title *</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Ms</option>
                                                    <!-- <option value="Mrs">Mrs</option>
                                                    <option value="Miss">Miss</option>  -->
                                                  </select>
                                             </div>
                                             <div class="col-5" style="padding: 0 2px;">
                                                <input type="text" class="form-control" name="firstname"
                                                placeholder="Name *" [(ngModel)]="firstname" >
                                            </div>
                                            <div class="col-5" style="padding: 0 2px;">
                                                <input type="text" class="form-control" name="mobile"
                                                placeholder="Mobile no. *" [(ngModel)]="mobile"  onKeyPress="if(this.value.length==10) return false;" >
                                            </div>

                                          
                                          

                                               
                                        </div>
                                        <div class="row" style="margin: 0;">
                                            <div class="col-lg-9 col-sm-9 col-9" style="padding: 0;">
                                                <input type="text" class="form-control" name="email"
                                                    placeholder="Email *" [(ngModel)]="email">
                                            </div>
                                            <!-- (click)="Register()" -->
                                            <div class="col-3 col-sm-3 col-md-2 col-lg-3 dviewbtn" style="padding: 0;z-index: 1029;">
                                                <div class="btns"  ><a class="click_btn" (click)="Register()"
                                                        style="height:36px;width: 68px;margin-left:10px;cursor: pointer;"><span
                                                            style="font-weight: bold;" >GO!</span></a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dview3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-sm-6 col-md-6 paidedt">
                        <div class="dview2" style="height: 86px;">
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-10 col-lg-6 col-sm-6 col-md-6">
                                <div class="fredtimg ss-paid_logo">
                                    <img src="../../assets/images/stocksherpa_logo-paid.svg" alt="paid">
                                </div>
                            </div>
                        </div>
                        <div class="paidedtcnt">
                            <p>PAID EDITION is ideal for long term investors seeking high growth, ROI & safety. Outsourcing
                                the
                                complex financial analysis to Ai liberates your valuable time.
                                Some unique features in the PAID EDITION are-</p>
                            <ol>
                                <li>Using Ai analysis to evaluate and compare 5 stocks at a time.</li>
                                <li class="padd75">List of Top 100 moonshot stocks identified by Ai.</li>
                                <li class="padd75">List of Top 10 moonshot stocks by industry.</li>
                                <li class="padd75">List of Top 10 moonshot stocks by market capitalization.</li>
                                <li>Receive a copy of <span class="hmdtlsbold">STOCK</span>Sherpa strategy cards, the
                                    investment strategy hacks
                                    for financial
                                    freedom on the Indian stock market.</li>
                            </ol>
                            <br>
                        </div>
                        <div class="dview1" >
                        </div>
                        <div class="pricedtls">
                            <div class="products-details">
                                <div class="row scbdr">
                                    <div class="col-6 products-check ">
                                        <p>Have <span class="hmdtlsbold">STOCK</span>Sherpa guide you with a<br><u>MONTHLY
                                                SUBSCRIPTION</u></p>
                                    </div>
                                    <div class="col-6 products-check">
                                        <p>Have <span class="hmdtlsbold">STOCK</span>Sherpa guide you for the entire year
                                            with an <u>ANNUAL SUBSCRIPTION</u></p>
                                    </div>
                                </div>
                            </div>
                            <div class="subscription-details ">
                                <div class="row scbdr" style="background: #fff;border: 1px solid #ebebeb;">
                                    <div class="col-6 subscription-view scbdrrgt">
                                        <p><br><strong>Rs.444/- Only</strong><br><a (click)="signup('Monthly')"
                                                class="click_btn"  style="cursor: pointer;"><strong>SIGN
                                                    UP NOW!</strong></a>
                                            <!-- <br><span style="text-align: center; display: -webkit-box;">+
                                        GST</span> -->
                                        </p>
                                    </div>
                                    <div class="col-6 subscription-view scbdrlft">

                                        <p><strong>Rs.2250/- Only</strong> <br> <strong><span class="stricke-line">Rs.5328/-
                                                    Only</span></strong><br><a (click)="signup('Yearly')"
                                                class="click_btn"  style="cursor: pointer;"><strong>SIGN UP
                                                    NOW!</strong></a>
                                            <!-- <br><span style="text-align: center; display: -webkit-box;">+
                                    GST</span> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>


    <div class="container-fluid productsbg" id="team">
        <div class="products" >
            <div class="d-block d-sm-none">
                <div class="teamhead">
                    <h2><span>OUR</span>Team</h2>
                </div>
                <div class="teamdtls">
                    <p class="text-center"><span class="hmdtlsbold">STOCK</span>Sherpa was conceptualized by a small
                        passionate team of entrepreneurs, who
                        have
                        for
                        long been 'Sherpas' guiding their relevant industries towards excellence.<br>The team includes a Phd
                        in
                        artificial intelligence, an ethical hacker and a serial entrepreneur working on exponential
                        technologies,
                        all bonded by their non-conformist attitudes and zeal to <br>breach barriers!</p>
                </div>
                <div class="vission-div text-center">
                    <h3>Vision</h3>
                    <p class="text-center">To empower a million to achieve financial freedom on the Indian stock market with
                        sophisticated Ai stock hack, the <span class="hmdtlsbold">STOCK</span>Sherpa!</p>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 ">
                        <div class="row">
                            <div class="col-4">
                                <div class="team-image-div">
                                    <div class="team-image" style="text-align: center;">
                                        <img  style="border-radius:10px;"  src="../../../assets/images/profile_pic.jpeg">
                                        <ul class="social-links">
                                            <li><a href="https://twitter.com/vivek0210?s=08"><i style="color: #4267B2;" class="fa fa-twitter-square"
                                                        aria-hidden="true"></i></a></li>
                                            <li><a href="http://linkedin.com/in/vivekmadappa"><i style="color: #0077b5;"
                                                        class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                            <li><a href="https://www.instagram.com/vivek0210">
                                                    <img style="width: 25px;height: 25px;"
                                                        src="../../assets/images/instagram.png" aria-hidden="true">
                                                    <!-- <i class="fa fa-instagram instagram" style="border-radius: 12px;" aria-hidden="true"></i> -->
                                                </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8" style="padding-left: 0;">
                                <div class="team-content-div">
                                    <div class="team-content">
    
                                        <p style="margin:0;">Vivek Madappa<br>As <span class="hmdtlsbold">STOCK</span>Sherpa
                                            founder, Vivek aims to empower stock market investors with a cut<span
                                                id="dots">...</span>
    
                                            <span id="more" style="display: none;">ting-edge technology aided solution that
                                                removes worry and increases gains.
                                                He
                                                brings forth learnings from his over 3 decade career as a marketing /
                                                management
                                                expert and as a serial entrepreneur that combines his passion for technology
                                                to
                                                decode opportunities.</span>
                                            <a (click)="Readmore()" style="color: blue;text-decoration: underline;"
                                                id="Rdmore">read more</a>
                                        </p>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 blog-slider">
                        <h2 class="title" style="border-bottom:none;margin:0px;padding-bottom:0px"><span>SHERPAS</span>Blog
                        </h2>
                        <div class="row">
                            <div class="col-5  blog-image">
                            </div>
                            <div class="col-7" style="padding: 0;">
                                <div class="blog-st-div">
                                    <div class="blog-content">
                                        <div class="owl-carousel owl-theme" id="blog_slider">
                                            <div class="item">
                                                <div class="content-stt">
                                                    <h3>Journey of Stocksherpa</h3>
                                                    <p>The year was 1992 and I was a young management graduate trying to manage a high-pressure career and survive in an expensive city like Mumbai
                                                    </p>
                                                    <a href="https://blog.stocksherpa.in">Click here</a>
                                                </div>
                                            </div>
                                            <!-- <div class="item">
                                                <div class="content-stt">
                                                    <h3>How safe are stock advisory's?</h3>
                                                    <p>Stock Advisory's are all the rage, but in a market fraught with risk,
                                                        how
                                                        does one pick the best anonymous support system? We take a closer
                                                        look.
                                                    </p>
                                                    <a href="">Click here</a>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-none d-sm-block dviewteam">
                <div class="teamhead">
                    <h2><span>OUR</span>Team</h2>
                </div>
                <div class="row mrb15">
                    <div class="col-12">
                        <div class="teamdtls">
                            <p><span class="hmdtlsbold">STOCK</span>Sherpa was conceptualized by a small
                                passionate team of entrepreneurs, who
                                have
                                for
                                long been 'Sherpas' guiding their relevant industries towards excellence.<br>The team includes a Phd
                                in
                                artificial intelligence, an ethical hacker and a serial entrepreneur working on exponential
                                technologies,
                                all bonded by their non-conformist attitudes and zeal to breach barriers!</p>
                        </div>
                    </div>
                </div>
                <div class="vission-div text-center">
                    <h3>Vision</h3>
                    <p>To empower a million to achieve financial freedom on the Indian stock market <br> with
                        sophisticated Ai stock hack, the <span class="hmdtlsbold">STOCK</span>Sherpa!</p>
                </div>


                <div class="row rmgn" style="margin-top: 20px;">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 ">
                        <div class="row">
                            <div class="col-4">
                                <div class="team-image-div">
                                    <div class="team-image" style="text-align: center;">
                                        <img style="border-radius:10px;" src="../../../assets/images/profile_pic.jpeg">
                                        <ul class="social-links">
                                            <li><a href="https://twitter.com/vivek0210?s=08"><i style="color: #4267B2;" class="fa fa-twitter-square"
                                                        aria-hidden="true"></i></a></li>
                                            <li><a href="https://linkedin.com/in/vivekmadappa"><i style="color: #0077b5;"
                                                        class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                            <li><a href="https://www.instagram.com/vivek0210">
                                                    <img style="width: 25px;height: 25px;"
                                                        src="../../assets/images/instagram.png" aria-hidden="true">
                                                    <!-- <i class="fa fa-instagram instagram" style="border-radius: 12px;" aria-hidden="true"></i> -->
                                                </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8" style="padding-left: 0;">
                                <div class="team-content-div">
                                    <div class="team-content">
    
                                        <p style="margin: 0 20px 0 0;">Vivek Madappa<br>As <span class="hmdtlsbold">STOCK</span>Sherpa
                                            founder, Vivek aims to empower stock market investors with a cutting-edge technology aided solution that
                                                removes worry and increases gains.
                                                He
                                                brings forth learnings from his over 3 decade career as a marketing /
                                                management
                                                expert and as a serial entrepreneur that combines his passion for technology
                                                to
                                                decode opportunities. 
                                        </p>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 blog-slider">
                        <h2 class="title" style="border-bottom:none;margin:0px;padding-bottom:0px;padding-top: 0;"><span>SHERPAS</span>Blog
                        </h2>
                        <div class="row">
                            <div class="col-5  blog-image">
                            </div>
                            <div class="col-7" style="padding: 0;">
                                <div class="blog-st-div">
                                    <div class="blog-content">
                                        <div class="owl-carousel owl-theme" id="blog_slider1">
                                            <div class="item">
                                                <div class="content-stt">
                                                    <h3>Journey of Stocksherpa</h3>
                                                    <p>
                                                        The year was 1992 and I was a young management graduate trying to manage a high-pressure career and survive in an expensive city like Mumbai
                                                    </p>
                                                    <a href="https://blog.stocksherpa.in">Click here</a>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid faq-stt faqbg"  id="faq">
        <div class="products">
          
            <div class="d-block d-sm-none">
                <h2><span>YOUR</span>FAQ's</h2>
                <p class="text-center att">As part of a path-breaking community, you are bound to have questions about what
                    exactly makes <span class="hmdtlsbold">STOCK</span>Sherpa one of it's kind to achieve financial freedom
                    with cutting edge Ai hack of the
                    Indian stock market. <br>Our FAQ section keeps updated with answers to every single question you may
                    ever
                    have. Read on-</p>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4  offset-md-4 offset-lg-4 offset-sm-4">
                        <div class="faq-qt">
                            <div class="owl-carousel owl-theme" id="faq_slider">
                                <div class="item">
                                    <div class="faq-content-tt">
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal1">Who is
                                                    <br><span class="hmdtlsbold">STOCK</span>Sherpa?</a></h3>
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal2">What is MoonShot
                                                    stocks?</a></h3>
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal3">What is <br>OKTO?</a>
                                            </h3>
                                        </div>

                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal4">Why 21 <br>Stock
                                                    ratios?</a></h3>
                                        </div>
                                        <div class="faq-content-st" style="border: none;background:none">
    
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal5">Difference between
                                                    screeners/ stock
                                                    tickers?</a></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="faq-content-tt">
    
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal6">Is <span
                                                        class="hmdtlsbold">STOCK</span>Sherpa a
                                                    stock
                                                    advisor?</a></h3>
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal7">Is <span
                                                        class="hmdtlsbold">STOCK</span>Sherpa for
                                                    long
                                                    term
                                                    investors?</a></h3>
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal8">Annual Or
                                                    monthly
                                                    subscription?</a>
                                            </h3>
                                        </div>
    
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal10">What is
                                                    <b>OKTO</b>map?
                                                </a>
                                            </h3>
                                        </div>
                                        <div class="faq-content-st" style="border: none;background:none">
    
                                        </div>
                                        <div class="faq-content-st">
                                            <h3><a href="" data-toggle="modal" data-target="#myModal11">Is stock investing
                                                    risky?</a></h3>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
    
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="d-none d-sm-block dviewfaq">
                <div class="faqimgdiv" >
                    <img src="../../assets/images/faq-bg-dview.png" alt="">
                </div>
                <h2><span>YOUR</span>FAQ's</h2>
                <div class="row">

                    <div class="col-12 col-sm-5 col-md-4 col-lg-4 offset-lg-2 offset-md-2 offset-sm-2 ">
                        <p class="att">As part of a path-breaking community, you are bound to have questions about what
                            exactly makes <span class="hmdtlsbold">STOCK</span>Sherpa one of it's kind to achieve financial freedom
                            with cutting edge Ai hack of the
                            Indian stock market. <br>Our FAQ section keeps updated with answers to every single question you may
                            ever
                            have. Read on-</p>
                    </div>

                    <div class="col-12 col-sm-5 col-md-6 col-lg-6">
                        <div class="owl-carousel owl-theme" id="faql">
                            <div class="item">
                                <div class="faq-content-st">
                                    <h3><a href="" data-toggle="modal" data-target="#myModal1">Who is
                                            <br><span class="hmdtlsbold">STOCK</span>Sherpa?</a></h3>
                                </div>
                                <div class="faq-content-st">
                                    <h3><a href="" data-toggle="modal" data-target="#myModal2">What is MoonShot
                                            stocks?</a></h3>
                                </div>
                                <div class="faq-content-st">
                                    <h3><a href="" data-toggle="modal" data-target="#myModal3">What is <br>OKTO?</a>
                                    </h3>
                                </div>							
                            </div>
                    <div class="item">
                        <div class="faq-content-st">
                            <h3><a href="" data-toggle="modal" data-target="#myModal4">Why 21 <br>Stock
                                    ratios?</a></h3>
                        </div> 
                        <div class="faq-content-st">
                            <h3><a href="" data-toggle="modal" data-target="#myModal5">Difference between
                                    screeners/ stock
                                    tickers?</a></h3>
                        </div>
                        <div class="faq-content-st">
                            <h3><a href="" data-toggle="modal" data-target="#myModal6">Is <span
                                        class="hmdtlsbold">STOCK</span>Sherpa a
                                    stock
                                    advisor?</a></h3>
                        </div>
                            </div>
                         <div class="item">
                            <div class="faq-content-st">
                                <h3><a href="" data-toggle="modal" data-target="#myModal7">Is <span
                                            class="hmdtlsbold">STOCK</span>Sherpa for
                                        long
                                        term
                                        investors?</a></h3>
                            </div>
                            <div class="faq-content-st">
                                <h3><a href="" data-toggle="modal" data-target="#myModal8">Annual Or
                                        monthly
                                        subscription?</a>
                                </h3>
                            </div>

                            <div class="faq-content-st">
                                <h3><a href="" data-toggle="modal" data-target="#myModal10">What is
                                        <b>OKTO</b>map?
                                    </a>
                                </h3>
                            </div>
                             
                         </div>
                         <div class="item">
                            <div class="faq-content-st">
                                <h3><a href="" data-toggle="modal" data-target="#myModal11">Is stock investing
                                        risky?</a></h3>
                            </div>
                         </div>
                          
                        </div>
                    </div>

                </div>

            </div>



        </div>
    </div>



    <div class="container-fluid contact contact-us-details"  id="contact">

        <div class="d-block d-sm-none">
            <div class="container">
                <h2><span>CONTACT</span>Us</h2>
            </div>
            <div class="container">
                <p class="text-left"><b>STOCK</b>Sherpa would love to guide you towards achieving your peak potential in
                    high growth
                    Investing. Reach out to us to know how!</p>
                <div class="call-action tt">
                    <h3>Mail us at</h3>
                    <a href="mailto:info@stocksherpa.i">info@stocksherpa.in</a><br>
                    <a href="mailto:feedback@stocksherpa.i">feedback@stocksherpa.in</a>
                </div>
                <div class="call-action tt">
                    <h3>Follow us at</h3>
                    <span>stocksherpa@</span>
                    <ul>
                        <li><a href="https://facebook.com/stocksherpa"><i style="color: #4267B2;font-size: 32px;" class="fa fa-facebook-square"
                            aria-hidden="true"></i></a></li>
                        <li><a href="https://twitter.com/stocksherpa"><i style="color: #4267B2;font-size: 32px;" class="fa fa-twitter-square"
                                    aria-hidden="true"></i></a></li>
                        <li><a href="https://linkedin.com/stocksherpa"><i style="color: #0077b5;font-size: 32px;" class="fa fa-linkedin-square"
                                    aria-hidden="true"></i></a></li>
                        <li><a href="https://instagram.com/stocksherpa?utm_medium=copy_link">
                                <img style="width: 32px;height: 32px;" src="../../assets/images/instagram.png"
                                    aria-hidden="true">
                                <!-- <i class="fa fa-instagram instagram" style="font-size:31px;border-radius:15px;" aria-hidden="true"></i> -->
                            </a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="d-none d-sm-block dviewcontact">        
          <div class="pad-div add">
            <h2><span>CONTACT</span>Us</h2>
                <p>STOCKSherpa would love to guide you towards achieving your <br>peak potential in high growth Investing. Reach out to us to know how!</p>
                <ul class="address-fields-div"> 
                    <li>
                        <div class="call-action tt">
                            <h3>Mail us at</h3>
                            <a href="mailto:info@stocksherpa.i">info@stocksherpa.in</a><br>
                            <a href="mailto:feedback@stocksherpa.i">feedback@stocksherpa.in</a>
                        </div>
                    </li>
                        <li class="bstyle"></li>
                    <li>
                        <div class="call-action tt">
                            <h3>Follow us at</h3>
                            <span>stocksherpa@</span><br>
                            <ul>
                                <li><a href="https://facebook.com/stocksherpa"><i style="color: #4267B2;font-size: 36px;" class="fa fa-facebook-square"
                                    aria-hidden="true"></i></a></li>
                                <li><a href="https://twitter.com/stocksherpa"><i style="color: #4267B2;font-size: 36px;" class="fa fa-twitter-square"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="https://linkedin.com/stocksherpa"><i style="color: #0077b5;font-size: 36px;" class="fa fa-linkedin-square"
                                            aria-hidden="true"></i></a></li>
                                <li><a href="https://instagram.com/stocksherpa">
                                        <img style="width: 36px;height: 36px;vertical-align: sub;" src="../../assets/images/instagram.png"
                                            aria-hidden="true">
                                       </a></li>
                            </ul>
                        </div>
                    </li>
                </ul>	
            </div>

        </div>

    </div>

    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <p><img class="whowhatpimg" src="../../assets/images/who_light_colour.png"></p>

                    <p><span class="hmdtlsbold">STOCK</span>Sherpa is for you, whether you are a working professional,
                        millennial, a beginner or a
                        long-term growth investor. <br>The quantitative analysis & benchmarks offered by your Ai stock guide
                        ensures you don’t put a foot wrong in your quest for financial freedom while hiking up the
                        Indian stock market, to find Moonshot stocks!</p>
                    <div class="stcoksherpa-div">
                        <h3>The biggest challenge!</h3>
                        <p>The Indian stock market has over 4000+ stocks listed. The challenge for you as an investor,
                            would be to analyse and pick stocks that offer the highest return on investment & growth at
                            lowest risk.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="myModalwhat" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <p><img class="whowhatpimg" src="../../assets/images/what_light_colour.png"></p>

                    <p>In a world of uncertainty and rapid change, stock investing as we know and the maps we have, are
                        no longer relevant. <br>With sophisticated Ai algorithms, <span
                            class="hmdtlsbold">STOCK</span>Sherpa guides you to effortlessly
                        hack the stock market and find Moonshot stocks (10x)</p>
                    <div class="stcoksherpa-div">
                        <h3><span class="hmdtlsbold">STOCK</span>Sherpa’s USP!</h3>
                        <p>When you receive stock recommendations from brokers or want to evaluate your current
                            portfolio, use <span class="hmdtlsbold">STOCK</span>Sherpa’s sophisticated Ai tool OKTO to
                            effortlessly and quantitatively
                            validate your decision in 30 seconds!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal1" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Who is <br><span class="hmdtlsbold">STOCK</span>Sherpa?</h3>
                    <p><span class="hmdtlsbold">STOCK</span>Sherpa is your Ai stock guide to financial freedom on the Indian
                        stock market. </p>
                    <p><span class="hmdtlsbold">STOCK</span>Sherpa's platform uses sophisticated Ai to analyse stocks
                        using 21 stock ratios. You now
                        have the power of Ai and big data at your fingertips to confidently & accurately analyse any
                        stock in just <span style="text-decoration: underline;font-weight:bold;">30 seconds</span>. </p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal2" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>What is <br>MoonShot stocks?</h3>
                    <p>Moonshot stocks offer the highest return on investment and growth with the lowest risk.</p>
                    <p>Moonshot stocks usually offer any investor, between 2 to 10 times return on investment over a 2
                        years horizon.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal3" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>What is <br>OKTO? </h3>
                    <p><span class="hmdtlsbold">STOCK</span>Sherpa’s sophisticated Ai algorithms to hack the Indian
                        stock market with <b>“21 Stock
                            Ratios”</b> is called OKTO. </p>
                    <p>OKTO uses the maximum number of stock ratios to identify Moonshot stocks that consistently out
                        perform the market.</p>
                    <div style="text-align: left;color: #000;font-family:AvantGarGotltcTEE;font-weight:normal;">They
                        include</div>
                    <div>
                        <ol
                            style="font-family:AvantGarGotltcTEE;text-align: left;color: #000;list-style-type:lower-alpha">
                            <li>9 Fundamental ratios</li>
                            <li>4 Valuation ratios</li>
                            <li>8 MoonShot ratios (Future)</li>
                        </ol>
                    </div>
                    <!--<img class="img-responsive" alt="" src="images/flowchart.png">-->
                </div>
            </div>
        </div>
    </div>
    <div id="myModal4" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Why <br>21 Stock ratios?</h3>
                    <p>The best way to measure the financial health of stocks is by analysing financial data from P&L,
                        balance sheet and cash flow statements. Only Ai can compute such large volumes of data over a 5
                        years period.</p>
                    <p>The Indian stock market has 4000+ shares listed. Only 2% of the stocks are fundamentally strong
                        and worth investing. <span class="hmdtlsbold">STOCK</span>Sherpa is the only Ai platform that
                        uses 21 financial ratios to
                        analyse stocks. Most stock analysts, websites or stock brokers use only 6 ratios.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal5" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>What is the<br> difference between <br>screeners, stock tickers?</h3>
                    <p>Screeners and stock tickers are excellent for financial data. However the analysis takes time and
                        effort. </p>
                    <p style="text-align: left;">With <span class="hmdtlsbold">STOCK</span>Sherpa you can outsource the
                        time and effort required to
                        analyse stocks to a sophisticated Ai platform that can transform even an inexperienced investor
                        into a tech savvy high growth investor who will consistently outperform the market.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal6" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Is <span class="hmdtlsbold">STOCK</span>Sherpa <br> a stock advisor?</h3>
                    <p>No. <span class="hmdtlsbold">STOCK</span>Sherpa does not advise or recommend stocks. </p>
                    <p>In the digital world of quantum computing its smarter for high growth investors to save time &
                        effort by leveraging big data analytics and Ai to analyse stocks.</p>
                    <p>Use
                        <span class="hmdtlsbold">STOCK</span>Sherpa to evaluate your current portfolio or re-validate
                        your brokers recommendations. Your
                        portfolio is your responsibility, not your brokers or analysts. So use Ai to gain unfair
                        advantage in the stock market!!
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal7" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Is <span class="hmdtlsbold">STOCK</span>Sherpa <br> for long term investors?</h3>
                    <p>YES.</p>
                    <p><span class="hmdtlsbold">STOCK</span>Sherpa is only for high growth long term investors, not for
                        day traders, speculators and
                        short-term investors. </p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal8" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Free, <br>Annual or monthly subscription?</h3>
                    <p>For free registration: <b>sign up</b></p>
                    <p> As a FREE subscriber you can access the <span class="hmdtlsbold">STOCK</span>Sherpa dashboard.
                        You will receive a free copy of <b>OKTO</b>map : Part 1</p>
                    <!-- <p>If you wish to access unlimited features like stock analysis, industry analysis, Top 100 stocks and stock comparison across all the 21 stock ratios; you will need to subscribe. By subscribing you become an elite member of a growing tribe of stock investors who use Ai.</p> -->
                    <p>If you have less than 8 stocks in your portfolio or an investment of less than Rs.2 lacs in
                        stocks, consider monthly subscription. Investors who have more than 10 stocks and over Rs.2 lacs
                        investment will need Annual subscription.</p>

                </div>
            </div>
        </div>
    </div>
    <div id="myModal9" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Do you have a FREE <br>subscription?</h3>
                    <p>YES.</p>
                    <p>For free registration: <b>sign in</b></p>
                    <p>If you wish to access unlimited features like stock analysis, industry analysis, Top 100 stocks
                        and stock comparison across all the 21 stock ratios; you will need to subscribe. By subscribing
                        you become an elite member of a growing tribe of stock investors who use Ai.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal10" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>What is <br><b>OKTO</b>map? </h3>
                    <p><span class="hmdtlsbold">STOCK</span>Sherpa compass to find Moonshot stocks is called
                        <b>OKTO</b>map.</p>
                    <p><b>OKTO</b>map’s short, easy to read reference notes contains investing hacks & strategies for
                        high
                        growth investing.</p>
                    <p><b>For Free subscribers:</b><br>
                        <b>OKTO</b>map Part 1 - Simple HACKS to consistently beat stock market experts with Ai.
                    </p>
                    <p><b>For Paid subscribers: </b><br>
                        <b>OKTO</b>map Part 2 - Strategies for high growth investing to achieve financial freedom.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal11" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <h3>Is stock <br>investing risky?</h3>
                    <p>Day trading, investing on stocks based on tips, short term or speculating in stocks are extremely
                        risky. 95% of the people indulging in short-term buying and selling lose money.</p>
                    <p>Wealth creation is a marathon (long term). Refer <b>Okto</b>map for details.</p>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal12" class="modal fade faq-popup" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <div class="logo-section">
                        <p style="font-size: 15px;">OKTO Score is 21 stock ratio analysis</p>
                        <a href="./"><img src="../../assets/images/011.png"></a>
                    </div>
                    <section class="ratios">
                        <div class="ratio1">
                            <h6>FUNDAMENTAL BULLET PROOF RATIOS</h6>
                            <ul>
                                <li>MARKET <br>CAPITALIZATION</li>
                                <li>PROFITABILITY <br>RATIOS</li>
                                <li>EPS</li>
                                <li>SOLVENCY <br>RATIOS</li>
                                <li>ROCE RATIO</li>
                                <li>SALES <br>RATIOS</li>
                                <li>ROE RATIO</li>
                                <li style="background: #63bbe1;">LIQUIDITY <br>RATIOS</li>

                            </ul>
                        </div>
                        <div class="ratio1 ratio2">
                            <h6>VALUATION RATIOS</h6>
                            <ul>
                                <li>PE <br>RATIOS</li>
                                <li>P/S <br>RATIOS</li>
                                <li>P/BV <br>RATIOS</li>
                                <li>P/FCF <br>RATIOS</li>
                            </ul>
                        </div>
                        <div class="ratio1 ratio3">
                            <h6>MOONSHOT RATIOS</h6>
                            <ul>
                                <li>INTRINSIC <br>VALUE</li>
                                <li>CROIC</li>
                                <li>PEG <br>RATIO</li>
                                <li>FCF/ <br>SALES</li>
                                <li>PROMOTERS <br>HOLDING</li>
                                <li>PLEDGED<br>SHARES</li>
                                <li>PIOTROSKI <br>SCORE</li>
                                <li style="background: #63bbe1;">ALTMAN Z <br>SCORE</li>
                            </ul>
                        </div>
                        <a class="bth" data-dismiss="modal">Return to Home</a>
                    </section>
                </div>
            </div>
        </div>
    </div>

</div>


<div id="myModaln2" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln2" aria-hidden="true">
    <div class="modal-dialog">
      
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
         <div style="text-align: center;padding: 30px 0;">
          <span style="font-size: 16px;font-weight: bold; color: #fff;">
            Registration Successful. Activation link is sent to your email
           </span>
         </div>
      
        </div>
      </div>
      </div>
  </div>  