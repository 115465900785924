<div class="nd-content">
    <div id="nd-hme-mobile" style="margin-top: 78px;"><a href="#"><img src="../../../assets/images/logo.png"></a></div>
    <div id="nd-hme-desktop"><a href="#"><img src="../../../assets/images/01-full.png"></a></div>
    <div class="nd-login-home"><button class="btn navbar-toggler" type="button" style="padding: .25rem 0.70rem;"><span style="padding: 0 20px 0 0;cursor: pointer;font-size: 1rem;">HOME</span><span class="navbar-toggler-icon" style="width: auto;height: auto;"><i class="fa fa-home" style="font-size: 36px!important;color: #fff;"></i></span></button></div>
</div>
<div class="container-fluid">
    <div class="nd-static-content">
        <h1><strong>Terms of Service</strong><br></h1>
    </div>
    <div style="padding: 0 5%;">
        <p>Last updated Aug 31, 2021<br></p>
        <h2>Welcome to stocksherpa.in<br></h2>
        <p>Thanks for using our products and services ("Services"). The Services are provided by Octoban Technology Private Limited through its network of websites and domains at stocksherpa.in ("Site").<br></p>
        <p>By using our Services, you are agreeing to these terms. Please read them carefully.<br></p>
        <h2>Terms<br></h2>
        <p>By accessing the Site, you are agreeing to be bound by our terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.<br></p>
        <h2>Service License<br></h2>
        <p>a. Permission is granted to use the materials (information or software) on Site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:<br></p>
        <ul>
            <li>modify or copy the materials;<br></li>
            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);<br></li>
            <li>attempt to decompile or reverse engineer any software contained on Site;<br></li>
            <li>attempt to decompile or reverse engineer any software contained on Site;<br></li>
            <li>transfer the materials to another person or "mirror" the materials on any other server.<br></li>
        </ul>
        <p>b. This license shall automatically terminate if you violate any of these restrictions and may be terminated by us at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.<br></p>
        <h2>Disclaimer and Warranties<br></h2>
        <p>The materials on Site are provided on an 'as is' basis. We makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.<br></p>
        <p>The data feed for the website is sourced and curated using automated algorithms from various data sources. The comments and opinions are based on automated formulas. These are prone to errors of automation. We are not responsible for the accuracy, reliability, performance, completeness, comprehensiveness, currentness, functionality, continuity, timeliness of the information, data or for any content available.<br></p>
        <p>Though we make the best efforts for accuracy; the materials appearing on Site could include technical, typographical, or photographic errors. We do not warrant that any of the materials on the Site are accurate, complete or current. We may make changes to the materials contained on Site at any time without notice. However we do not make any commitment to update the materials.<br></p>
        <p>Further, we do not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.<br></p>
        <h2>Liability for our Services<br></h2>
        <p>WE, OCTOBAN TECHNOLOGY PRIVATE LIMITED, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES.<br></p>
        <p>THE TOTAL LIABILITY OF SITE FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT THAT YOU PAID US TO USE THE SERVICES.<br></p>
        <p>IN ALL CASES, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT ARISES FROM THE USAGE OF THE SITE.<br></p>
        <h2>Links<br></h2>
        <p>We have not reviewed all of the links present on the Site and are not responsible for the contents of any such linked website. The inclusion of any link does not imply our endorsement. Use of any such linked website is at the user's own risk.<br></p>
        <h2>Modifications<br></h2>
        <p>These terms of service along with its supplements may be updated from time to time. We will update the last updated date to alert about the changes. It is your responsibility to periodically review these changes and stay informed about the updates. By using this website you are agreeing to be bound by the then current version of these terms of service.<br></p>
        <h2>Governing Law<br></h2>
        <p>These terms and conditions are governed by and construed in accordance with the laws of India and you irrevocably submit to the exclusive jurisdiction of the courts in Bengaluru, Karnataka.<br></p>
        <h2>Contact Us<br></h2>
        <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:<br></p>
        <p>OCTOBAN TECHNOLOGY PRIVATE LIMITED<br>No 402, VDB CARDINAL, 6TH MAIN,<br>INDIRANAGAR, BENGALURU, KARNATAKA,<br>560038<br>info@stocksherpa.in<br></p>
    </div>
</div>
