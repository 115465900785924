<div class="contact-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-12">
        <p style="text-align: center;margin-bottom: 0;">All rights reserved@STOCKSherpa 2021</p>
        </div>
    </div>
  </div>
</div>
 
 
