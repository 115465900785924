import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';   
import { AuthlayoutComponent } from './layout/authlayout/authlayout.component';
import { FreelayoutComponent } from './layout/freelayout/freelayout.component'; 
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { LpComponent } from './lp/lp.component'; 
import { AuthGuard } from './_guards/index';
import { PaymentsuccessComponent } from './paymentsuccess/paymentsuccess.component';
import { PaymentfailureComponent } from './paymentfailure/paymentfailure.component'; 
import { Error404Component } from './error404/error404.component';
import { UserpasswordComponent } from './userpassword/userpassword.component'; 
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { FreeDashboardComponent } from './free-dashboard/free-dashboard.component';
import { FreeCompareComponent } from './free-compare/free-compare.component';
import { FreeByindustryComponent } from './free-byindustry/free-byindustry.component';
import { FreeBymarketComponent } from './free-bymarket/free-bymarket.component';
import { FreeTop100Component } from './free-top100/free-top100.component';
import { PaidDashboardComponent } from './paid-dashboard/paid-dashboard.component';
import { PaidCompareComponent } from './paid-compare/paid-compare.component';
import { PaidByindustryComponent } from './paid-byindustry/paid-byindustry.component';
import { PaidBymarketComponent } from './paid-bymarket/paid-bymarket.component';
import { PaidTop100Component } from './paid-top100/paid-top100.component';

import { PaidlayoutComponent } from './layout/paidlayout/paidlayout.component';
import { FreeMyaccountComponent } from './free-myaccount/free-myaccount.component';
import { PaidMyaccountComponent } from './paid-myaccount/paid-myaccount.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsandConditionsComponent } from './termsand-conditions/termsand-conditions.component';
import { CancellationpolicyComponent } from './cancellationpolicy/cancellationpolicy.component';

import { NdLayoutComponent } from './nd-new-design/nd-layout/nd-layout/nd-layout.component';
import { NdLpComponent } from './nd-new-design/nd-lp/nd-lp.component';
import { NdAboutusComponent } from './nd-new-design/nd-aboutus/nd-aboutus.component';
import { NdFaqComponent } from './nd-new-design/nd-faq/nd-faq.component';
import { NdContactusComponent } from './nd-new-design/nd-contactus/nd-contactus.component';
import { NdPrivacyPolicyComponent } from './nd-new-design/nd-privacy-policy/nd-privacy-policy.component';
import { NdTermsAndConditionsComponent } from './nd-new-design/nd-terms-and-conditions/nd-terms-and-conditions.component';
import { NdCancellationPolicyComponent } from './nd-new-design/nd-cancellation-policy/nd-cancellation-policy.component';
import {NdDownloadoktomapComponent} from './nd-new-design/nd-downloadoktomap/nd-downloadoktomap.component'
const routes: Routes = [
  // {
  //   path: '',
  //   component: AuthlayoutComponent,
  //   children: [
  //     { path: '', component: LpComponent, pathMatch: 'full' }, 
      
  //   ]
  // },
  {
    path: '',
    component: NdLayoutComponent,
    children: [
      { path: '', component: NdLpComponent, pathMatch: 'full' }, 
      { path: 'about-us', component: NdAboutusComponent, pathMatch: 'full' }, 
      { path: 'faq', component: NdFaqComponent, pathMatch: 'full' }, 
      { path: 'contact-us', component: NdContactusComponent, pathMatch: 'full' }, 
      { path: 'oktomap-download', component: NdDownloadoktomapComponent, pathMatch: 'full' }, 
      { path: 'CancellationPolicy', component: NdCancellationPolicyComponent, pathMatch: 'full' }, 
      { path: 'TermsandConditions', component: NdTermsAndConditionsComponent, pathMatch: 'full' }, 
      { path: 'PrivacyPolicy', component: NdPrivacyPolicyComponent, pathMatch: 'full' }, 

      
    ]
  },
  {
    path: 'login', 
    component: LoginComponent
  },
  {
    path: 'signup', 
    component: SignupComponent
  },
  // {
  //   path: 'PrivacyPolicy', 
  //   component: PrivacyPolicyComponent
  // },
  // {
  //   path: 'CancellationPolicy', 
  //   component: CancellationpolicyComponent
  // },
  // {
  //   path: 'TermsandConditions', 
  //   component: TermsandConditionsComponent
  // },
  {
    path: 'activation/:id', 
    component: UserpasswordComponent
  },
  {
    path: 'resetpassword/:id', 
    component: ForgotpasswordComponent
  },
  {
    path: '',
    component: FreelayoutComponent,
    children: [
      { path: 'free-dashboard', component: FreeDashboardComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'free-compare', component: FreeCompareComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'free-byindustry', component: FreeByindustryComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'free-bymarket', component: FreeBymarketComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'free-toprank', component: FreeTop100Component, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'free-myaccount', component: FreeMyaccountComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'myportfolio', component: PortfolioComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
    ]
  }, 
  {
    path: '',
    component: PaidlayoutComponent,
    children: [
      { path: 'premium-dashboard', component: PaidDashboardComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'premium-compare', component: PaidCompareComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'premiumby-industry', component: PaidByindustryComponent, pathMatch: 'full',canActivate: [AuthGuard] },
      { path: 'premiumby-market', component: PaidBymarketComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'premiumtop-rank', component: PaidTop100Component, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'premium-myaccount', component: PaidMyaccountComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
      { path: 'premium-myportfolio', component: PortfolioComponent, pathMatch: 'full',canActivate: [AuthGuard] }, 
    ]
  },
  {
    path: 'payment-success', 
    component: PaymentsuccessComponent
  },
  {
    path: 'payment-failure', 
    component: PaymentfailureComponent
  },
  {
    path: "404",
    component: Error404Component
  },
  {
    path: "**",
    redirectTo: "404"

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export var AppRoutes = routes;