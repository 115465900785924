<ng2-toasty [position]="'center-center'"></ng2-toasty>

<div class="container-fluid" id="pglogin">
  <div class="row d-none d-sm-block">
      <a><img src="../../../assets/images/01-full.png" class="img-responsive"></a>
  </div>
  <div class="row d-block d-sm-none">
      <a><img src="../../../assets/images/logo.png" class="img-responsive"></a>
  </div> 
 
  <div class="login-home">
    <button class="navbar-toggler" type="button" [routerLink]="['/']" style="padding: .25rem 0.70rem" >
      <span class="login" style="padding: 0 20px 0 0; cursor: pointer;font-size: 1rem;" >HOME</span> <span class="navbar-toggler-icon" style="width: auto; height: auto;"> <i style="color: #fff;font-size: 40px;" class="fa fa-home"></i></span>
      </button>
  </div>
 
  <div class="row logindtls">
    <div class="col-12 col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <div class="login-form">
        <div class="form-section">
          <div style="text-align: center; margin-top: -25px;">
            <h1 style="display: inline-block; font-size: 48px; padding-bottom: 0; margin-bottom: 0;"><span>Stock</span>Sherpa</h1>
            </div>

          
            <h2 style="margin:10px 0 0 0;font-size: 28px;">Your Ai Stock Guide</h2>
            <form> 
              <p style="text-align: center;font-size: 21px; padding-bottom: 0;margin-top: 1px;margin-bottom: 1px;">Welcome to account activation</p>
              <p style="text-align: left;margin-bottom: 15px;">Email: <b>{{PageLoadEmail}}</b> </p>
              <input type="password" class="form-control" name="password" placeholder="Password"  [(ngModel)]="password">
              <input type="password" class="form-control" name="confirmpassword" placeholder="Confirm Password" [(ngModel)]="confirmpassword"> 
              <input type="submit" name="submit" value="Activate" style="cursor: pointer;"  (click)="generate()"  class="click_btn add">			 
            </form> 
           
        </div>
    </div>
    </div>
  </div>  

  <br />
</div>
 



<div id="myModaldash5" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none;">
       
      </div>
      <div class="modal-body">
      <div>
        <p style="color: #f58220;font-weight: 700;">Account Verified </p>
        <p style="color: #fff;font-weight: 700;">You've Successfully verified your account <br> <b>{{UserEmail}}</b></p>
        <div class="btns"><a href="javascript:void(0,0)" data-dismiss="modal" [routerLink]="['/login']" class="click_btn"><span>Login</span></a></div>
        </div>
        <div>
        <img src="../../assets/images/dash5.png" style="border-bottom-left-radius: 50px;border-bottom-right-radius: 50px;">
        </div>
      </div>
    </div> 
    </div>
</div>