<ng2-toasty [position]="'bottom-right'"></ng2-toasty>
<div id="nd-content">
    <div id="nd-hme-mobile"><a href="#"><img src="./../../assets/images/logo.png"></a></div>
    <div id="nd-hme-desktop"><a href="#"><img src="./../../assets/images/01-full.png"></a></div>
    <div class="container" id="nd-hme-content">
        <div id="nd-hme-content">
            <h1><span>STOCK</span>Sherpa</h1>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
                    <p>Using AI to identify multi-bagger stocks for long term wealth creation.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div id="nd-summit">
            <p>How we get you to the stock summit!</p>
        </div>
        <div id="nd-summit-content" class="row">
            <div class="col-4"><img src="./../../assets/images/1brain.svg">
                <h1>AI Guide</h1>
                <p>Analyze any stock in  5 seconds. Identify stocks for creating wealth.<br></p>
            </div>
            <div class="col-4"><img src="./../../assets/images/2chart.svg" style="cursor: pointer;" data-toggle="modal" data-target="#myModaln1">
                <h1 data-toggle="modal" data-target="#myModaln1" style="cursor: pointer;">Free Download</h1>
                <p data-toggle="modal" data-target="#myModaln1" style="cursor: pointer;">Hacking 21 financial ratios to identify multi-baggers.<br></p>
            </div>
            <div class="col-4"><img src="./../../assets/images/3moon.svg">
                <h1>Wealth Creation</h1>
                <p>This sophisticated stock analysis platform is only for long term investors (not traders).<br></p>
            </div>
        </div>
        <div id="nd-free-signup"><a class="click_btn" (click)="signup1('Free')" style="cursor: pointer;">Try it for FREE now!<br></a>
            <p>Start climbing with us with our FREE trial package!</p>
        </div>
    </div>
</div>
<div id="nd-content-sec" class="nd-content-sec-mobile">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row">
                <div class="col-5 col-sm-5 col-md-5 col-lg-5" style="justify-content: center;align-content: center;flex-direction: column;display: flex;">
                    <p class="nd-video-content">Watch this clip to know how STOCKSherpa works in just 30 seconds!</p>
                </div>
                <div id="nd-video-img" class="col-7 col-sm-7 col-md-7 col-lg-7"><a class="popup-youtube" href="https://www.youtube.com/watch?v=QXzTMdghf8Y"><img src="./../../assets/images/player.svg"><i class="fa fa-play" id="fa-pay-1"></i></a></div>
                <div id="nd-remove-padd" class="col-12">
                    <div id="nd-climb">
                        <h1>Alright you stock trekker, lets start the climb!</h1>
                        <h2>The STOCKSherpa subscription includes- </h2>
                        <p><i class="fa fa-check"></i>&nbsp;Smart in-depth Ai analysis in just 30 seconds!</p>
                        <p><i class="fa fa-check"></i>&nbsp;Top 100 Moonshot stocks identified by Ai</p>
                        <p style="text-indent: -10px;margin-left: 20px;"><i class="fa fa-check"></i>&nbsp;List of best performing Platform, EV, IT engineering and exponential technology stocks</p>
                        <p><i class="fa fa-check"></i>&nbsp;Future wealth creators across Market cap</p>
                        <p><i class="fa fa-check"></i>&nbsp;In-depth comparisons of upto 5 stocks at a time!</p>
                        <p><i class="fa fa-check"></i>&nbsp;Detailed financial and SWOT analysis</p>
                        <p><i class="fa fa-check"></i>&nbsp;Winning advice that’s easy to understand</p>
                        <p><i class="fa fa-check"></i>&nbsp;Access to a growing bank of stock trekking tutorials</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="nd-Subscription">
        <div class="row">
            <div id="nd-left-div" class="col-6" style="display: none;">
                <h1>Monthly Subscription</h1>
                <p>Let STOCKSherpa guide you through tricky stock terrain for just a month</p>
            </div>
            <div id="nd-right-div" class="col-12">
                
                <p>Get STOCKSherpa to analyse any stock in 30 seconds and find you the best weath creating stocks</p>
            </div>
        </div>
    </div>
    <div id="nd-Subscription-price">
        <div class="row">
            <div id="nd-left-div-1" class="col-6" style="display: none;">
                <h1 style="display: none;"><br></h1>
                <h1><strong>Rs.201/-* Only</strong><br></h1>
                <div class="nd-sub-button"><a href="#">Sign up now!</a></div>
                <p>*GST + Taxes extra</p>
            </div>
            <div id="nd-right-div-1" class="col-12">
                <h1 style="display: none;"><span>Only</span><strong>&nbsp;Rs.201/-*&nbsp;</strong><span style="font-size: 12px;">&nbsp;Per Month</span></h1>
                <h1 class="nd-stricke-line" style="display: none;"><strong>Rs.501/-* Only</strong><br></h1>
                <div class="nd-sub-button"><a (click)="signup1('Yearly')" style="cursor: pointer;">Sign up now!</a></div>
                <p style="display: none;">* Annual subscription</p>
            </div>
        </div>
    </div>
</div>
<div id="nd-content-sec-1" class="nd-content-sec-desktop">
    <div class="row">
        <div class="col-6 nd-lftdiv">
            <div class="row nd-add-padd">
                <div class="col-5 col-sm-5 col-md-5 col-lg-5" style="justify-content: center;align-content: center;flex-direction: column;display: flex;">
                    <p class="nd-video-content">Watch this clip to know how STOCKSherpa works in just 30 seconds!</p>
                </div>
                <div id="nd-video-img-1" class="col-7 col-sm-7 col-md-7 col-lg-7"><a class="popup-youtube" href="https://www.youtube.com/watch?v=QXzTMdghf8Y"><img src="./../../assets/images/player.svg"><i class="fa fa-play" id="fa-pay-2"></i></a></div>
            </div>
            <div class="row">
                <div id="nd-Subscription-1" style="width: 100%;">
                    <div class="row">
                        <div id="nd-left-div-2" class="col-6" style="display: none;">
                            <h1>Monthly Subscription</h1>
                            <p>Let STOCKSherpa guide you through tricky stock terrain for just a month</p>
                        </div>
                        <div id="nd-right-div-2" class="col-12">
                            <h1></h1><br>
                            <p>Get STOCKSherpa to analyse any stock in 30 seconds and find you the best weath creating stocks<br></p>
                        </div>
                    </div>
                </div>
                <div id="nd-Subscription-price-1">
                    <div class="row">
                        <div id="nd-left-div-3" class="col-6" style="display: none;">
                            <h1 style="display: none;"><br></h1>
                            <h1><strong>Rs.201/-* Only</strong><br></h1>
                            <div class="nd-sub-button"><a href="#">Sign up now!</a></div>
                            <p>*GST + Taxes extra</p>
                        </div>
                        <div id="nd-right-div-3" class="col-12">
                            <h1 style="display: none;"><span>Only</span><strong>&nbsp;Rs.201/-*&nbsp;</strong><span style="font-size: 12px;">&nbsp;Per Month</span></h1>
                            <h1 class="nd-stricke-line" style="display: none;"><strong>Rs.501/-* Only</strong><br></h1>
                            <div class="nd-sub-button"><a  (click)="signup1('Yearly')" style="cursor: pointer;">Sign up now!</a></div>
                            <p style="display: none;">* Annual subscription</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div id="nd-remove-padd-1" class="col-12">
                <div id="nd-climb-1">
                    <h1>Alright you stock trekker, lets start the climb!</h1>
                    <h2>The STOCKSherpa subscription includes- </h2>
                    <p><i class="fa fa-check"></i>&nbsp;Smart in-depth Ai analysis in just 30 seconds!</p>
                    <p><i class="fa fa-check"></i>&nbsp;Top 100 Moonshot stocks identified by Ai</p>
                    <p style="padding-left: 40px;"><i class="fa fa-check" style="margin-left: -20px;"></i>&nbsp;List of best performing Platform, EV, IT engineering and exponential technology stocks</p>
                    <p><i class="fa fa-check"></i>&nbsp;Future wealth creators across Market cap</p>
                    <p><i class="fa fa-check"></i>&nbsp;In-depth comparisons of upto 5 stocks at a time!</p>
                    <p><i class="fa fa-check"></i>&nbsp;Detailed financial and SWOT analysis</p>
                    <p><i class="fa fa-check"></i>&nbsp;Winning advice that’s easy to understand</p>
                    <p><i class="fa fa-check"></i>&nbsp;Access to a growing bank of stock trekking tutorials</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="nd-content-thr">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div id="nd-founder-head">
                <h1>Vivek Madappa - Founder, STOCKSherpa</h1>
            </div>
            <div class="row">
                <div id="nd-team-img" class="col-4"><img src="./../../assets/images/profile_pic.jpeg">
                    <div id="nd-social-icon">
                        <ul>
                            <li><a href="https://twitter.com/vivek0210?s=08" target="_blank"><i class="fa fa-twitter-square"></i></a></li>
                            <li><a href="http://linkedin.com/in/vivekmadappa" target="_blank"><i class="fa fa-linkedin-square" style="color: #0077b5;"></i></a></li>
                            <li><a href="https://www.instagram.com/vivek0210" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div id="nd-founder-content" class="col-8">
                    <p>I bring learning from my over 3 decade career in marketing &amp; entrepreneurship to a tool that eliminates risk and worry associated with stock investing. With a passionate team of industry experts, all ‘sherpas’ in their own right, I aim to create unprecedented financial freedom with an Ai guide that helps you, the stock trekker!</p><a href="https://www.linkedin.com/in/vivekmadappa" target="_blank">https://www.linkedin.com/in/vivekmadappa</a>
                </div>
            </div>
        </div>
        <div id="nd-testimonials" class="col-12 col-sm-12 col-md-6 col-lg-6">
            <h1>Testimonials</h1>
            <p>Here’s what stock trekkers like you have to say</p>
            <div id="blog_slider1" class="owl-carousel owl-theme">
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-jk.png"></div>
                        <p>I have been an investor in the Indian stock market for over 25+ Years. What I love about STOCKSherpa is, its ablity to quickly view STOCKSherpa score and compare the relative performance at a glance. It saves me precious time researching and reading through brokerage reports. The whole world thinks ITC is available at a low valuation/price @ 21 PE, but the STOCKSherpa score gives Asian Paints a much higher score @ 95 PE. I will invest in Asian Paints Vs ITC.<br></p>
                    </div>
                    <h1>Jose Koshy&nbsp;<span>Regional Director @ Oracle University for Oracle Technologies - Competency &amp; Skills</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-tm.png"></div>
                        <p>As a first time investor and as women, we are taught to be wary of investing and taking financial risks. STOCKSherpa really helps me find stocks that are worthwhile through their advanced algorithms! I always check the rating on STOCKSherpa before I go ahead and invest in stocks. Great initiative! Thanks guys!<br></p>
                    </div>
                    <h1>Tara Molloth&nbsp;<span>Digital Marketing Specialist</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-sk.png"></div>
                        <p>STOCKSherpa is very useful for inverstors to decide in which stocks to invest. It's AI analysis is easy to understand, very detailed and saves time.<br></p>
                    </div>
                    <h1>Sonia Kaveriappa&nbsp;<span>Travel Consultant</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-vb.png"></div>
                        <p>STOCKSherpa is an excellent platform for quickly assessing the quality of the stock for investing. The parameters that STOCKSherpa covers is extensive and reliable for long term investments.<br></p>
                    </div>
                    <h1>Valiveti Bhaskar&nbsp;<span>Director Finance of Loadstar Equipment pvt ltd.</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-SN.png"></div>
                        <p>I have always been interested in investing, but my lack of knowledge and experience has made me hesitant to begin my journey. STOCKSherpa has helped ease my entry into this field. The advanced algorithms and AI have added credibility, making it easy for me as a first-time investor. I am looking forward to growing my portfolio using STOCKSherpa.<br></p>
                    </div>
                    <h1>Santosh Nagaswamy&nbsp;<span>Associate Director and Client Partner - Infosys Singapore</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-GC.png"></div>
                        <p>The minute I entered STOCKSherpa it was like 'Eureka! I wish we had this platform earlier!'<br>Come to think of it, most of the time stock investing follows the herd because truth be told investing based on analysis is hard to get around to. The layout is&nbsp;clear and the complex stock ratios simplified with recommendations to cut the chase. I would recommend STOCKSherpa to any discerning investor in a&nbsp;heartbeat!<br></p>
                    </div>
                    <h1>George Chacko&nbsp;<span>Director, Global Account, APJ, Intel. Singapore</span><br></h1>
                </div>
                <div class="nd-testimonial-div nd-contact-div item">
                    <div>
                        <div class="nd-testimonial-img-div"><img src="../../../assets/images/ss-img-CA%20PS.png"></div>
                        <p>I came across a wonderful line recently - "to get paid in the future, one must live in the future". This is true in every sphere of life; more so in investing. The share market offers tremendous opportunities to create wealth, provided you have a keen-eye to pick high yield stocks. You have to be consistently prudent.<br>I rely on STOCKSherpa to improve my chances of wealth creation. Powered by AI, it eliminates emotional errors &amp; recommends "Moonshot stocks" to help me build a secure future. I strongly endorse STOCKSherpa to all, especially those early in their career.<br></p>
                    </div>
                    <h1>CA Pavan Sharma&nbsp;<span>partner BCL India; a Business advisory firm for Startups &amp; SMEs</span><br></h1>
                </div>
            </div> 
        </div>
    </div>
</div>
<div id="nd-contact-sec">
    <h1>We would love to hear from you!</h1>
    <div class="row">
        <div id="nd-social-icon-1" class="col-12">
            <div style="padding: 0 10px;"><a href="mailto:info@stocksherpa.in">info@stocksherpa.in</a></div>
            <div style="padding: 5px 10px;"><a href="mailto:feedback@stocksherpa.in">feedback@stocksherpa.in</a></div><span style="vertical-align: text-top;padding: 5px 0 0 10px;">stocksherpa@&nbsp;</span>
            <ul>
                <li><a href="https://facebook.com/stocksherpa" target="_blank"><i class="fa fa-facebook-square" style="color: #4267B2;"></i></a></li>
                <li><a href="https://twitter.com/StockSherpaAi?s=08" target="_blank"><i class="fa fa-twitter-square" style="color: #4267B2;"></i></a></li>
                <li><a href="https://linkedin.com/stocksherpa" target="_blank"><i class="fa fa-linkedin-square" style="color: #0077b5;"></i></a></li>
                <li><a href="https://instagram.com/stocksherpa?utm_medium=copy_link" target="_blank"><i class="fa fa-instagram"></i></a></li>
            </ul>
        </div>
    </div>
</div>
<div id="myModaln1" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln1" aria-hidden="true">
    <div class="modal-dialog">
     
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
                          
                    <h5 style="margin-bottom: 0;">OKTOMAP</h5>
                        
                            <p style="margin-top: 0; margin-left: 10px;">Hacking 21 financial ratios to identify fundamentally strong stocks <br/><a (click)="DownloadOktomap()" style="cursor: pointer;color: #fff;"><i style="cursor: pointer;" class="fa fa-download" aria-hidden="true"></i>Download OKTOMAP.</a> </p>
                       
                    <h5 style="margin-bottom: 0;">Strategy Cards</h5>
              
                            <p style="text-align: left;margin-left: 15px;margin-top: 0;"> Easy strategies to invest for wealth creation.  </p>
                            <p style="text-decoration: underline;">Register to download STRATEGY HACK</p>
                 
             
             <div class="row">
               
                <div class="col-lg-12 col-sm-12 col-12">
  
                  <input type="text" class="form-control" name="firstname" placeholder="Name"  [(ngModel)]="firstname">

                </div>
              </div>
            
            <input type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="E-mail">
            <input type="number" class="form-control" name="mobile" placeholder="Mobile No."  [(ngModel)]="mobile" onKeyPress="if(this.value.length==10) return false;">
              
            <label class="text-left cnt"><input type="checkbox" name="chkrobot" class="checkbox" [(ngModel)]="chkrobot"> I am not a robot! <span class="checkmark"></span></label>
            <div class="sbtn" style="cursor: pointer;"><input type="submit" name="login" value="Register" (click)="Register()" class="click_btn confirm add" style="cursor: pointer;width: 75%;">			 </div>
          
            
             <p>Enjoy the hike! <a [routerLink]="['/']" data-dismiss="modal">Continue to site</a></p>
        </div>
      </div>
      </div>
  </div> 
  <div id="myModaln2" class="modal fade faq-popup" role="dialog"  aria-labelledby="myModaln2" aria-hidden="true">
    <div class="modal-dialog">
      
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <img src="../../assets/images/signup-img.png" style="width: 100%;" alt="">
         <div style="text-align: center;padding: 30px 0;">
          <span style="font-size: 16px;font-weight: bold; color: #fff;">
           Please activate your account and download STRATEGY HACK.
           </span>
         </div>
      
        </div>
      </div>
      </div>
  </div> 